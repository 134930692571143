import React from "react"
import styled from "styled-components"
import { em } from "polished"

import { __ } from "../utils/translate"
import embedParams from "../config/embedParams"

const SiteTrailStatus = ({ reportedAt }) => {
  let status = ``
  const date = new Date(reportedAt)
  if (date.getFullYear() == 1970) status = `teaRose`
  else {
    const diff = (new Date() - date) / (1000 * 3600)
    if (diff <= 24) status = `celadon`
    else if (diff <= 48) status = `vanilla`
    else if (diff <= 96) status = `lightBlue`
    else status = `desertSand`
  }

  const dateFormatted = new Intl.DateTimeFormat(embedParams.language, {
    dateStyle: `short`,
    timeStyle: `short`,
  }).format(date)

  const text = {
    celadon: __(`Newly prepared (Prepared %{date})`, { date: dateFormatted }),
    vanilla: __(`1-2 days (Prepared %{date})`, { date: dateFormatted }),
    lightBlue: __(`3-4 days (Prepared %{date})`, { date: dateFormatted }),
    desertSand: __(`5 days or more (Prepared %{date})`, {
      date: dateFormatted,
    }),
    teaRose: __(`Closed`),
  }[status]

  return (
    <Container $color={status}>
      {__(`Trail status`)}: {text}
    </Container>
  )
}

export default SiteTrailStatus

const Container = styled.p`
  ${({ theme }) => theme.fonts.set(`primary`, `medium`)}

  padding: ${em(16)} ${em(20)};
  font-size: ${em(13)};
  background-color: ${({ theme, $color }) => theme.colors[$color]};
  border-radius: ${({ theme }) => theme.br.normal};
`
