import React from "react"
import styled from "styled-components"
import { em, rem, rgba } from "polished"

import ExpandableText from "../ExpandableText"
import { __ } from "../../utils/translate"
import embedParams from "../../config/embedParams"

const Current = ({ data }) => {
  if (!data.current || embedParams.client == `stf`) return null

  return (
    <Container>
      <Box>
        <Title>{__(`Current`)}</Title>

        <ExpandableText text={data.current} />
      </Box>
    </Container>
  )
}

export default Current

const Container = styled.section`
  padding: ${em(10)} ${rem(20)};

  @media ${({ theme }) => theme.mq.xxsmallDown} {
    padding-left: ${rem(10)};
    padding-right: ${rem(10)};
  }
`

const Box = styled.div`
  padding: ${em(14)};
  border-radius: ${({ theme }) => theme.br.normal};
  background-color: ${({ theme }) => rgba(theme.colors.maximumRed, 0.3)};
`

const Title = styled.h3`
  ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

  margin-bottom: ${em(10)};
  font-size: ${em(15)};
  letter-spacing: ${em(-0.025)};
`
