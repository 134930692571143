import React, { useEffect, useRef } from "react"
import { observer } from "mobx-react-lite"
import styled, { css } from "styled-components"
import { em, rem, hideVisually, ellipsis } from "polished"
import { throttle } from "lodash"
import Fuse from "fuse.js"

import { ReactComponent as SvgBook } from "../assets/images/icons/book.svg"
import { ReactComponent as SvgLocation } from "../assets/images/icons/location.svg"
import { useStore } from "../store"
import embedParams from "../config/embedParams"
import { a11yCategories } from "../config/categories"
import { searchTypesenseSites } from "../utils/typesense"
import { queryToTypesenseParams, fixQueryBoolVal } from "../utils/query"
import useReferredState from "../utils/useReferredState"
import { getCategoryIconSrc } from "../utils/icon"
import { __ } from "../utils/translate"

const LIMIT_PER_TYPE = 10

const selectSearchableStoreCategories = (store) =>
  store.categories.filter((c) => {
    const selected =
      c.searchable &&
      !store.findInQuery({ value: c.id, type: `category` }) &&
      !store.findInQuery({ value: c.id, type: `category_animal` }) &&
      !a11yCategories.includes(c.slug)

    // if (selected && embedParams.client == `stf` && embedParams.strict == `trip`)
    //   return clientStfTripCategories.includes(c.id)

    return selected
  })

const SearchResults = () => {
  const store = useStore()
  const [results, resultsRef, setResults] = useReferredState([])
  const [highlighted, highlightedRef, setHighlighted] = useReferredState(1)
  const [tabs, tabsRef, setTabs] = useReferredState([])
  const [tabActive, tabActiveRef, setTabActive] = useReferredState(`all`)
  const resultsElRef = useRef()
  const tabsElRef = useRef()

  const search = throttle(() => {
    if (!store.search) {
      setResults([])
      return
    }

    const threshold = 0.3
    const includeMatches = false

    const foundCategories = new Fuse(selectSearchableStoreCategories(store), {
      keys: [`label`, `aliases`],
      threshold,
      includeMatches,
    })
      .search(store.search)
      .slice(0, LIMIT_PER_TYPE)
      .map(({ item }) => ({
        type: item.legacyType == `animal` ? `category_animal` : `category`,
        labelType: `category`,
        value: item.id,
        title: item.label,
      }))

    if (embedParams.strict) {
      queryToTypesenseParams(store)
        .then((typesenseParams) => {
          return searchTypesenseSites({
            limit: LIMIT_PER_TYPE,
            query: `${typesenseParams.query} ${store.search}`.trim(),
            filter: typesenseParams.filters,
          })
            .then((sites) => {
              const foundSites = sites.map((s) => ({
                type: `site`,
                labelType: `site`,
                value: s.id,
                title: s.title,
                icon: s.assetsCategoryIcon,
              }))

              setResults([...foundCategories, ...foundSites])
            })
            .catch(() => null)
        })
        .catch(() => null)
    } else {
      const foundGuides = new Fuse(
        store.guides.filter(
          (g) => !g.hidden && !store.findInQuery({ value: g.id, type: `guide` })
        ),
        {
          keys: [`name`],
          threshold,
          includeMatches,
        }
      )
        .search(store.search)
        .slice(0, LIMIT_PER_TYPE)
        .map(({ item }) => ({
          type: `guide`,
          labelType: `guide`,
          value: item.id,
          title: item.name,
        }))

      const foundCounties = new Fuse(
        store.counties.filter(
          (g) => !store.findInQuery({ value: g.id, type: `county` })
        ),
        {
          keys: [`name`],
          threshold,
          includeMatches,
        }
      )
        .search(store.search)
        .slice(0, LIMIT_PER_TYPE)
        .map(({ item }) => ({
          type: `county`,
          labelType: `county`,
          value: item.id,
          title: item.name,
        }))

      const foundMunicipalities = new Fuse(
        store.municipalities.filter(
          (g) => !store.findInQuery({ value: g.id, type: `municipality` })
        ),
        {
          keys: [`name`],
          threshold,
          includeMatches,
        }
      )
        .search(store.search)
        .slice(0, LIMIT_PER_TYPE)
        .map(({ item }) => ({
          type: `municipality`,
          labelType: `municipality`,
          value: item.id,
          title: item.name,
        }))

      const typesenseIdFilter = store.query
        .filter((q) => q.type == `site`)
        .map((q) => q.value)
        .join(`,`)

      const typesenseFilter = [
        `(${store.query
          .filter((q) => q.type == `published`)
          .map((q) => `published:=${fixQueryBoolVal(q.value)}`)
          .join(` || `)})`,
        typesenseIdFilter && `_id:!=[${typesenseIdFilter}]`,
      ]
        .filter(Boolean)
        .join(` && `)

      searchTypesenseSites({
        limit: LIMIT_PER_TYPE,
        query: store.search,
        filter: typesenseFilter,
      })
        .then((sites) => {
          const foundSites = sites.map((s) => ({
            type: `site`,
            labelType: `site`,
            value: s.id,
            title: s.title,
            icon: s.assetsCategoryIcon,
          }))

          setResults([
            ...foundCategories,
            ...foundSites,
            ...foundGuides,
            ...foundCounties,
            ...foundMunicipalities,
          ])
        })
        .catch(() => null)
    }
  }, 200)

  const getHighlightedButtonEl = () => {
    if (!resultsElRef.current || !highlightedRef.current) return null
    const i = highlightedRef.current + (tabActiveRef.current == `all` ? 1 : 0)
    return resultsElRef.current.querySelector(`button:nth-of-type(${i})`)
  }

  useEffect(() => {
    if (!resultsElRef.current) return
    const resultEl = getHighlightedButtonEl()
    if (resultEl)
      resultEl.scrollIntoView({ block: `nearest`, behavior: `smooth` })
  }, [highlighted])

  useEffect(() => {
    setHighlighted(1)

    if (tabsElRef.current) {
      const tabEl = tabsElRef.current.querySelector(
        `button[data-type="${tabActive}"]`
      )
      if (tabEl)
        tabEl.scrollIntoView({
          block: `nearest`,
          inline: `nearest`,
          behavior: `smooth`,
        })
    }
  }, [tabActive])

  useEffect(() => {
    if (!resultsRef.current.length) setHighlighted(0)
    const tabs = [`all`]
    results.forEach((r) => !tabs.includes(r.type) && tabs.push(r.type))
    setTabs(tabs)
  }, [results])

  useEffect(() => {
    search()
    setHighlighted(1)
  }, [store.search])

  useEffect(() => {
    window.addEventListener(`keyup`, winKeyup)

    return () => {
      window.removeEventListener(`keyup`, winKeyup)
    }
  }, [])

  const applyResult = (data) => {
    if (embedParams.strict && data.type == `site`) {
      store.setPointActiveId(data.value, `search_results`)
      store.setDisplaySite(true)
    } else {
      store.addToQuery(data)
      store.removeFromQuery({ type: `importance` })
      store.setSearch(``)
    }
  }

  const winKeyup = (e) => {
    if ([`ArrowUp`, `ArrowDown`].includes(e.key)) {
      setHighlighted(
        (() => {
          const i = highlightedRef.current + (e.key == `ArrowDown` ? 1 : -1)
          if (i < 0) return resultsRef.current.length
          if (i >= resultsRef.current.length + 1) return 0
          return i
        })()
      )
    }

    if (
      [`ArrowLeft`, `ArrowRight`].includes(e.key) &&
      tabsRef.current.length > 2
    ) {
      let index = tabsRef.current.findIndex((t) => t == tabActiveRef.current)
      if (e.key == `ArrowLeft`) {
        index--
        if (index < 0) index = tabsRef.current.length - 1
      } else {
        index++
        if (index > tabsRef.current.length - 1) index = 0
      }
      setTabActive(tabsRef.current[index])
    }

    if (e.key == `Enter`) {
      if (highlightedRef.current) {
        const resultEl = getHighlightedButtonEl()
        if (resultEl) applyResult(resultEl.dataset)
      } else applyResult({ type: `text`, value: store.search })
    }
  }

  const resultClick = (e) => {
    applyResult(e.currentTarget.dataset)
  }

  const resultMouseEnter = (e) => {
    setHighlighted(window.parseInt(e.currentTarget.dataset.highlightIndex))
  }

  const tabClick = (e) => {
    setTabActive(e.currentTarget.dataset.type)
    setHighlighted(1)
  }

  if (!store.search) return null

  return (
    <>
      <Title>{__(`Results`)}</Title>

      {tabs.length > 2 && (
        <Tabs ref={tabsElRef}>
          <div>
            <div>
              {tabs.map((tab) => (
                <Tab
                  key={tab}
                  onClick={tabClick}
                  $active={tab == tabActive}
                  data-type={tab}
                >
                  {__(`search-type::${tab}`)}
                </Tab>
              ))}
            </div>
          </div>
        </Tabs>
      )}

      <Results ref={resultsElRef}>
        {tabActive == `all` && (
          <Result
            onClick={resultClick}
            onMouseEnter={resultMouseEnter}
            $highlighted={!highlighted}
            data-highlight-index={0}
            data-type="text"
            data-value={store.search}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: __(`Search by %{text} text`, {
                  text: `<strong>“${store.search}”</strong>`,
                }),
              }}
            />
          </Result>
        )}

        {results
          .filter((r) => [`all`, r.type].includes(tabActive))
          .map((result, i, arr) => (
            <React.Fragment key={`${result.type}${result.value}`}>
              {(!i || arr[i - 1].labelType != result.labelType) && (
                <Label>{__(`search-type::${result.labelType}`)}</Label>
              )}

              <Result
                onClick={resultClick}
                onMouseEnter={resultMouseEnter}
                $highlighted={highlighted == i + 1}
                data-highlight-index={i + 1}
                data-type={result.type}
                data-value={result.value}
              >
                {[`category`, `category_animal`].includes(result.type) && (
                  <img
                    src={getCategoryIconSrc(result.value, `outline`)}
                    role="presentation"
                    alt=""
                  />
                )}

                {result.type == `site` && (
                  <img
                    src={getCategoryIconSrc(result.icon, `main`)}
                    role="presentation"
                    alt=""
                  />
                )}

                {result.type == `guide` && <SvgBook aria-hidden="true" />}

                {[`county`, `municipality`].includes(result.type) && (
                  <SvgLocation aria-hidden="true" />
                )}

                <span>
                  <strong>{result.title}</strong>
                </span>
              </Result>
            </React.Fragment>
          ))}
      </Results>
    </>
  )
}

export default observer(SearchResults)

const Title = styled.h2`
  ${hideVisually()}
`

const Tabs = styled.div`
  flex-shrink: 0;

  > div {
    ${({ theme }) => theme.mixins.hideScrollbar()}

    padding-left: ${rem(20)};
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    > div {
      min-width: 100%;
      padding-right: ${rem(20)};
      display: inline-flex;
      border-bottom: 1px solid ${({ theme }) => theme.colors.slateGray};
    }
  }
`

const Tab = styled.button.attrs({ type: `button` })`
  flex-grow: 1;
  padding: ${em(18)};
  white-space: nowrap;
  font-size: ${em(10)};
  text-transform: uppercase;
  text-align: center;
  outline-offset: ${em(-3)} !important;
  border-bottom: 2px solid
    ${({ $active, theme }) =>
      $active ? theme.colors.slateGray : `transparent`};

  &:hover {
    color: ${({ theme }) => theme.colors.cadet};
  }
`

const Results = styled.div`
  flex-grow: 1;
  padding: ${em(10)} 0;
  margin-right: ${rem(4)};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media ${({ theme }) => theme.mq.mobileDown} {
    padding-bottom: ${rem(80)}; // extra space for ViewToggle
  }

  &::-webkit-scrollbar {
    width: ${rem(5)};
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gunmetal};
    border-radius: ${({ theme }) => theme.br.small};
  }
`

const Label = styled.h3`
  margin-top: ${em(12)};
  padding: 0 ${rem(20)};
  font-size: ${em(10)};
  text-transform: uppercase;
`

const Result = styled.button.attrs({ type: `button` })`
  width: 100%;
  padding: ${em(8)} ${rem(20)};
  display: flex;
  align-items: center;
  gap: ${em(16)};
  outline-offset: ${em(-4)} !important;

  ${({ $highlighted }) =>
    $highlighted &&
    css`
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.slateGray};
    `}

  &:not([data-type="site"]) {
    img,
    svg {
      ${({ $highlighted }) =>
        $highlighted &&
        css`
          filter: grayscale(1) brightness(0) invert(1);
        `}
    }
  }

  span {
    ${ellipsis(`100%`)}
  }

  svg,
  img {
    width: ${em(20)};
    height: ${em(20)};
    flex-shrink: 0;
    color: ${({ theme }) => theme.colors.darkSlateGray};
  }

  img {
    height: auto;
  }
`
