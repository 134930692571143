import { __ } from "./translate"

const humanizeDurationTime = (duration) => {
  // `duration` must be in minutes

  const hours = Math.floor(duration / 60)
  const minutes = duration % 60

  if (hours > 0 && minutes > 0)
    return __(`%{hours} h %{minutes} min`, { hours, minutes })
  else if (hours > 0) return __(`%{hours} h`, { hours })
  else return __(`%{minutes} min`, { minutes })
}

const humanizeDifficulty = (difficulty) => {
  return (
    {
      black: __(`Black - experienced`),
      blue: __(`Blue - medium`),
      green: __(`Green - simple`),
      red: __(`Red - demanding`),
    }[difficulty] || __(difficulty)
  )
}

const humanizeLinkType = (linkType) => {
  return (
    {
      booking_website: __(`Booking`),
      official_website: __(`Website`),
      visit: __(`Visit`),
    }[linkType] || __(linkType)
  )
}

export { humanizeDurationTime, humanizeDifficulty, humanizeLinkType }
