import React from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { rem } from "polished"

import { ReactComponent as SvgPlus } from "../assets/images/icons/plus.svg"
import { ReactComponent as SvgDash } from "../assets/images/icons/dash.svg"
import { useStore } from "../store"
import embedParams from "../config/embedParams"
import { __ } from "../utils/translate"

const ZoomControl = () => {
  const store = useStore()

  const buttonClick = (e) => {
    if (!store.map) return

    const { mode } = e.currentTarget.dataset

    store.travelMap({
      flyTo: {
        zoom: store.map.getZoom() + 0.5 * (mode === `in` ? 1 : -1),
      },
    })
  }

  if (
    embedParams.zoomControl === false ||
    (embedParams.zoomControl == `fullscreen` && !store.fullscreen)
  )
    return null

  return (
    <Container>
      <button
        type="button"
        title={__(`Zoom in`)}
        onClick={buttonClick}
        data-mode="in"
      >
        <SvgPlus aria-label={__(`Zoom in`)} />
      </button>

      <button
        type="button"
        title={__(`Zoom out`)}
        onClick={buttonClick}
        data-mode="out"
      >
        <SvgDash aria-label={__(`Zoom out`)} />
      </button>
    </Container>
  )
}

export default observer(ZoomControl)

const Container = styled.div`
  border-radius: ${({ theme }) => theme.br.normal};
  box-shadow: ${({ theme }) => theme.bs.button};

  button {
    width: ${rem(42)};
    height: ${rem(45)};
    padding: ${rem(11)};
    display: block;
    position: relative;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.gunmetal};

    &:hover,
    &:focus {
      z-index: 1;
      color: ${({ theme }) => theme.colors.lightGray};
    }

    &:active {
      background-color: ${({ theme }) => theme.colors.cadet};
    }

    &::before {
      /* expanded click area */
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: ${rem(-10)};
      right: ${rem(-10)};
    }

    &:first-child {
      border-radius: ${({ theme }) => theme.br.normal}
        ${({ theme }) => theme.br.normal} 0 0;

      &::before {
        top: ${rem(-5)};
      }
    }

    &:last-child {
      border-radius: 0 0 ${({ theme }) => theme.br.normal}
        ${({ theme }) => theme.br.normal};

      &::before {
        bottom: ${rem(-5)};
      }
    }

    &:not(:last-child) {
      border-bottom: 2px solid ${({ theme }) => theme.colors.pewterBlue};
    }
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`
