import React from "react"
import styled, { css } from "styled-components"
import { rem } from "polished"

import { ReactComponent as SvgClose } from "../assets/images/icons/close.svg"
import embedParams from "../config/embedParams"
import { postEmbedMessage } from "../utils/embedMessage"
import { __ } from "../utils/translate"

const CloseControl = () => {
  if (embedParams.closeControl !== true) return null

  const buttonClick = () => postEmbedMessage(`closeRequested`)

  return (
    <Container>
      <button type="button" title={__(`Close`)} onClick={buttonClick}>
        <SvgClose aria-label={__(`Close`)} />
      </button>
    </Container>
  )
}

export default CloseControl

const Container = styled.div`
  button {
    width: ${rem(42)};
    height: ${rem(42)};
    padding: ${rem(12)};
    display: block;
    position: relative;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.alloyOrange};
    box-shadow: ${({ theme }) => theme.bs.button};
    border-radius: ${({ theme }) => theme.br.normal};

    &:hover {
      color: ${({ theme }) => theme.colors.lightGray};

      ${({ $active }) =>
        $active &&
        css`
          color: ${({ theme }) => theme.colors.aliceBlue};
        `}
    }

    &::before {
      /* expanded click area */
      content: "";
      position: absolute;
      top: ${rem(-5)};
      bottom: ${rem(-5)};
      left: ${rem(-10)};
      right: ${rem(-10)};
    }
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`
