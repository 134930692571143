// TODO: move the use of these to `fetchApiCategories` and the corresponding params (`a11y`, `quick`)

const a11yCategories = [
  `near-parking`,
  `near-public-transport`,
  `near-toilet`,
  `stroller-accessible`,
  `wheelchair-accessible`,
]

const quickCategories = [
  `33`, // hiking
  `25`, // nature-reserve
  `31`, // biking
  `16`, // mountainbiking
  `69`, // bathing
  `37`, // canoeing
  `54`, // cafe
  `17`, // climbing
]

export { a11yCategories, quickCategories }
