import React, { useEffect, useRef } from "react"
import { observer } from "mobx-react-lite"
import styled, { css } from "styled-components"
import { em, rem, rgba } from "polished"

import { ReactComponent as SvgClose } from "../assets/images/icons/close.svg"
import { useStore } from "../store"
import { __ } from "../utils/translate"

const isIOS = !!navigator.userAgent.match(/(iPad|iPhone|iPod)/g)
const isFirefox = !!window.navigator.userAgent.match(/firefox/i)

const PrintZone = () => {
  const store = useStore()
  const printBtnRef = useRef(null)

  const cancelClick = () => {
    store.setPrint(false)
  }

  const printClick = () => {
    if (isFirefox) {
      window.addEventListener(`afterprint`, afterPrint, { passive: true })
    } else {
      window.matchMedia(`print`).addListener(afterPrint)
    }

    window.print()
  }

  const afterPrint = (e) => {
    if (isFirefox) {
      window.removeEventListener(`afterprint`, afterPrint, {
        passive: true,
      })
    } else {
      if (e.matches) return
      window.matchMedia(`print`).removeListener(afterPrint)
    }
  }

  useEffect(() => {
    store.setFullscreen(true)
    printBtnRef.current.focus()
  }, [])

  return (
    <Container>
      <Zone $isIOS={isIOS}>
        <div>
          <div />

          <div />
        </div>

        <div />
      </Zone>

      <Dialog>
        <h2>{__(`Print`)}</h2>

        <button
          type="button"
          className="-exit"
          title={__(`Done`)}
          onClick={cancelClick}
        >
          <SvgClose aria-label={__(`Done`)} />
        </button>

        <p>
          {__(
            `The visible area will be printed on a landscape paper. You can adjust the map and click “Print”.`
          )}
        </p>

        <button
          type="button"
          className="-print"
          ref={printBtnRef}
          onClick={printClick}
        >
          {__(`Print`)}
        </button>
      </Dialog>
    </Container>
  )
}

export default observer(PrintZone)

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

const Zone = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  animation: ${({ theme }) => theme.animations.fadeIn} 0.5s linear;

  @media only print {
    visibility: hidden;
  }

  > div:first-child {
    display: flex;

    > div:first-child {
      width: 100vw;
      height: 100vh;
      max-width: ${rem(1024)};
      max-height: ${rem(720)};
      border: 7px solid ${({ theme }) => rgba(theme.colors.black, 0.6)};

      ${({ $isIOS }) =>
        $isIOS &&
        css`
          max-width: 100vw;
          max-height: 100vh;
        `}
    }

    > div:last-child {
      flex-grow: 1;
      background-color: ${({ theme }) => rgba(theme.colors.black, 0.6)};
      backdrop-filter: blur(2px);
    }
  }

  > div:last-child {
    flex-grow: 1;
    background-color: ${({ theme }) => rgba(theme.colors.black, 0.6)};
    backdrop-filter: blur(2px);
  }
`

const Dialog = styled.div`
  width: 100%;
  max-width: min(calc(100% - ${rem(80)}), ${em(420)});
  padding: ${em(20)};
  position: absolute;
  z-index: 2;
  left: ${rem(10)};
  bottom: ${rem(10)};
  display: grid;
  grid-template-columns: auto max-content;
  align-items: flex-start;
  column-gap: ${em(20)};
  row-gap: ${em(10)};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.gunmetal};
  box-shadow: ${({ theme }) => theme.bs.button};
  border-radius: ${({ theme }) => theme.br.small};

  @media only print {
    visibility: hidden;
  }

  h2 {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

    font-size: ${em(14)};
  }

  p {
    font-size: ${em(14)};
  }

  button {
    pointer-events: auto;
  }

  .-exit {
    width: ${em(34)};
    height: ${em(34)};
    margin-left: auto;
    margin-top: ${em(-10)};
    margin-right: ${em(-10)};

    &:hover {
      color: ${({ theme }) => theme.colors.lightGray};
    }

    &:active {
      opacity: 0.6;
    }

    svg {
      width: 100%;
      height: 100%;
      padding: ${em(10)};
      display: block;
    }
  }

  .-print {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

    padding: ${em(10)} ${em(24)};
    font-size: ${em(14)};
    color: ${({ theme }) => theme.colors.darkSlateGray};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.br.normal};

    &:hover {
      background-color: ${({ theme }) => theme.colors.aliceBlue};
    }

    &:active {
      opacity: 0.6;
    }
  }
`
