import queryString from "query-string"
import { uniq, uniqWith, differenceWith, isEqual, capitalize } from "lodash"

import { fetchApiSiteNeighbours, fetchApiUserVisits } from "./api"

const fixQueryBoolVal = (v) => v.replace(`1`, `true`).replace(`0`, `false`)

const parseEmbedQuery = (embedQuery) => {
  const query = []
  const parsed = queryString.parse(embedQuery)
  let hasPublished = false
  let legacyStrict = ``
  let legacyGuide = 0

  const types = [
    `site`,
    `site_with_neighbours`,
    `guide`,
    `county`,
    `municipality`,
    `organization`,
    `trip`,
    `list`,
    `user`,
    `category`,
    `category_animal`,
    `text`,
    `type`,
    `importance`,
    `published`,
    `source`,
  ]

  types.forEach((type) => {
    const item = parsed[type]
    if (item) {
      ;(Array.isArray(item) ? item : [item]).forEach((value) => {
        query.push({ type, value })
        if (type == `published`) hasPublished = true
      })
    }
  })

  if (!hasPublished) query.push({ type: `published`, value: `true` })

  // legacy

  const legacyNearby = parsed[`nearby`]

  const legacySites = parsed[`search[site_ids][]`] || parsed[`site_ids`]
  if (legacySites) {
    ;(Array.isArray(legacySites)
      ? legacySites
      : legacySites.split(`,`)
    ).forEach((id) => {
      query.push({
        value: id,
        type: legacyNearby ? `site_with_neighbours` : `site`,
      })
    })
    legacyStrict = true
  }

  const legacySite = parsed[`site_id`]
  if (legacySite) {
    query.push({
      value: legacySite,
      type: `site_with_neighbours`,
    })
    legacyStrict = true
  }

  const legacyCategory = parsed[`search[tags]`]
  if (legacyCategory) {
    query.push({
      value: `33`,
      type: `category`,
    })
    legacyStrict = true
  }

  const legacyOrganization = parsed[`organization_id`]
  if (legacyOrganization) {
    query.push({
      value: legacyOrganization,
      type: `organization`,
    })
    legacyStrict = `organization`
  }

  const legacyUser = parsed[`user_id`]
  if (legacyUser) {
    query.push({
      value: legacyUser,
      type: `user`,
    })
    legacyStrict = `user`
  }

  const legacyTrip = parsed[`search[trip_id]`]
  if (legacyTrip) {
    query.push({
      value: legacyTrip,
      type: `trip`,
    })
    // legacyStrict = `trip`
    legacyStrict = true // we don't need a miniguide-look for now
  }

  const legacyList = parsed[`search[list_id]`]
  if (legacyList) {
    query.push({
      value: legacyList,
      type: `list`,
    })
    // legacyStrict = `list`
    legacyStrict = true // we don't need a miniguide-look for now
  }

  const legacyGuides = parsed[`search[guide_ids][]`]
  if (legacyGuides) {
    ;(Array.isArray(legacyGuides) ? legacyGuides : [legacyGuides]).forEach(
      (id) => {
        legacyGuide = id
        query.push({
          value: id,
          type: `guide`,
        })
      }
    )
    legacyStrict = true
  }

  const legacyRelatedGuide = parsed[`related_guide_id`]
  if (legacyRelatedGuide) {
    legacyGuide = legacyRelatedGuide
  }

  const legacyImportance = parsed[`search[importance]`]
  if (legacyImportance) {
    query.push({
      value: legacyImportance,
      type: `importance`,
    })
  }

  return { query: uniqWith(query, isEqual), legacyStrict, legacyGuide }
}

const queryToTypesenseParams = (store) => {
  return new Promise((resolve, reject) => {
    const sitesNeighboursPromise = Promise.all(
      store.query
        .filter((q) => q.type == `site_with_neighbours`)
        .map((q) => fetchApiSiteNeighbours({ id: q.value }))
    )

    const usersVisitsPromise = Promise.all(
      store.query
        .filter((q) => q.type == `user`)
        .map((q) => fetchApiUserVisits({ id: q.value }))
    )

    const tripsSites = uniq(
      store.trips
        .filter((t) =>
          store.query
            .filter((q) => q.type == `trip`)
            .map((q) => q.value)
            .includes(t.id)
        )
        .map((t) => t.items.map((i) => i.siteId))
        .flat()
    )

    const tripsNeighboursPromise = Promise.all(
      tripsSites.map((id) => fetchApiSiteNeighbours({ id }))
    )

    const listsSites = uniq(
      store.lists
        .filter((l) =>
          store.query
            .filter((q) => q.type == `list`)
            .map((q) => q.value)
            .includes(l.id)
        )
        .map((t) => t.items.map((i) => i.siteId))
        .flat()
    )

    return Promise.all([
      sitesNeighboursPromise,
      usersVisitsPromise,
      tripsNeighboursPromise,
    ]).then(([sitesNeighbours, usersVisits, tripsNeighbours]) => {
      const filterId = uniq([
        ...store.query
          .filter((q) => [`site`, `site_with_neighbours`].includes(q.type))
          .map((q) => q.value),
        ...sitesNeighbours.flat().map((n) => n.id),
        ...usersVisits.flat().map((v) => v.id),
        ...tripsNeighbours.flat().map((v) => v.id),
        ...tripsSites,
        ...listsSites,
      ])

      const filterBase = [
        filterId.length && `_id:=[${filterId.join(`,`)}]`,

        ...store.query
          .filter((q) => q.type == `guide`)
          .map((q) => `guide_ids:=${q.value}`),

        ...store.query
          .filter((q) => q.type == `organization`)
          .map((q) => `organization_id:=${q.value}`),

        ...store.query
          .filter((q) => q.type == `trip`)
          .map((q) => `trip_ids:=${q.value}`),
      ]
        .filter(Boolean)
        .join(` || `)

      const filterRegions = [
        ...store.query
          .filter((q) => q.type == `county`)
          .map((q) => `county_id:=${q.value}`),

        ...store.query
          .filter((q) => q.type == `municipality`)
          .map((q) => `municipality_id:=${q.value}`),
      ]
        .filter(Boolean)
        .join(` || `)

      const filterCategories = store.query
        .filter((q) => q.type == `category`)
        .map((q) => `category_ids:=${q.value} || main_icon_id:=${q.value}`)
        .join(` || `)

      const filterCategoriesAnimals = store.query
        .filter((q) => q.type == `category_animal`)
        .map((q) => `category_ids:=${q.value} || main_icon_id:=${q.value}`)
        .join(` || `)

      const filterA11y = store.query
        .filter((q) => q.type == `a11y`)
        .map((q) =>
          q.value == `wheelchair-tested`
            ? `wheelchair_tested:=true`
            : `category_ids:=${q.value}`
        )
        .join(` || `)

      const filterImportance = store.query
        .filter((q) => q.type == `importance`)
        .map((q) => `importance:=${q.value}`)
        .join(` || `)

      const filterPublished = store.query
        .filter((q) => q.type == `published`)
        .map((q) => `published:=${fixQueryBoolVal(q.value)}`)
        .join(` || `)

      const filterType = store.query
        .filter((q) => q.type == `type`)
        .map((q) => `type:=${capitalize(q.value)}`)
        .join(` || `)

      const filterSource = store.query
        .filter((q) => q.type == `source`)
        .map((q) => `source:=${q.value}`)
        .join(` || `)

      const filters = [
        filterBase && `(${filterBase})`,
        filterRegions && `(${filterRegions})`,
        filterCategories && `(${filterCategories})`,
        filterCategoriesAnimals && `(${filterCategoriesAnimals})`,
        filterA11y && `(${filterA11y})`,
        filterImportance && `(${filterImportance})`,
        filterPublished ? `(${filterPublished})` : `published:=true`,
        filterType && `(${filterType})`,
        filterSource && `(${filterSource})`,
      ]
        .filter(Boolean)
        .join(` && `)

      const text = store.query
        .filter((q) => q.type == `text`)
        .map((q) => q.value)
        .join(` `)

      if (store.query.length && !filters && !text) {
        // query had some params in it but they didn't result in any Typesense
        // params (e.g. user had no trips, list had no sites) or so
        return reject(new Error())
      }

      return resolve({ filters, query: text })
    })
  })
}

const queryItemToTitle = (item, store) => {
  switch (item.type) {
    case `category`:
    case `category_animal`: {
      const category = store.findCategory(item.value)
      return category ? category.label : item.value
    }

    case `guide`: {
      const guide = store.guides.find((g) => g.id == item.value)
      return guide ? guide.name : item.value
    }

    case `organization`: {
      const organization = store.findOrganization(item.value)
      return organization ? organization.name : item.value
    }

    case `county`: {
      const county = store.counties.find((c) => c.id == item.value)
      return county ? county.name : item.value
    }

    case `municipality`: {
      const municipality = store.municipalities.find((c) => c.id == item.value)
      return municipality ? municipality.name : item.value
    }

    case `site`:
    case `site_with_neighbours`: {
      const site = store.points.find((c) => c.id == item.value)
      return site ? site.properties.title : item.value
    }

    case `user`: {
      const user = store.findUser(item.value)
      return user ? user.name : item.value
    }

    case `trip`: {
      const trip = store.findTrip(item.value)
      return trip ? trip.name : item.value
    }
  }

  return item.value
}

const queryDifference = (query1, query2) => {
  return [
    ...differenceWith(query1, query2, isEqual),
    ...differenceWith(query2, query1, isEqual),
  ]
}

export {
  parseEmbedQuery,
  queryToTypesenseParams,
  queryItemToTitle,
  queryDifference,
  fixQueryBoolVal,
}
