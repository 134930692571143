import React, { Suspense, lazy, useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { em, rem, ellipsis } from "polished"

import { ReactComponent as SvgHeart } from "../../assets/images/icons/heart.svg"
import { ReactComponent as SvgHeartFilled } from "../../assets/images/icons/heart-filled.svg"
import { ReactComponent as SvgBookmark } from "../../assets/images/icons/bookmark.svg"
import { ReactComponent as SvgBookmarkFilled } from "../../assets/images/icons/bookmark-filled.svg"
import { ReactComponent as SvgSend } from "../../assets/images/icons/send.svg"
import { ReactComponent as SvgShare } from "../../assets/images/icons/share.svg"
import {
  fetchApiUserLists,
  fetchApiUserTrips,
  fetchApiUserWatches,
  deleteApiWatches,
  postApiWatches,
} from "../../utils/api"
import { __ } from "../../utils/translate"
import embedParams from "../../config/embedParams"

const SaveModal = lazy(() => import(`./SaveModal`))
const ShareModal = lazy(() => import(`../ShareModal`))
const AuthModal = lazy(() => import(`../AuthModal`))

const Toolbar = ({ data }) => {
  const [saveModalActive, setSaveModalActive] = useState(false)
  const [shareModalActive, setShareModalActive] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [authModal, setAuthModal] = useState(false)
  const [watchId, setWatchId] = useState(0)

  const favouriteClick = () => {
    if (embedParams.userId) {
      if (!watchId) {
        setWatchId(`!`)
        postApiWatches(data.id)
          .then((watch) => setWatchId(watch.id))
          .catch(fetchAndSetWatch)
      } else if (watchId && watchId != `!`) {
        setWatchId(0)
        deleteApiWatches(watchId).catch(fetchAndSetWatch)
      }
    } else {
      setAuthModal(true)
    }
  }

  const saveClick = () => {
    if (embedParams.userId) setSaveModalActive(true)
    else setAuthModal(true)
  }

  const fetchAndSetWatch = () => {
    if (!embedParams.userId) return

    fetchApiUserWatches({ id: embedParams.userId })
      .then((watches) => {
        const watch = watches.find((w) => w.siteId == data.id)
        setWatchId(watch ? watch.id : 0)
      })
      .catch(() => setWatchId(0))
  }

  const fetchAndSetSaved = () => {
    if (!embedParams.userId) return

    Promise.all([
      fetchApiUserLists({ id: embedParams.userId }),
      fetchApiUserTrips({ id: embedParams.userId }),
    ])
      .then(([lists, trips]) => {
        setIsSaved(
          !!lists.find((l) => l.sites.includes(data.id)) ||
            !!trips.find((t) => t.sites.includes(data.id))
        )
      })
      .catch(() => setIsSaved(false))
  }

  useEffect(() => {
    if (!saveModalActive) {
      setWatchId(0)
      setIsSaved(false)
      fetchAndSetWatch()
      fetchAndSetSaved()
    }
  }, [saveModalActive, data.id])

  return (
    <>
      {!embedParams.client && (
        <Container className="site-toolbar">
          <li>
            <Button type="button" $active={!!watchId} onClick={favouriteClick}>
              {watchId ? (
                <SvgHeartFilled aria-hidden="true" />
              ) : (
                <SvgHeart aria-hidden="true" />
              )}

              <span>{__(`Favourite`)}</span>
            </Button>
          </li>

          <li>
            <Button type="button" $active={isSaved} onClick={saveClick}>
              {isSaved ? (
                <SvgBookmarkFilled aria-hidden="true" />
              ) : (
                <SvgBookmark aria-hidden="true" />
              )}

              <span>{__(`Save`)}</span>
            </Button>
          </li>

          <li>
            <Button
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.google.com/maps/dir/?api=1&destination=${[
                ...data.geometry.coordinates,
              ]
                .reverse()
                .join(`,`)}`}
            >
              <SvgSend aria-hidden="true" />

              <span>{__(`Directions`)}</span>
            </Button>
          </li>

          <li>
            <Button type="button" onClick={() => setShareModalActive(true)}>
              <SvgShare aria-hidden="true" />

              <span>{__(`Share`)}</span>
            </Button>
          </li>
        </Container>
      )}

      <Suspense fallback={null}>
        {shareModalActive && (
          <ShareModal
            url={data.url}
            title={data.title}
            onClose={() => setShareModalActive(false)}
          />
        )}

        {saveModalActive && (
          <SaveModal data={data} onClose={() => setSaveModalActive(false)} />
        )}

        {authModal && <AuthModal onClose={() => setAuthModal(false)} />}
      </Suspense>
    </>
  )
}

export default Toolbar

const Container = styled.ul`
  margin: ${rem(10)} 0;
  padding: 0 ${rem(20)};
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background-color: ${({ theme }) => theme.colors.aliceBlue};

  @media ${({ theme }) => theme.mq.xxsmallDown} {
    padding-left: ${rem(10)};
    padding-right: ${rem(10)};
  }

  li {
    display: flex;
    min-width: 0;
  }
`

const Button = styled.button`
  width: 100%;
  display: flex;
  gap: ${em(4)};
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.darkSlateGray};
  padding: ${em(10)} ${em(8)} ${em(7)};

  ${({ $active }) =>
    $active &&
    css`
      color: ${({ theme }) => theme.colors.tealBlue};
    `}

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.tealBlue};
  }

  svg {
    width: ${em(20)};
    height: ${em(20)};

    @media ${({ theme }) => theme.mq.mobileDown} {
      width: ${em(16)};
      height: ${em(16)};
    }
  }

  span {
    ${ellipsis(`100%`)}

    font-size: ${em(11)};
    letter-spacing: ${em(-0.25)};
    text-align: center;
  }
`
