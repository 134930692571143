import React from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { em } from "polished"

import { ReactComponent as SvgList } from "../assets/images/icons/list.svg"
import { ReactComponent as SvgMap } from "../assets/images/icons/map.svg"
import { useStore } from "../store"
import { __ } from "../utils/translate"

const ViewToggle = () => {
  const store = useStore()

  const buttonClick = () => {
    if (store.traffic) store.setTrafficMobileMap(!store.trafficMobileMap)
    else store.setMenu(!store.menu)
  }

  const isMap = store.traffic ? !store.trafficMobileMap : store.menu
  const label = isMap ? __(`Map view`) : __(`List view`)

  return (
    <Container>
      <button type="button" title={label} onClick={buttonClick}>
        {isMap ? <SvgMap aria-label={label} /> : <SvgList aria-label={label} />}

        <span>{label}</span>
      </button>
    </Container>
  )
}

export default observer(ViewToggle)

const Container = styled.div`
  button {
    width: 100%;
    padding: ${em(12)} ${em(20)};
    display: flex;
    align-items: center;
    gap: ${em(10)};
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: ${({ theme }) => theme.bs.button};
    border: solid ${({ theme }) => theme.colors.aliceBlue};
    border-width: 1px 0 0 1px;
    border-radius: ${({ theme }) => theme.br.normal};

    &:hover {
      color: ${({ theme }) => theme.colors.cadet};
    }

    &:active {
      opacity: 0.8;
    }
  }

  svg {
    width: ${em(18)};
    height: ${em(18)};
    flex-shrink: 0;
    display: block;
    color: ${({ theme }) => theme.colors.darkSlateGray};
  }

  span {
    ${({ theme }) => theme.fonts.set(`primary`, `medium`)}

    font-size: ${em(14)};
  }
`
