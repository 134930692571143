import { clientStfTripCategories } from "../config/client"
import embedParams from "../config/embedParams"
import { parseEmbedQuery } from "./query"

const clientAdaptedPoints = (points) => {
  switch (embedParams.client) {
    case `stf`: {
      if (embedParams.strict == `trip`) return filterClientStfTripPoints(points)

      break
    }
  }

  return points
}

const filterClientStfTripPoints = (points) => {
  const { query } = parseEmbedQuery(embedParams.query)
  const tripId = (query.find((q) => q.type == `trip`) || {}).value

  const siteIds = query
    .filter((q) => [`site`, `site_with_neighbours`].includes(q.type))
    .map((q) => q.value)

  return points.filter((p) => {
    if (
      siteIds.includes(p.id) ||
      (tripId && p.properties.trips.includes(tripId))
    )
      return true

    return clientStfTripCategories.includes(p.properties.mainCategory)
  })
}

export { clientAdaptedPoints }
