import React, { useEffect, useState, useRef } from "react"
import { observer } from "mobx-react-lite"
import { reaction } from "mobx"
import styled from "styled-components"
import { chunk } from "lodash"
import turfBboxPolygon from "@turf/bbox-polygon"
import turfBooleanWithin from "@turf/boolean-within"
import ReactMapboxGl, { Layer, Source } from "react-mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"

import MapA11y from "./MapA11y"
import MapSourcePoints from "./MapSourcePoints"
import MapSourceShapes from "./MapSourceShapes"
import MapSourceUnclustered from "./MapSourceUnclustered"
import MapAreas from "./MapAreas"
import MapTrails from "./MapTrails"
import MapPoints from "./MapPoints"
import MapCustomPin from "./MapCustomPin"
import MapTraffic from "./MapTraffic"
import MapMeasure from "./MapMeasure"
import MapLocation from "./MapLocation"
import MapElevationsMark from "./MapElevationsMark"
import mapboxConfig from "../config/mapbox"
import mapStyles, { mapStyleNames } from "../config/mapStyles"
import embedParams from "../config/embedParams"
import { swedenGeoBounds } from "../config/geo"
import { useStore } from "../store"

const isFirefox = window.navigator.userAgent.match(/firefox/i)
const isSafari = !!window.navigator.userAgent.match(
  /^((?!chrome|android).)*safari/i
)

// eslint-disable-next-line new-cap
const MapGL = ReactMapboxGl({
  preserveDrawingBuffer: isSafari || isFirefox, // allows map printing in Safari/Firefox
})

const ZOOM = embedParams.zoom ? [embedParams.zoom] : undefined
const CENTER = embedParams.center || undefined
const FIT_BOUNDS = embedParams.bounds
  ? chunk(embedParams.bounds, 2)
  : swedenGeoBounds

const Map = () => {
  const store = useStore()
  const [displayedStyle, setDisplayedStyle] = useState(store.mapStyle)
  const clusterMouseEnterIdRef = useRef(0)

  const toggleDisplayedStyle = () => {
    let name = store.mapStyle
    const style = mapStyles[store.mapStyle]

    if (store.map && style.zoomFallbackTileset) {
      if (style.minZoom && style.minZoom >= store.map.getZoom())
        name = style.zoomFallbackTileset

      if (style.maxZoom && style.maxZoom <= store.map.getZoom())
        name = style.zoomFallbackTileset

      if (name == store.mapStyle && style.bounds) {
        const mapBounds = store.map.getBounds()
        const mapBoundsPolygon = turfBboxPolygon([
          mapBounds._ne.lng,
          mapBounds._ne.lat,
          mapBounds._sw.lng,
          mapBounds._sw.lat,
        ])

        const styleBoundsPolygon = turfBboxPolygon(style.bounds.flat())
        if (!turfBooleanWithin(mapBoundsPolygon, styleBoundsPolygon))
          name = style.zoomFallbackTileset
      }
    }

    setDisplayedStyle(name)
  }

  const setPointShapeState = (id, state) => {
    store.map.setFeatureState({ id: id, source: `geojson-points` }, state)

    const shape = store.findShapeByParent(id)
    if (shape)
      store.map.setFeatureState(
        { id: shape.id, source: `geojson-shapes` },
        state
      )
  }

  // const mapClick = (e) => {
  //   if (e.defaultPrevented) return
  //   // if (!e.screenX && !e.screenY) return
  //   store.setPointActiveId(0)
  // }

  const pointsClusterClick = (e) => {
    if (store.measure || store.traffic) return
    if (e.defaultPrevented) return
    if (!e.features.length) return
    e.preventDefault()

    const feature = e.features[0]

    store.map
      .getSource(`geojson-points`)
      .getClusterExpansionZoom(feature.properties.cluster_id, (err, zoom) => {
        if (err) return
        store.map.easeTo({
          center: feature.geometry.coordinates,
          zoom: zoom,
        })
      })
  }

  const pointsClusterMouseEnter = (e) => {
    if (store.measure || store.traffic) return
    if (!e.features.length) return

    const feature = e.features[0]
    if (clusterMouseEnterIdRef.current != feature.id) pointsClusterMouseLeave()

    store.map.setFeatureState(
      { id: feature.id, source: `geojson-points` },
      { hover: true }
    )
    store.map.getCanvas().style.cursor = `pointer`
    clusterMouseEnterIdRef.current = feature.id
  }

  const pointsClusterMouseLeave = (e) => {
    if (!clusterMouseEnterIdRef.current) return

    store.map.setFeatureState(
      { id: clusterMouseEnterIdRef.current, source: `geojson-points` },
      { hover: false }
    )
    store.map.getCanvas().style.cursor = null
    clusterMouseEnterIdRef.current = 0
  }

  const featureMouseEnter = (e) => {
    if (store.measure || store.traffic) return
    if (clusterMouseEnterIdRef.current) return
    if (!e.features.length) return
    if (isFeatureInvisible(e)) return

    store.map.getCanvas().style.cursor = `pointer`
    store.setPointHoverId(getFeaturePointId(e))
    store.setMapFeatureHover(true)
  }

  const featureMouseLeave = () => {
    store.map.getCanvas().style.cursor = null
    store.setPointHoverId(0)
    store.setMapFeatureHover(false)
  }

  const featureClick = (e) => {
    if (store.measure || store.traffic) return
    if (e.defaultPrevented) return
    if (!e.features.length) return
    if (isFeatureInvisible(e)) return
    e.preventDefault()

    store.setPointHoverId(0)
    store.setMapFeatureHover(false)
    store.setPointActiveId(getFeaturePointId(e), `map_feature`)
  }

  const getFeaturePointId = (e) => {
    const feature = e.features[0]
    return [`points`, `points-unclustered`].includes(feature.layer.id)
      ? feature.id
      : feature.properties.parent
  }

  const isFeatureInvisible = (e) => {
    const feature = e.features[0]
    return !!feature.state.invisible
  }

  const mapLoaded = (map) => {
    store.setMap(map)

    toggleDisplayedStyle()

    // store.map.on(`click`, mapClick)
    store.map.on(`move`, toggleDisplayedStyle)

    store.map.on(`click`, `points-cluster`, pointsClusterClick)
    store.map.on(`mousemove`, `points-cluster`, pointsClusterMouseEnter)
    store.map.on(`mouseleave`, `points-cluster`, pointsClusterMouseLeave)

    store.map.on(`mousemove`, `points`, featureMouseEnter)
    store.map.on(`mouseleave`, `points`, featureMouseLeave)
    store.map.on(`mousemove`, `points-unclustered`, featureMouseEnter)
    store.map.on(`mouseleave`, `points-unclustered`, featureMouseLeave)
    store.map.on(`mouseenter`, `trails`, featureMouseEnter)
    store.map.on(`mouseleave`, `trails`, featureMouseLeave)
    store.map.on(`mouseenter`, `areas`, featureMouseEnter)
    store.map.on(`mouseleave`, `areas`, featureMouseLeave)

    store.map.on(`click`, `points`, featureClick)
    store.map.on(`click`, `points-unclustered`, featureClick)
    store.map.on(`click`, `trails`, featureClick)
    store.map.on(`click`, `areas`, featureClick)
  }

  useEffect(() => {
    const shape = store.shapeActive || store.shapePreselected
    if (shape) {
      store.map.setFeatureState(
        { id: shape.id, source: `geojson-shapes` },
        { active: true }
      )
    }
  }, [store.shapeActive, store.shapePreselected])

  useEffect(() => {
    toggleDisplayedStyle()
  }, [store.mapStyle])

  useEffect(() => {
    const reactionPointHoverId = reaction(
      () => store.pointHoverId,
      (value, previousValue) => {
        if (!store.map) return
        if (previousValue) setPointShapeState(previousValue, { hover: false })
        if (value) setPointShapeState(value, { hover: true })
      }
    )

    const reactionPointActiveId = reaction(
      () => store.pointActiveId,
      (value, previousValue) => {
        if (!store.map) return
        if (previousValue) setPointShapeState(previousValue, { active: false })
        if (value) setPointShapeState(value, { active: true })
        if (store.pointPreselectedId) {
          setPointShapeState(store.pointPreselectedId, { active: false })
          store.setPointPreselectedId(0)
        }
      }
    )

    const reactionPointPreselectedId = reaction(
      () => store.pointPreselectedId,
      (value) => {
        if (!store.map) return
        if (value) setPointShapeState(value, { active: true })
        reactionPointPreselectedId()
      }
    )

    return () => {
      reactionPointHoverId()
      reactionPointActiveId()
    }
  }, [])

  return (
    <Container>
      <MapGL
        containerStyle={{
          width: `100%`,
          height: `100%`,
          position: `relative`,
          zIndex: 1,
        }}
        onStyleLoad={mapLoaded}
        zoom={ZOOM}
        center={CENTER}
        fitBounds={!CENTER ? FIT_BOUNDS : undefined}
        fitBoundsOptions={{ duration: 0 }}
        style={mapboxConfig.style}
      >
        <Layer id="base" />

        {mapStyleNames.map((mapStyleName) => (
          <React.Fragment key={mapStyleName}>
            {mapStyleName == displayedStyle && (
              <>
                <Source
                  id="tileset"
                  geoJsonSource={{
                    type: mapStyles[mapStyleName].type,
                    tiles: mapStyles[mapStyleName].tiles,
                    tileSize: mapStyles[mapStyleName].tileSize,
                  }}
                />

                <Layer
                  before="base"
                  sourceId="tileset"
                  type={mapStyles[mapStyleName].type}
                />
              </>
            )}
          </React.Fragment>
        ))}

        <MapSourceShapes />

        <MapSourcePoints />

        <MapSourceUnclustered />

        <MapAreas />

        <MapTrails />

        <MapPoints />

        <MapCustomPin />

        {embedParams.traffic && <MapTraffic />}

        <MapA11y />

        <MapMeasure />

        <MapElevationsMark />

        <MapLocation />
      </MapGL>
    </Container>
  )
}

export default observer(Map)

const Container = styled.section`
  width: 100%;
  height: 100%;
  position: relative;

  .mapboxgl-canvas:focus {
    outline-offset: -2px;
  }

  .mapboxgl-ctrl-attrib.mapboxgl-compact {
    margin: 0;
  }

  .mapboxgl-ctrl-bottom-left {
    display: none;
  }
`
