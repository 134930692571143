import React, { useState, useMemo, useEffect, useCallback } from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { em } from "polished"
import striptags from "striptags"

import Styled from "./Styled"
import { __ } from "../utils/translate"

const briefLimit = 24

const ExpandableText = ({ text }) => {
  const [expanded, setExpanded] = useState(false)

  const brief = useMemo(() => {
    if (text) {
      const b = striptags(text).split(` `)
      if (b.length > briefLimit)
        return (
          b
            .slice(0, briefLimit - 1)
            .join(` `)
            .replace(/\s*([,.!?:;]+)(?!\s*$)\s*/g, `$1 `) + `…`
        )
    }
    return ``
  }, [text])

  useEffect(() => {
    setExpanded(false)
  }, [text])

  const styledRef = useCallback((node) => {
    node?.querySelectorAll(`a`).forEach((a) => {
      a.target = `_blank`
      a.rel = `noopener noreferrer`
    })
  }, [])

  const toggleClick = () => {
    setExpanded((v) => !v)
  }

  if (!text && !brief) return null

  return (
    <Container>
      {!expanded && !!brief && (
        <Brief>
          <p dangerouslySetInnerHTML={{ __html: brief }} />

          <p>
            {` `}
            <button type="button" onClick={toggleClick}>
              {__(`Show more`)}
            </button>
          </p>
        </Brief>
      )}

      {(expanded || !brief) && (
        <Full>
          <Styled
            dangerouslySetInnerHTML={{ __html: text }}
            innerRef={styledRef}
          />

          {!!brief && (
            <Styled>
              <p>
                <button type="button" onClick={toggleClick}>
                  {__(`Show less`)}
                </button>
              </p>
            </Styled>
          )}
        </Full>
      )}
    </Container>
  )
}

export default observer(ExpandableText)

const Container = styled.div``

const Brief = styled(Styled)`
  > p {
    &:nth-last-child(-n + 2) {
      margin-bottom: 0;
      display: inline;
    }
  }
`

const Full = styled.div`
  > * + * {
    margin-top: ${em(14)};
  }
`
