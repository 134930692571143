import React from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { em, rem } from "polished"

import { ReactComponent as SvgWheelchairTested } from "../../assets/images/icons/wheelchair-tested.svg"
import { useStore } from "../../store"
import { a11yCategories } from "../../config/categories"
import { getCategoryIconSrc } from "../../utils/icon"
import { __ } from "../../utils/translate"

const SPECIES = [
  `abborre`,
  `al`,
  `benloja`,
  `bjorkna`,
  `braxen`,
  `farna`,
  `gadda`,
  `gos`,
  `harr`,
  `karp`,
  `lake`,
  `lax`,
  `mort`,
  `oring`,
  `regnbage`,
  `ruda`,
  `sarna`,
  `sarv`,
  `sik`,
  `sikloja`,
  `storroding`,
  `sutare`,
]

const WHEELCHAIR_TESTED_ID = `wheelchair-tested`

const Categories = ({ data }) => {
  const store = useStore()

  const categories = { activitiesFacilities: [], a11y: [], species: [] }

  const title = {
    activitiesFacilities: __(`Activities & facilities`),
    a11y: __(`Accessibility`),
    species: __(`Species`),
  }

  let anyCategories = false

  if (data.wheelchairTested) {
    categories.a11y.push({ id: WHEELCHAIR_TESTED_ID })
    anyCategories = true
  }

  data.categories.map((id) => {
    if (id == data.mainCategory) return
    const category = store.categories.find((c) => c.id == id)

    if (category) {
      let type = `activitiesFacilities`
      if (category.legacyType == `animal`) {
        if (SPECIES.includes(category.slug)) type = `species`
        else type = ``
      } else if (a11yCategories.includes(category.slug)) type = `a11y`

      if (type) {
        categories[type].push(category)
        anyCategories = true
      }
    }
  })

  if (!anyCategories) return null

  return (
    <Container>
      {Object.entries(categories).map(
        ([name, items]) =>
          !!items.length && (
            <Group key={name}>
              <Title>{title[name]}</Title>

              {name == `species` ? (
                <Species>
                  {items.map((category) => (
                    <li key={category.id}>
                      <figure>
                        <img
                          src={`/images/species/${category.slug}.jpg`}
                          alt={category.label}
                        />

                        <figcaption>{category.label}</figcaption>
                      </figure>
                    </li>
                  ))}
                </Species>
              ) : (
                <List>
                  {items.map((category) => (
                    <li key={category.id}>
                      {category.id == WHEELCHAIR_TESTED_ID ? (
                        <SvgWheelchairTested aria-hidden="true" />
                      ) : (
                        <img
                          src={getCategoryIconSrc(category.id, `outline`)}
                          role="presentation"
                          alt=""
                        />
                      )}

                      <span>
                        {category.id == WHEELCHAIR_TESTED_ID
                          ? __(`Tested with wheelchair`)
                          : category.label}
                      </span>
                    </li>
                  ))}
                </List>
              )}
            </Group>
          )
      )}
    </Container>
  )
}

export default observer(Categories)

const Title = styled.h3`
  ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

  margin-bottom: ${em(12)};
  font-size: ${em(15)};
  letter-spacing: ${em(-0.025)};
`

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${em(24)};
  row-gap: ${em(10)};

  li {
    font-size: ${em(12)};
    display: flex;
    align-items: baseline;
    gap: ${em(10)};
  }

  img,
  svg {
    width: ${em(26)};
    flex-shrink: 0;
  }

  svg {
    height: ${em(26)};
  }

  span {
    ${({ theme }) => theme.fonts.set(`primary`, `medium`)}

    position: relative;
    top: ${em(-8)};
  }
`

const Species = styled.ul`
  ${({ theme }) => theme.fonts.set(`primary`, `medium`)}

  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: ${em(10)};

  @container (max-width: ${em(640)}) {
    grid-template-columns: repeat(6, 1fr);
  }

  @container (max-width: ${em(480)}) {
    grid-template-columns: repeat(4, 1fr);
  }

  @container (max-width: ${em(320)}) {
    grid-template-columns: repeat(3, 1fr);
  }

  li {
    /* */
  }

  figure {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${em(10)};
    overflow: hidden;
    text-align: center;
  }

  img {
    width: ${em(56)};
    margin-top: ${em(-10)};
  }

  figcaption {
    margin-top: ${em(-30)};
    position: relative;
    z-index: 1;
    font-size: ${em(12)};
  }
`

const Group = styled.section`
  margin: ${em(10)} 0;
  padding: ${em(10)} 0;
  container-type: inline-size;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
`

const Container = styled.div`
  padding: 0 ${rem(20)};

  @media ${({ theme }) => theme.mq.xxsmallDown} {
    padding-left: ${rem(10)};
    padding-right: ${rem(10)};
  }

  .site-toolbar + & {
    ${Group}:first-child {
      border-top: none;
    }
  }
`
