import React from "react"
import styled from "styled-components"
import { em, rem } from "polished"

import Button from "../Button"
import { ReactComponent as SvgDownload } from "../../assets/images/icons/download.svg"
import { __ } from "../../utils/translate"

const Audio = ({ data }) => {
  if (!data.audioUrl) return null

  return (
    <Container>
      <Box>
        <Title>{data.audioDescription || __(`Sound`)}</Title>

        <Download>
          <Button
            href={data.audioUrl}
            icon={<SvgDownload />}
            title={__(`Download`)}
            size="xsmall"
            download=""
          />
        </Download>

        <Player>
          <audio src={data.audioUrl} controls />
        </Player>
      </Box>
    </Container>
  )
}

export default Audio

const Container = styled.section`
  padding: ${em(10)} ${rem(20)};

  @media ${({ theme }) => theme.mq.xxsmallDown} {
    padding-left: ${rem(10)};
    padding-right: ${rem(10)};
  }
`

const Box = styled.div`
  padding: ${em(14)};
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: ${em(20)};
  row-gap: ${em(14)};
  background-color: ${({ theme }) => theme.colors.lightGray};
  border-radius: ${({ theme }) => theme.br.normal};
`

const Title = styled.h3`
  ${({ theme }) => theme.fonts.set(`primary`, `medium`)}

  font-size: ${em(13)};
  letter-spacing: ${em(-0.025)};
`

const Download = styled.div`
  grid-column: 2;
  margin-top: em(-8);
`

const Player = styled.div`
  grid-column: 1 / span 2;

  audio {
    width: 100%;
    display: block;
  }
`
