import React, { useCallback, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { toJS } from "mobx"
import styled, { css } from "styled-components"
import { em, rem } from "polished"

import FormField from "./FormField"
import Button from "../Button"
import { useStore } from "../../store"
import useAbortController from "../../utils/useAbortController"
import { fetchApiTrafficStops } from "../../utils/api"
import { __ } from "../../utils/translate"

const Form = () => {
  const store = useStore()
  const { signal } = useAbortController()
  const [loading, setLoading] = useState(false)

  const hasAllValues = !!store.trafficStart && !!store.trafficFinish

  useEffect(() => {
    const point = store.pointActive || store.pointPreselected
    if (point && !store.trafficFinish) {
      setLoading(true)

      fetchApiTrafficStops(
        {
          lat: point.geometry.coordinates[1],
          lng: point.geometry.coordinates[0],
        },
        { signal }
      )
        .then((stops) => {
          if (stops.length) store.setTrafficFinish(stops[0])
        })
        .catch(() => null)
        .finally(() => setLoading(false))
    }
  }, [store.pointActive, store.pointPreselected])

  const switchValues = () => {
    const startValueCloned = toJS(store.trafficStart)
    const finishValueCloned = toJS(store.trafficFinish)

    store.setTrafficStart(finishValueCloned)
    store.setTrafficFinish(startValueCloned)
    store.setTrafficChoices(
      toJS(
        store.trafficChoices.map((c) => ({
          ...c,
          _choice: c._choice == `start` ? `finish` : `start`,
        }))
      )
    )
  }

  const formSubmit = useCallback(
    (e) => {
      e.preventDefault()
      if (hasAllValues) store.setTraffic(`trips:loading`)
    },
    [hasAllValues]
  )

  const startChange = (v) => store.setTrafficStart(v)
  const finishChange = (v) => store.setTrafficFinish(v)

  return (
    <Container onSubmit={formSubmit} $loading={loading}>
      <FormField
        type="start"
        value={store.trafficStart}
        onChange={startChange}
      />

      <FormField
        type="finish"
        value={store.trafficFinish}
        onChange={finishChange}
        onSwitchValues={switchValues}
      />

      <Button
        type="submit"
        color="white"
        fill={hasAllValues ? `seaGreen` : `cadet`}
        block={true}
      >
        {loading ? `${__(`Loading`)}…` : __(`Search public transport`)}
      </Button>
    </Container>
  )
}

export default observer(Form)

const Container = styled.form`
  padding: 0 ${rem(20)};
  display: flex;
  flex-direction: column;
  gap: ${em(20)};

  @media ${({ theme }) => theme.mq.xxsmallDown} {
    padding: 0 ${rem(10)};
  }

  ${({ $loading }) =>
    $loading &&
    css`
      pointer-events: none;
      animation: ${({ theme }) => theme.animations.fadeBlink} 1s infinite linear;
    `}
`
