import React from "react"
import styled from "styled-components"
import { rem } from "polished"

import SiteTrailStatus from "../SiteTrailStatus"

const TrailStatus = ({ data }) => {
  if (!data.trailStatusReportedAt) return null

  return (
    <Container>
      <SiteTrailStatus reportedAt={data.trailStatusReportedAt} />
    </Container>
  )
}

export default TrailStatus

const Container = styled.section`
  padding: ${rem(6)} ${rem(20)};

  @media ${({ theme }) => theme.mq.xxsmallDown} {
    padding-left: ${rem(10)};
    padding-right: ${rem(10)};
  }
`
