import React from "react"
import { observer } from "mobx-react-lite"
import { Source } from "react-mapbox-gl"

import { useStore } from "../store"

const MapSourcePoints = () => {
  const store = useStore()

  return (
    <Source
      id="geojson-points"
      geoJsonSource={{
        type: `geojson`,
        data: {
          type: `FeatureCollection`,
          features: store.pointsHide ? [] : store.points.slice(),
        },
        cluster: true,
        clusterMaxZoom: 11, // +1 in reality
        clusterMinPoints: 4,
        clusterRadius: 30,
      }}
    />
  )
}

export default observer(MapSourcePoints)
