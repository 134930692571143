import React, { useEffect, useState, useMemo } from "react"
import { Layer, Source } from "react-mapbox-gl"
import { observer } from "mobx-react-lite"
import { toJS } from "mobx"
import {
  point as turfPoint,
  lineString as turfLineString,
  featureCollection as turfFeatureCollection,
} from "@turf/helpers"
import turfBbox from "@turf/bbox"

import Tooltip from "./Tooltip"
import { getLegIconAndColor } from "./Traffic/Trippy"
import generateId from "../utils/generateId"
// import { postEmbedMessage } from "../utils/embedMessage"
import colors from "../theme/colors"
import { useStore } from "../store"

const MapTraffic = () => {
  const store = useStore()
  const [tooltip, setTooltip] = useState(``)

  const features = useMemo(() => {
    if (!store.traffic) return []

    const features = []
    const choiceExceptions = []

    if (store.trafficTripActive?.legs?.length) {
      const legs = []

      store.trafficTripActive.legs.forEach((_leg, index) => {
        // `WALK|TRSF` legs doesn't contain any stops, let's polyfill this
        const leg = toJS(_leg)
        if ([`WALK`, `TRSF`].includes(leg.type)) {
          leg.stops = []

          if (!index) {
            // stops for first leg
            leg.stops.push(store.trafficStart)
            leg.stops.push(store.trafficTripActive.legs[index + 1]?.stops?.[0])
          } else if (store.trafficTripActive.legs.length - 1 == index) {
            // stops last leg
            leg.stops.push(
              store.trafficTripActive.legs[index - 1]?.stops?.[
                (store.trafficTripActive.legs[index - 1].stops.length || 0) - 1
              ]
            )
            leg.stops.push(store.trafficFinish)
          } else {
            // stops for in-between leg
            leg.stops.push(
              store.trafficTripActive.legs[index - 1]?.stops?.[
                (store.trafficTripActive.legs[index - 1].stops.length || 0) - 1
              ]
            )
            leg.stops.push(store.trafficTripActive.legs[index + 1]?.stops?.[0])
          }

          leg.stops = leg.stops.filter(Boolean)
        }

        legs.push(leg)
      })

      legs.forEach((leg, legIndex) => {
        if (!leg.stops?.length) return null

        const { color } = getLegIconAndColor(leg)

        features.push(
          turfLineString(
            leg.stops.map((stop, i) => {
              const coordinates = [stop.lng, stop.lat]
              let type = `normal`

              if (!legIndex && !i) {
                type = `start`
                choiceExceptions.push(String(stop.sourceId))
              } else if (
                legIndex == store.trafficTripActive.legs.length - 1 &&
                i == leg.stops.length - 1
              ) {
                type = `finish`
                choiceExceptions.push(String(stop.sourceId))
              }

              features.push(
                turfPoint(
                  coordinates,
                  { type, color, name: stop.name },
                  { id: String(generateId()) }
                )
              )

              return coordinates
            }),
            { color },
            { id: String(generateId()) }
          )
        )

        return true
      })
    } else {
      if (store.trafficStart) {
        features.push(
          turfPoint(
            [store.trafficStart.lng, store.trafficStart.lat],
            { type: `start`, name: store.trafficStart.name },
            { id: String(generateId()) }
          )
        )
        choiceExceptions.push(String(store.trafficStart.sourceId))
      }

      if (store.trafficFinish) {
        features.push(
          turfPoint(
            [store.trafficFinish.lng, store.trafficFinish.lat],
            { type: `finish`, name: store.trafficFinish.name },
            { id: String(generateId()) }
          )
        )
        choiceExceptions.push(String(store.trafficFinish.sourceId))
      }
    }

    if (!store.trafficChoicesHide) {
      store.trafficChoices?.map((c) => {
        if (choiceExceptions.includes(c.sourceId)) return
        choiceExceptions.push(c.sourceId)

        features.push(
          turfPoint(
            [c.lng, c.lat],
            {
              type: c._choice == `start` ? `start-choice` : `finish-choice`,
              name: c.name,
              choice: toJS(c),
            },
            { id: String(generateId()) }
          )
        )
      })
    }

    return features
  }, [
    store.traffic,
    store.trafficStart,
    store.trafficFinish,
    store.trafficTripActive,
    store.trafficChoices,
    store.trafficChoicesHide,
  ])

  const choiceClick = (e) => {
    const isStart = e.features[0].properties.type == `start-choice`
    const choice = JSON.parse(e.features[0].properties.choice)
    if (isStart) store.setTrafficStart(choice)
    else store.setTrafficFinish(choice)
    store.setTrafficTripActiveId(0)
    store.setTrafficMobileMap(false)
  }

  const featureMouseEnter = (e) => {
    const isChoice = e.features[0].layer.id == `stops-choices`
    store.setPointHoverId(0)
    store.map.getCanvas().style.cursor = isChoice ? `pointer` : `default`
    setTooltip(e.features[0].properties.name)
  }

  const featureMouseLeave = () => {
    store.map.getCanvas().style.cursor = null
    setTooltip(``)
  }

  useEffect(() => {
    store.map.on(`click`, `stops-choices`, choiceClick)
    store.map.on(`mousemove`, `stops-normal`, featureMouseEnter)
    store.map.on(`mouseleave`, `stops-normal`, featureMouseLeave)
    store.map.on(`mousemove`, `stops-edges`, featureMouseEnter)
    store.map.on(`mouseleave`, `stops-edges`, featureMouseLeave)
    store.map.on(`mousemove`, `stops-choices`, featureMouseEnter)
    store.map.on(`mouseleave`, `stops-choices`, featureMouseLeave)

    return () => {
      store.map.off(`mousemove`, `stops-normal`, featureMouseEnter)
      store.map.off(`mouseleave`, `stops-normal`, featureMouseLeave)
      store.map.off(`mousemove`, `stops-edges`, featureMouseEnter)
      store.map.off(`mouseleave`, `stops-edges`, featureMouseLeave)
      store.map.off(`mousemove`, `stops-choices`, featureMouseEnter)
      store.map.off(`mouseleave`, `stops-choices`, featureMouseLeave)
    }
  }, [])

  useEffect(() => {
    if (features.length) {
      store.travelMap({
        instant: false,
        fitBounds: turfBbox(
          turfFeatureCollection(
            features
              .concat(store.pointActive || store.pointPreselected)
              .filter(Boolean)
          )
        ),
      })
    }
  }, [features])

  if (!features.length) return null

  return (
    <>
      <Source
        id="geojson-stops"
        geoJsonSource={{
          type: `geojson`,
          data: {
            type: `FeatureCollection`,
            features,
          },
        }}
      />

      {/* lines */}
      {/* <Layer
        sourceId="geojson-stops"
        type="line"
        layout={{
          "line-join": `round`,
          "line-cap": `round`,
        }}
        paint={{
          "line-width": 5,
          "line-color": colors.black,
        }}
        filter={[`==`, `$type`, `LineString`]}
      /> */}

      <Layer
        sourceId="geojson-stops"
        type="line"
        layout={{
          "line-join": `round`,
          "line-cap": `round`,
        }}
        paint={{
          "line-width": 4,
          "line-color": [`get`, `color`],
        }}
        filter={[`==`, `$type`, `LineString`]}
      />

      {/* stops */}
      <Layer
        id="stops-normal"
        sourceId="geojson-stops"
        type="circle"
        paint={{
          "circle-radius": 6,
          "circle-color": [`get`, `color`],
          // "circle-stroke-color": colors.black,
          // "circle-stroke-width": 2,
        }}
        filter={[`all`, [`==`, `$type`, `Point`], [`==`, `type`, `normal`]]}
      />

      {/* start/finish choices */}
      <Layer
        id="stops-choices"
        sourceId="geojson-stops"
        type="circle"
        paint={{
          "circle-radius": 4,
          "circle-color": colors.white,
          // "circle-color": [
          //   `case`,
          //   [`==`, [`get`, `type`], `start-choice`],
          //   colors.seaGreen,
          //   [`==`, [`get`, `type`], `finish-choice`],
          //   colors.maximumPurple,
          //   [`get`, `color`],
          // ],
          "circle-stroke-width": 6,
          // "circle-stroke-color": colors.white,
          "circle-stroke-color": [
            `case`,
            [`==`, [`get`, `type`], `start-choice`],
            colors.seaGreen,
            [`==`, [`get`, `type`], `finish-choice`],
            colors.maximumPurple,
            [`get`, `color`],
          ],
        }}
        filter={[
          `all`,
          [`==`, `$type`, `Point`],
          [`in`, `type`, `start-choice`, `finish-choice`],
        ]}
      />

      {/* start/finish */}
      <Layer
        id="stops-edges"
        sourceId="geojson-stops"
        type="symbol"
        layout={{
          "icon-allow-overlap": true,
          "icon-image": [
            `case`,
            [`==`, [`get`, `type`], `start`],
            `stops-a`,
            [`==`, [`get`, `type`], `finish`],
            `stops-b`,
            ``,
          ],
          "icon-anchor": `bottom`,
          "icon-size": 1,
          "symbol-z-order": `source`,
        }}
        filter={[
          `all`,
          [`==`, `$type`, `Point`],
          [`in`, `type`, `start`, `finish`],
        ]}
      />
      {/* <Layer
        id="stops-edges"
        sourceId="geojson-stops"
        type="circle"
        paint={{
          "circle-radius": 12,
          "circle-color": [
            `case`,
            [`==`, [`get`, `type`], `start`],
            colors.seaGreen,
            [`==`, [`get`, `type`], `finish`],
            colors.maximumPurple,
            [`get`, `color`],
          ],
          "circle-stroke-color": colors.black,
          "circle-stroke-width": 2,
        }}
        filter={[
          `all`,
          [`==`, `$type`, `Point`],
          [`in`, `type`, `start`, `finish`],
        ]}
      />

      <Layer
        sourceId="geojson-stops"
        type="symbol"
        layout={{
          "text-font": [`Open Sans Bold`],
          "text-size": 12,
          "text-field": [
            `case`,
            [`==`, [`get`, `type`], `start`],
            `A`,
            [`==`, [`get`, `type`], `finish`],
            `B`,
            ``,
          ],
        }}
        paint={{
          "text-color": colors.white,
        }}
        filter={[
          `all`,
          [`==`, `$type`, `Point`],
          [`in`, `type`, `start`, `finish`],
        ]}
      /> */}

      {tooltip && <Tooltip>{tooltip}</Tooltip>}
    </>
  )
}

export default observer(MapTraffic)
