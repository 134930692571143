const getOffset = (el) => {
  const rect = el.getBoundingClientRect()
  return {
    top: rect.top + document.body.scrollTop,
    left: rect.left + document.body.scrollLeft,
  }
}

const scrollToTargetInParent = (parent, target, position = `top`) => {
  const parentOffset = getOffset(parent)
  const targetOffset = getOffset(target)

  let top = parent.scrollTop + targetOffset.top - parentOffset.top
  let left = parent.scrollLeft + targetOffset.left - parentOffset.left

  if (position == `center`) {
    top = top - parent.offsetHeight / 2 + target.offsetHeight / 2
    left = left - parent.offsetWidth / 2 + target.offsetWidth / 2
  }

  parent.scrollTop = top
  parent.scrollLeft = left
}
export { scrollToTargetInParent }
