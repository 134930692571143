import queryString from "query-string"
import { camelizeKeys } from "camelize-object-keys"

import { mapStyleNames, mapStyleNameDefault } from "./mapStyles"
import { parseEmbedQuery } from "../utils/query"
import { languages, defaultLanguage } from "../translations"

const params = camelizeKeys(queryString.parse(window.location.search))
const { legacyStrict, legacyGuide } = parseEmbedQuery(params.query)

Object.entries(params).forEach(([key, value]) => {
  if (value === `true`) params[key] = true
  else if (value === `false`) params[key] = false
})

params.strict = legacyStrict || params.strict || ``
params.guide = legacyGuide || params.guide || 0

if (!params.language || !languages.includes(params.language))
  params.language = defaultLanguage

if (params.style || !mapStyleNames.includes(params.style))
  params.style = mapStyleNameDefault

params.apiBase = (
  params.apiBase ||
  process.env.REACT_APP_API_BASE ||
  `https://api.naturkartan.se`
).replace(/\/+$/, ``)

params.naturkartanBase =
  params.naturkartanBase?.replace(/\/$/, ``) || `https://www.naturkartan.se`

params.typesenseEnv =
  params.typesenseEnv || process.env.REACT_APP_TYPESENSE_ENV || `production`

if (typeof params.autoBounds == `undefined`) params.autoBounds = true

if (params.bounds)
  params.bounds = params.bounds.split(`,`).map((b) => parseFloat(b))

if (params.center)
  params.center = params.center.split(`,`).map((c) => parseFloat(c))

if (params.zoom) params.zoom = parseFloat(params.zoom)

if (params.categoryNames)
  try {
    params.categoryNames = JSON.parse(params.categoryNames)
  } catch (e) {
    params.categoryNames = {}
  }
else params.categoryNames = {}

if (typeof params.quickCategories == `string`)
  params.quickCategories =
    params.quickCategories.split(`,`).map((id) => String(id)) || false

if (params.customPin)
  params.customPin = camelizeKeys(queryString.parse(params.customPin))

if (params.traffic !== false) {
  const trafficWhitelist = [`naturkartan.se`, `gotland.com`]
  if (
    trafficWhitelist.includes(params.parentBase) ||
    process.env.NODE_ENV == `development`
  )
    params.traffic = true
}

// legacy

if (legacyStrict) {
  if (params.quickCategories === undefined) params.quickCategories = []
  params.importanceControl = false
  params.a11YControl = false
}

if (params.menuActive)
  params.menu = params.menuActive == `widescreen` ? true : params.menuActive

if (params.menu == `widescreen`) params.menu = true

if (params.menuSort == `abc`) params.menuSort = `az`

if (params.stf) {
  params.client = `stf`
  try {
    if (JSON.parse(params.stf)?.trips) params.strict = `trip`
  } catch {
    //
  }
} else if (params.satellite) params.client = `satellite`
else if (params.bergslagsleden) params.client = `bergslagsleden`
else if (params.stolavsleden) params.client = `stolavsleden`
else if (params.roslagen) params.client = `roslagen`
else if (params.vanilla) params.client = `vanilla`

// ---

export default params
