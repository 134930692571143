import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { useMediaQuery } from "@react-hook/media-query"
import styled, { css } from "styled-components"
import { em, rem, rgba } from "polished"

import ZoomControl from "./ZoomControl"
import StyleControl from "./StyleControl"
import CloseControl from "./CloseControl"
import FullscreenControl from "./FullscreenControl"
import MeasureControl from "./MeasureControl"
import LocationControl from "./LocationControl"
import PrintControl from "./PrintControl"
import EmbedControl from "./EmbedControl"
import LegendControl from "./LegendControl"
import ElevationsControl from "./ElevationsControl"
import TrafficControl from "./TrafficControl"
import Toolbar from "./Toolbar"
import ToolbarTraffic from "./ToolbarTraffic"
import Legend from "./Legend"
import PrintZone from "./PrintZone"
import Measure from "./Measure"
import { ReactComponent as SvgChevronDown } from "../assets/images/icons/chevron-down.svg"
import { useStore } from "../store"
import embedParams from "../config/embedParams"
import { __ } from "../utils/translate"
import mq from "../theme/mq"
import logoWhite from "../assets/images/logo-white.svg"

const Controls = () => {
  const store = useStore()
  const isMobile = useMediaQuery(mq.mobileDown)
  const isDesktop = !isMobile
  const [showAllToggles, setShowAllToggles] = useState(false)
  const isMiniguide = [`trip`, `list`].includes(embedParams.strict)

  const isDesktopOrMobileNotFullscreen =
    !isMobile || (isMobile && !store.fullscreen)

  const isDesktopOrMobileFullscreen =
    !isMobile || (isMobile && store.fullscreen)

  const isMobileFullscreen = isMobile && store.fullscreen
  const isMobileNotFullscreen = isMobile && !store.fullscreen

  const showAllToggleClick = () => {
    setShowAllToggles((v) => !v)
  }

  return (
    <Container>
      {store.print && (
        <PrintZoneWrap>
          <PrintZone />
        </PrintZoneWrap>
      )}

      <ToolbarWrap $mobileFullscreen={isMobileFullscreen}>
        {store.traffic ? <ToolbarTraffic /> : <Toolbar />}
      </ToolbarWrap>

      <TopRight
        $mobileFullscreen={isMobileFullscreen}
        $mobileAndNotFullscreen={isMobileNotFullscreen}
        $print={store.print}
        // $traffic={store.traffic && store.trafficMobileMap}
      >
        <CloseControl />

        {(isMiniguide || isDesktopOrMobileNotFullscreen) && !store.print && (
          <FullscreenControl />
        )}

        {(isDesktop || (isMobileFullscreen && showAllToggles)) && (
          <>
            <StyleControl />

            <MeasureControl />

            <PrintControl />

            <EmbedControl />

            <LegendControl />

            <ElevationsControl />

            {embedParams.traffic && <TrafficControl />}
          </>
        )}

        {isMobileFullscreen && (
          <ShowAllToggle
            onClick={showAllToggleClick}
            title={__(`Show all`)}
            $active={showAllToggles}
          >
            <SvgChevronDown aria-label={__(`Show all`)} />
          </ShowAllToggle>
        )}
      </TopRight>

      <BottomRight>
        {isDesktopOrMobileFullscreen && <LocationControl />}

        <ZoomControl />
      </BottomRight>

      {isDesktopOrMobileFullscreen && (
        <BottomLeft $print={store.print}>
          <Measure />

          <Legend />
        </BottomLeft>
      )}

      {isDesktopOrMobileNotFullscreen && (
        <PoweredBy
          href={`${embedParams.naturkartanBase}/${embedParams.language}/`}
          rel="noopener nofollow"
          target="_blank"
          title="Powered by Naturkartan"
        >
          <img
            src={logoWhite}
            alt="Powered by Naturkartan"
            title="Powered by Naturkartan"
          />
        </PoweredBy>
      )}

      {isDesktopOrMobileNotFullscreen && (
        <Attribution>
          <a
            href="http://www.openstreetmap.org/about"
            target="_blank"
            rel="noopener noreferrer"
          >
            © OpenStreetMap
          </a>
          {` `}
          <a
            href="http://www.thunderforest.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            © Thunderforest
          </a>
        </Attribution>
      )}
    </Container>
  )
}

export default observer(Controls)

const PrintZoneWrap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 35;
  top: 0;
  left: 0;
`

const ToolbarWrap = styled.div`
  ${({ theme }) => theme.mixins.hideScrollbar()}

  gap: ${em(10)};
  padding: ${rem(10)} ${rem(8)};
  position: absolute;
  z-index: 30;
  top: ${rem(20)};
  left: 0;
  right: ${rem(80)};
  pointer-events: auto;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  mask: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) ${rem(10)},
    rgba(0, 0, 0, 1) calc(100% - ${rem(10)}),
    rgba(0, 0, 0, 0) 100%
  );

  &:empty {
    display: none;
  }

  @media ${({ theme }) => theme.mq.xxlargeDown} {
    top: ${rem(4)};
    right: ${rem(60)};

    ${({ $mobileFullscreen }) =>
      $mobileFullscreen &&
      css`
        right: 0;
        mask: none;
      `};
  }

  @media only print {
    visibility: hidden;
  }
`

const FullscreenControlWrap = styled.div`
  &:empty {
    display: none;
  }
`

const TopRight = styled.div`
  ${({ theme }) => theme.mixins.hideScrollbar()}

  max-height: calc(100% - ${rem(200)});
  padding: ${rem(10)} ${rem(9)};
  position: absolute;
  z-index: 40;
  top: ${rem(20)};
  right: ${rem(20)};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  pointer-events: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  mask: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) ${rem(10)},
    rgba(0, 0, 0, 1) calc(100% - ${rem(10)}),
    rgba(0, 0, 0, 0) 100%
  );

  @media ${({ theme }) => theme.mq.xxlargeDown} {
    max-height: calc(
      100% -
        ${({ $mobileAndNotFullscreen }) =>
          rem($mobileAndNotFullscreen ? 0 : 226)}
    );
    top: ${({ $mobileFullscreen, $print, $traffic }) =>
      rem($mobileFullscreen && !$print && !$traffic ? 60 : 4)};
    right: 0;
  }

  @media only print {
    visibility: hidden;
  }

  &:empty {
    display: none;
  }

  > * {
    pointer-events: auto;

    + * {
      margin-top: ${rem(6)};
    }
  }

  > ${FullscreenControlWrap}:not(:empty) + * {
    margin-top: ${rem(8)};
  }
`

const ShowAllToggle = styled.button.attrs({ type: `button` })`
  width: ${rem(42)};
  height: ${rem(42)};
  padding: ${rem(12)};
  display: block;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.bs.button};
  border-radius: ${({ theme }) => theme.br.normal};

  svg {
    width: 100%;
    height: 100%;
    display: block;

    ${({ $active }) =>
      $active &&
      css`
        transform: rotate(180deg);
      `}
  }
`

const BottomRight = styled.div`
  position: absolute;
  z-index: 50;
  bottom: ${rem(30)};
  right: ${rem(30)};

  @media ${({ theme }) => theme.mq.xxlargeDown} {
    bottom: ${rem(8)};
    right: ${rem(8)};
  }

  @media only print {
    visibility: hidden;
  }

  &:empty {
    display: none;
  }

  > * {
    pointer-events: auto;

    + * {
      margin-top: ${rem(14)};
    }
  }
`

const BottomLeft = styled.div`
  max-width: calc(100% - ${rem(100)});
  position: absolute;
  z-index: 60;
  bottom: ${rem(30)};
  left: ${rem(10)};
  pointer-events: none;

  ${({ $print }) =>
    $print &&
    css`
      top: ${rem(10)};
      bottom: auto;
    `}

  @media ${({ theme }) => theme.mq.xxlargeDown} {
    max-width: calc(100% - ${rem(80)});
    bottom: ${rem(8)};
  }

  &:empty {
    display: none;
  }

  > * {
    pointer-events: auto;
  }
`

const Attribution = styled.div`
  ${({ theme }) => theme.mixins.hideScrollbar()}

  position: absolute;
  z-index: 10;
  bottom: ${rem(10)};
  left: 0;
  right: ${rem(210)};
  padding: 0 ${rem(10)};
  white-space: nowrap;
  overflow-x: scroll;
  pointer-events: auto;
  -webkit-overflow-scrolling: touch;
  mask: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) ${rem(10)},
    rgba(0, 0, 0, 1) calc(100% - ${rem(10)}),
    rgba(0, 0, 0, 0) 100%
  );
  font-size: ${rem(10)};
  text-shadow: 0 0 ${rem(2)} rgba(0, 0, 0, 0.7);
  color: ${({ theme }) => theme.colors.white};

  @media ${({ theme }) => theme.mq.xxlargeDown} {
    right: ${rem(170)};
  }

  @media ${({ theme }) => theme.mq.mobileDown} {
    bottom: ${rem(4)};
  }

  a {
    pointer-events: auto;

    &:hover,
    &:focus {
      text-shadow: 0 0 ${rem(3)} rgba(0, 0, 0, 1);
    }
  }
`

const PoweredBy = styled.a`
  width: ${rem(85)};
  height: ${rem(25)};
  position: absolute;
  z-index: 20;
  bottom: ${rem(26)};
  right: ${rem(30)};
  pointer-events: auto;

  ${BottomLeft} ~ & {
    right: ${rem(100)};
  }

  @media ${({ theme }) => theme.mq.xxlargeDown} {
    bottom: ${rem(12)};
    right: ${rem(70)};

    ${BottomLeft} ~ & {
      right: ${rem(70)};
    }
  }

  @media ${({ theme }) => theme.mq.mobileDown} {
    bottom: ${rem(1)};
  }

  @media print {
    right: ${rem(20)};
  }

  &:hover,
  &:focus {
    img {
      filter: drop-shadow(
          ${rem(1)} ${rem(1)} ${rem(2)}
            ${({ theme }) => rgba(theme.colors.black, 0.6)}
        )
        drop-shadow(
          ${rem(-1)} ${rem(-1)} ${rem(2)}
            ${({ theme }) => rgba(theme.colors.black, 0.6)}
        );
    }
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
    filter: drop-shadow(
        ${rem(1)} ${rem(1)} ${rem(1)}
          ${({ theme }) => rgba(theme.colors.black, 0.3)}
      )
      drop-shadow(
        ${rem(-1)} ${rem(-1)} ${rem(1)}
          ${({ theme }) => rgba(theme.colors.black, 0.3)}
      );
  }
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`
