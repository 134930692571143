import React, { memo } from "react"
import styled from "styled-components"
import { em, rem, hideVisually } from "polished"
import { DateTime, Duration } from "luxon"

import { getLegIconAndColor } from "./Trippy"
import { __, _n } from "../../utils/translate"

const TripLegs = memo(({ legs }) => {
  return (
    <Container>
      {legs.map((leg) => {
        const { icon, color } = getLegIconAndColor(leg)
        const startDate = DateTime.fromISO(leg.startDate)
        const finishDate = DateTime.fromISO(leg.finishDate)

        return (
          <Leg key={leg.id} style={{ "--color": color }}>
            <p>
              <span>{__(`Departure`)}</span>

              <time
                dateTime={leg.startDate}
                title={startDate.toFormat(`yyyy-LL-dd`)}
              >
                {startDate.toFormat(`HH:mm`)}
              </time>

              <strong>{leg.startName}</strong>
            </p>

            <div>
              <div>{icon}</div>

              <p>
                <b>{leg.name}</b>
                {!!leg.direction && ` → ${leg.direction}`}
                <br />
                <span>{__(`Duration`)}:</span>
                {Duration.fromISO(leg.duration).as(`minutes`)} min
                {!!leg.stops.length &&
                  `, ${_n(`%{num} stop`, `%{num} stops`, leg.stops.length, { num: leg.stops.length })}`}
                {!!leg.operators.length && (
                  <>
                    <br />
                    {__(`Run by`)}
                    {leg.operators.join(`, `)}
                  </>
                )}
              </p>
            </div>

            <p>
              <span>{__(`Arrival`)}</span>

              <time
                dateTime={leg.finishDate}
                title={finishDate.toFormat(`yyyy-LL-dd`)}
              >
                {finishDate.toFormat(`HH:mm`)}
              </time>

              <strong>{leg.finishName}</strong>
            </p>
          </Leg>
        )
      })}
    </Container>
  )
})

export default TripLegs

const Container = styled.ul`
  padding: ${em(20)} ${rem(20)};

  @media ${({ theme }) => theme.mq.xxsmallDown} {
    padding: ${em(12)} ${rem(10)};
  }

  > li + li {
    margin-top: ${em(14)};
  }
`

const Leg = styled.li`
  padding-left: ${em(20)};
  position: relative;
  font-size: ${em(12)};
  background-image: linear-gradient(var(--color), var(--color));
  background-size: 1px calc(100% - ${em(50)});
  background-repeat: no-repeat;
  background-position: left center;

  &::before,
  &::after {
    content: "";
    width: ${em(9)};
    height: ${em(9)};
    position: absolute;
    left: ${em(-4)};
    background-color: var(--color);
    border-radius: 50%;
  }

  &::before {
    top: ${em(16)};
  }

  &::after {
    bottom: ${em(18)};
  }

  > * {
    padding: ${em(10)} 0;
    display: grid;
    grid-template-columns: ${em(50)} 1fr;
    gap: ${em(10)};
    border-bottom: 1px solid ${({ theme }) => theme.colors.timberwolf};
  }

  time {
    font-size: ${em(12)};
  }

  span {
    ${hideVisually}
  }

  b {
    font-size: ${em(15)};
    display: inline-block;
    padding: 0 ${em(6)} ${em(1)};
    border-radius: ${({ theme }) => theme.br.xsmall};
    color: ${({ theme }) => theme.colors.white};
    background-color: var(--color);
  }

  svg {
    width: ${em(30)};
    height: ${em(30)};
    display: block;
    color: var(--color);
  }
`
