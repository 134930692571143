import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { em } from "polished"

import ShareModal from "../ShareModal"
import { ReactComponent as SvgShare } from "../../assets/images/icons/share.svg"
import { ReactComponent as SvgBook } from "../../assets/images/icons/book.svg"
import { useStore } from "../../store"
import { transparentPlaceholderImg, paramizeImgSrc } from "../../utils/img"
import { __ } from "../../utils/translate"
import embedParams from "../../config/embedParams"

const Header = ({ data }) => {
  const store = useStore()
  const [shareModalActive, setShareModalActive] = useState(false)

  const shareClick = () => setShareModalActive(true)
  const shareModalClose = () => setShareModalActive(false)

  const guide = data.guideId && store.findGuide(data.guideId)

  let dateNice = ``
  let dateFull = ``

  if (data.date) {
    const date = new Date(data.date)
    dateNice = new Intl.DateTimeFormat(embedParams.language, {
      dateStyle: `long`,
    }).format(date)
    dateFull = new Intl.DateTimeFormat(embedParams.language, {
      dateStyle: `full`,
      timeStyle: `long`,
    }).format(date)
  }

  return (
    <>
      <Container>
        <Title>{data.name}</Title>

        <Author>
          {guide ? (
            <a
              href={`${embedParams.naturkartanBase}/${embedParams.language}/${guide.slug}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <SvgBook aria-hidden="true" />

              <span>{guide.name}</span>
            </a>
          ) : (
            <a
              href={`${embedParams.naturkartanBase}/${embedParams.language}/users/${data.user.username}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={
                  (data.user.image &&
                    paramizeImgSrc({
                      src: data.user.image,
                      width: 60,
                      height: 60,
                    })) ||
                  transparentPlaceholderImg
                }
                alt={data.user.name}
              />

              <span>{data.user.name}</span>
            </a>
          )}
        </Author>

        {data.date && (
          <Time dateTime={data.date} title={dateFull}>
            {dateNice}
          </Time>
        )}

        <Share>
          <button type="button" onClick={shareClick}>
            <SvgShare aria-hidden="true" />

            <span>{__(`Share`)}</span>
          </button>
        </Share>
      </Container>

      {shareModalActive && (
        <ShareModal
          onClose={shareModalClose}
          url={`${embedParams.naturkartanBase}/${embedParams.language}/${embedParams.strict}s/${data.slug}`}
          title={data.name}
        />
      )}
    </>
  )
}

export default observer(Header)

const Container = styled.header`
  display: grid;
  grid-template-columns: 1fr auto;
`

const Title = styled.h2`
  ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

  grid-column: 1 / span 2;
  grid-row: 1;
  margin-bottom: ${em(12)};
  font-size: ${em(20)};
`

const Author = styled.p`
  grid-column: 1;
  grid-row: 2;

  a {
    display: flex;
    gap: ${em(6)};
    color: ${({ theme }) => theme.colors.darkSlateGray};

    &:hover {
      color: ${({ theme }) => theme.colors.seaGreen};
    }
  }

  img,
  svg {
    width: ${em(20)};
    height: ${em(20)};
    flex-shrink: 0;
  }

  img {
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.lightGray};
  }

  svg {
    width: ${em(16)};
  }

  span {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

    font-size: ${em(14)};
  }
`

const Time = styled.time`
  ${({ theme }) => theme.fonts.set(`primary`, `medium`)}

  grid-column: 1;
  grid-row: 3;
  margin-top: ${em(4)};
  font-size: ${em(12)};
  letter-spacing: ${em(-0.25)};
`

const Share = styled.div`
  grid-column: 2;
  grid-row: 2 / span 3;

  button {
    width: 100%;
    display: block;
    text-align: center;
    color: ${({ theme }) => theme.colors.darkSlateGray};

    &:hover {
      color: ${({ theme }) => theme.colors.seaGreen};
    }
  }

  svg {
    width: ${em(20)};
    height: ${em(20)};
    margin: 0 auto;
    display: block;
  }

  span {
    font-size: ${em(11)};
    letter-spacing: ${em(-0.25)};
  }
`
