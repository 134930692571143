import React, { useState, useEffect, useRef } from "react"
import { reaction } from "mobx"
import { observer } from "mobx-react-lite"
import styled, { css } from "styled-components"
import { rem, em, hideVisually } from "polished"
import { orderBy } from "lodash"

import SiteCard from "./SiteCard"
import Miniguide from "./Miniguide/Index"
import { ReactComponent as SvgCheck } from "../assets/images/icons/check.svg"
import { ReactComponent as SvgChevronDown } from "../assets/images/icons/chevron-down.svg"
import { ReactComponent as SvgDocumentSearch } from "../assets/images/icons/document-search.svg"
import { useStore } from "../store"
import useReferredState from "../utils/useReferredState"
import { scrollToTargetInParent } from "../utils/scroll"
import { getMapPointsInViewportIds } from "../utils/map"
import { __ } from "../utils/translate"
import embedParams from "../config/embedParams"

const PER_PAGE = 20
const SORT_LABELS = {
  ns: __(`North-South`),
  sn: __(`South-North`),
  we: __(`West-East`),
  ew: __(`East-West`),
  az: __(`A-Z`),
  za: __(`Z-A`),
  popular: __(`Most popular`),
  position: __(`Position`),
}
const SORT_METHODS = [`ns`, `sn`, `we`, `ew`, `az`, `za`, `popular`]
const SORT_METHODS_MINIGUIDE = [...SORT_METHODS, `position`]

const SitesList = () => {
  const store = useStore()
  const isMiniguide = [`trip`, `list`].includes(embedParams.strict)
  const sortMethods = isMiniguide ? SORT_METHODS_MINIGUIDE : SORT_METHODS
  const sortMethodDefault = sortMethods.includes(embedParams.menuSort)
    ? embedParams.menuSort
    : sortMethods[0]

  const [sites, setSites] = useState([])
  const [inited, setInited] = useState(false)
  const [pagination, setPagination] = useState([0, PER_PAGE])
  const [displaySort, setDisplaySort] = useState(false)
  const [sortMethod, sortMethodRef, setSortMethod] =
    useReferredState(sortMethodDefault)
  const [pointActiveId, setPointActiveId] = useState(0)
  const mapMovesTrackedRef = useRef(false)
  const activeSiteRef = useRef(null)
  const scrollToActiveSiteRef = useRef(false)
  const topSiteRef = useRef(null)
  const scrollToTopSiteRef = useRef(false)
  const containerRef = useRef(null)
  const sortRef = useRef(null)
  const processTimeoutRef = useRef(null)
  const pointsVersionProcessedRef = useRef(``)

  const docClick = (e) => {
    if (sortRef.current && !sortRef.current.contains(e.target))
      setDisplaySort(false)
  }

  const sortToggleClick = () => {
    setDisplaySort((v) => !v)
  }

  const sortOptionClick = (e) => {
    window.setTimeout(() => setDisplaySort(false), 200)
    setSortMethod(e.currentTarget.dataset.method)
    scrollToTargetInParent(containerRef.current, sortRef.current, `top`)
  }

  const outerScroll = () => {
    // scrolled to top
    if (containerRef.current.scrollTop <= 0 && pagination[0] > 0) {
      scrollToTopSiteRef.current = true
      setPagination([Math.max(pagination[0] - PER_PAGE, 0), pagination[1]])
    }
    // scrolled to bottom
    else if (
      containerRef.current.offsetHeight + containerRef.current.scrollTop >=
        containerRef.current.scrollHeight - 2 &&
      pagination[1] < sites.length - 1
    ) {
      setPagination([
        pagination[0],
        Math.min(pagination[1] + PER_PAGE, sites.length),
      ])
    }
  }

  const sortSites = (features) => {
    switch (sortMethodRef.current) {
      case `ns`:
      case `sn`: {
        features = orderBy(
          features,
          (f) =>
            typeof f.geometry.coordinates[1] === `number`
              ? f.geometry.coordinates[1]
              : 0,
          [sortMethodRef.current == `sn` ? `asc` : `desc`]
        )
        break
      }

      case `we`:
      case `ew`: {
        features = orderBy(
          features,
          (f) =>
            typeof f.geometry.coordinates[0] === `number`
              ? f.geometry.coordinates[0]
              : 0,
          [sortMethodRef.current == `we` ? `asc` : `desc`]
        )
        break
      }

      case `az`:
      case `za`: {
        // features = orderBy(features, (f) => f.properties.title.toLowerCase(), [
        //   sortMethodRef.current == `az` ? `asc` : `desc`,
        // ])
        features.sort((a, b) => {
          const diff = a.properties.title.localeCompare(
            b.properties.title,
            embedParams.language
          )
          return diff === 0
            ? 0
            : diff * (sortMethodRef.current == `az` ? 1 : -1)
        })
        break
      }

      case `popular`: {
        features = orderBy(features, (f) => f.properties.popularity, [`desc`])
        break
      }

      case `position`: {
        const miniguide = (
          embedParams.strict == `trip` ? store.trips : store.lists
        )[0]
        if (miniguide) {
          features = orderBy(
            features,
            (f) => {
              const item = miniguide.items.find((i) => i.siteId == f.id)
              return item ? item.position : 0
            },
            [`asc`]
          )
        }
        break
      }
    }

    return features
  }

  const processSites = () => {
    const set = (points) => {
      setSites(sortSites(points))
      setInited(true)

      if (!mapMovesTrackedRef.current) {
        mapMovesTrackedRef.current = true
        store.map.on(`moveend`, processSites)
      }
    }

    if (store.points.length > 30) {
      window.clearTimeout(processTimeoutRef.current)
      processTimeoutRef.current = window.setTimeout(() => {
        getMapPointsInViewportIds(store).then((ids) =>
          set(store.points.filter((p) => ids.includes(p.id)))
        )
      }, 1000)
    } else if (pointsVersionProcessedRef.current != store.pointsVersion) {
      pointsVersionProcessedRef.current = store.pointsVersion
      set(store.points)
    }
  }

  useEffect(() => {
    setPointActiveId(0)
    setPagination([0, PER_PAGE])
    setSites(sortSites(sites))
  }, [sortMethod])

  useEffect(() => {
    setPointActiveId(store.pointActiveId)
  }, [store.pointActiveId])

  useEffect(() => {
    if (!pointActiveId) return
    const index = sites.findIndex((s) => s.id == pointActiveId)
    scrollToActiveSiteRef.current = true
    setPagination([
      Math.max(index - PER_PAGE, 0),
      Math.min(index + PER_PAGE, sites.length),
    ])
  }, [pointActiveId, sites])

  useEffect(() => {
    if (!containerRef.current) return
    if (scrollToActiveSiteRef.current && activeSiteRef.current) {
      scrollToTargetInParent(
        containerRef.current,
        activeSiteRef.current,
        `center`
      )
      scrollToActiveSiteRef.current = false
    } else if (scrollToTopSiteRef.current && topSiteRef.current) {
      scrollToTargetInParent(containerRef.current, topSiteRef.current, `top`)
      scrollToTopSiteRef.current = false
    }
  }, [pagination])

  useEffect(() => {
    if (store.initiallyLoaded) processSites()

    const reactionInitiallyLoaded = reaction(
      () => store.initiallyLoaded,
      () => {
        processSites()
      }
    )

    const reactionPoints = reaction(
      () => store.pointsVersion,
      () => {
        setPointActiveId(0)
        setPagination([0, PER_PAGE])
        if (store.initiallyLoaded) processSites()
      }
    )

    document.addEventListener(`click`, docClick, { passive: true })

    return () => {
      reactionInitiallyLoaded()
      reactionPoints()
      document.removeEventListener(`click`, docClick, { passive: true })
      window.clearTimeout(processTimeoutRef.current)
      if (mapMovesTrackedRef.current) store.map.off(`moveend`, processSites)
    }
  }, [])

  return (
    <Container ref={containerRef} onScroll={outerScroll}>
      {isMiniguide && embedParams.showMiniguide && (
        <MiniguideWrap>
          <Miniguide />
        </MiniguideWrap>
      )}

      {inited && !!sites.length && (
        <>
          <Sort ref={sortRef}>
            <SortToggle onClick={sortToggleClick} $active={displaySort}>
              <span>{__(`Sort by`)}</span>

              <b>{SORT_LABELS[sortMethod]}</b>

              <SvgChevronDown aria-hidden="true" />
            </SortToggle>

            {displaySort && (
              <SortOptions>
                {sortMethods.map((method) => (
                  <li key={method}>
                    <SortOption
                      onClick={sortOptionClick}
                      $active={sortMethod == method}
                      data-method={method}
                    >
                      {SORT_LABELS[method]}

                      {sortMethod == method && <SvgCheck aria-hidden="true" />}
                    </SortOption>
                  </li>
                ))}
              </SortOptions>
            )}
          </Sort>

          <Sites>
            {sites.slice(pagination[0], pagination[1]).map((site, i) => {
              let ref = undefined
              if (site.id == pointActiveId) ref = activeSiteRef
              else if (i == PER_PAGE) ref = topSiteRef
              return (
                <div key={site.id} ref={ref}>
                  <SiteCard
                    data={site}
                    highlightActive={true}
                    showInMiniguideDescription={true}
                    pointActivationTrigger="sites_list"
                  />
                </div>
              )
            })}
          </Sites>
        </>
      )}

      {!inited && !sites.length && (
        <Loader>
          <p>{__(`Loading`)}</p>

          {Array.from({ length: 10 }, (n, i) => (
            <div key={i} />
          ))}
        </Loader>
      )}

      {inited && !sites.length && (
        <Empty>
          <SvgDocumentSearch aria-hidden="true" />

          <p>{__(`Could not find anything`)}</p>
        </Empty>
      )}
    </Container>
  )
}

export default observer(SitesList)

const Container = styled.div`
  height: 100%;
  margin-right: ${rem(2)};
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media ${({ theme }) => theme.mq.mobileDown} {
    padding-bottom: ${rem(80)}; // extra space for ViewToggle
  }

  &::-webkit-scrollbar {
    width: ${rem(5)};
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gunmetal};
    border-radius: ${({ theme }) => theme.br.small};
  }
`

const MiniguideWrap = styled.div`
  margin-bottom: ${em(20)};
  padding: 0 ${rem(12)} 0 ${rem(24)};

  &:empty {
    display: none;
  }
`

const Sort = styled.div`
  width: 100%;
  padding: 0 ${rem(12)} 0 ${rem(24)};
  position: sticky;
  z-index: 10;
  top: 0;
  background-color: ${({ theme }) => theme.colors.white};

  @media ${({ theme }) => theme.mq.mobileDown} {
    padding-left: ${em(10)};
  }
`

const SortToggle = styled.button.attrs({ type: `button` })`
  width: 100%;
  padding: ${em(6)} 0;
  display: flex;
  align-items: center;
  gap: ${em(10)};
  border-bottom: 1px solid;

  &:hover {
    color: ${({ theme }) => theme.colors.cadet};
  }

  span {
    ${({ theme }) => theme.fonts.set(`primary`, `medium`)}

    font-size: ${em(10)};
    letter-spacing: ${em(-0.25)};
    text-transform: uppercase;
  }

  b {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

    font-size: ${em(13)};
    letter-spacing: ${em(-0.25)};
  }

  svg {
    width: ${em(12)};
    height: ${em(10)};
    margin-left: auto;
    transition: transform 0.3s ${({ theme }) => theme.easings.default};

    ${({ $active }) =>
      $active &&
      css`
        transform: rotate(180deg);
      `}
  }
`

const SortOptions = styled.ul`
  width: 100%;
  padding: ${em(14)} ${rem(20)};
  position: absolute;
  top: 100%;
  left: 0;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 ${rem(4)} ${rem(4)} rgba(0, 0, 0, 0.25);
  animation: ${({ theme }) => theme.animations.slideInY(-10)} 0.3s
    ${({ theme }) => theme.easings.outBack};
`

const SortOption = styled.button.attrs({ type: `button` })`
  ${({ theme }) => theme.fonts.set(`primary`, `medium`)}

  width: 100%;
  padding: ${em(6)} ${em(8)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${em(10)};
  font-size: ${em(13)};
  border-radius: ${({ theme }) => theme.br.small};

  ${({ $active }) =>
    $active &&
    css`
      background-color: ${({ theme }) => theme.colors.lightGray};
    `}

  &:hover {
    color: ${({ theme }) => theme.colors.cadet};
  }

  svg {
    width: ${em(14)};
    height: ${em(14)};
    flex-shrink: 0;
  }
`

const Sites = styled.div`
  padding: 0 ${rem(12)} ${em(20)} ${rem(24)};

  @media ${({ theme }) => theme.mq.mobileDown} {
    padding-left: ${em(10)};
  }

  > * {
    margin-top: ${em(10)};
  }
`

const Loader = styled.div`
  padding: 0 ${rem(14)} ${rem(24)} ${rem(24)};

  @media ${({ theme }) => theme.mq.mobileDown} {
    padding-left: ${em(10)};
  }

  p {
    ${hideVisually()}
  }

  div {
    width: 100%;
    height: ${em(120)};
    background-color: ${({ theme }) => theme.colors.lightGray};
    border-radius: ${({ theme }) => theme.br.small};
    animation: ${({ theme }) => theme.animations.fadeBlink} 1s infinite linear;

    & + div {
      margin-top: ${em(10)};
    }
  }
`

const Empty = styled.div`
  ${({ theme }) => theme.fonts.set(`primary`, `medium`)}

  padding: ${rem(24)};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${em(20)};
  text-align: center;
  color: ${({ theme }) => theme.colors.cadet};

  p {
    font-size: ${em(14)};
  }

  svg {
    width: ${em(36)};
    height: ${em(36)};
  }
`
