// TODO: upgrade with useIntersectionObserver (https://usehooks.com/useintersectionobserver)

import React, { useEffect, useRef, useState } from "react"
import { observer } from "mobx-react-lite"
import styled, { css } from "styled-components"
import { em, rem } from "polished"

import Button from "../Button"
import { ReactComponent as SvgClose } from "../../assets/images/icons/close.svg"
import { useStore } from "../../store"
import { __ } from "../../utils/translate"

const Header = ({ data }) => {
  const store = useStore()
  const closeRef = useRef(null)
  const containerRef = useRef(null)
  const [containerStuck, setContainerStuck] = useState(false)

  const closeClick = (e) => {
    if (!e.screenX && !e.screenY) {
      const id = store.pointActiveId
      // setFocusTrapActive(false)
      window.setTimeout(
        () =>
          store.setKeyboardAction({
            name: `siteCardClosed`,
            id: id,
          }),
        100
      )
    }

    store.setDisplaySite(false)
  }

  useEffect(() => {
    if (closeRef.current) closeRef.current.focus()

    let containerObserver = null

    if (containerRef.current) {
      containerObserver = new IntersectionObserver(
        ([e]) => {
          setContainerStuck(
            e.intersectionRatio < 1 && e.boundingClientRect.top <= 5
          )
        },
        { threshold: [1], root: containerRef.current.parentNode }
      )

      containerObserver.observe(containerRef.current)
    }

    return () => {
      containerObserver?.disconnect()
    }
  }, [])

  return (
    <Container ref={containerRef} $stuck={containerStuck}>
      <Close aria-hidden={!containerStuck && !!data.images.length}>
        <Button
          onClick={closeClick}
          innerRef={closeRef}
          disabled={!containerStuck && !!data.images.length}
          type="button"
          title={__(`Close`)}
          color="gunmetal"
          fill="aliceBlue"
          size="xsmall"
          boxShadow={true}
          icon={<SvgClose />}
        />
      </Close>

      <Title>{data.title}</Title>
    </Container>
  )
}

export default observer(Header)

const Close = styled.div`
  margin-top: ${em(-3)};
  flex-shrink: 0;
  order: 3;

  &[aria-hidden="true"] {
    pointer-events: none;
    opacity: 0;
  }
`

const Title = styled.h2`
  ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

  flex-grow: 1;
  font-size: ${em(20)};
  line-height: 1.3;
`

const Container = styled.header`
  position: sticky;
  z-index: 999999;
  top: -1px;
  padding: ${em(16)} ${rem(20)} ${em(10)};
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid transparent;
  display: flex;
  gap: ${rem(16)};

  @media ${({ theme }) => theme.mq.mobileDown} {
    font-size: ${em(12)};
  }

  ${({ $stuck }) =>
    $stuck &&
    css`
      border-bottom-color: ${({ theme }) => theme.colors.lightGray};

      /* ${Title} {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      } */
    `}

  @media ${({ theme }) => theme.mq.xxsmallDown} {
    padding-top: ${rem(14)};
    padding-left: ${rem(10)};
    padding-right: ${rem(10)};
  }
`
