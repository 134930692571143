import React, { useState, useRef } from "react"
import { observer } from "mobx-react-lite"
import styled, { css, keyframes } from "styled-components"
import { rem } from "polished"

import { ReactComponent as SvgTarget } from "../assets/images/icons/target.svg"
import { useStore } from "../store"
import embedParams from "../config/embedParams"
import useWatchGeoPosition from "../utils/useWatchGeoPosition"
import { __ } from "../utils/translate"

const LocationControl = () => {
  const store = useStore()
  const [loading, setLoading] = useState(false)
  const mapTravelled = useRef(false)

  const [watchGeoPosition, unwatchGeoPosition] = useWatchGeoPosition({
    onWatch: (pos) => {
      setLoading(false)
      const location = [pos.coords.longitude, pos.coords.latitude]
      store.setLocation(location)

      if (!mapTravelled.current) {
        mapTravelled.current = true
        store.travelMap({
          flyTo: {
            center: location,
            zoom: 10,
          },
        })
      }
    },
    onError: (err) => {
      setLoading(false)
      alert(
        `${__(`There was an error detecting your location`)} (${err.message}, ${err.code})`
      )
    },
  })

  const active = !!store.location.length

  const buttonClick = () => {
    if (active) {
      store.setLocation(false)
      unwatchGeoPosition()
    } else {
      setLoading(true)
      watchGeoPosition()
    }
  }

  const label = active ? __(`Hide my location`) : __(`Show my location`)

  if (
    !(`geolocation` in navigator) ||
    embedParams.locationControl === false ||
    (embedParams.locationControl == `fullscreen` && !store.fullscreen)
  )
    return null

  return (
    <Container $active={active} $isLoading={loading}>
      <button type="button" title={label} onClick={buttonClick}>
        <SvgTarget aria-label={label} />
      </button>
    </Container>
  )
}

export default observer(LocationControl)

const loadingAnimation = keyframes`
  0% { opacity: 0.6; }
  50% { opacity: 0.3; }
  100% { opacity: 0.6; }
`

const Container = styled.div`
  ${({ $isLoading }) =>
    $isLoading &&
    css`
      animation: ${loadingAnimation} 0.8s linear infinite;
    `}

  button {
    width: ${rem(42)};
    height: ${rem(42)};
    padding: ${rem(10)};
    display: block;
    position: relative;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.gunmetal};
    box-shadow: ${({ theme }) => theme.bs.button};
    border-radius: ${({ theme }) => theme.br.normal};

    ${({ $active }) =>
      $active &&
      css`
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.seaGreen};
        box-shadow: ${({ theme }) => theme.bs.buttonPressed};
      `}

    &:hover {
      color: ${({ theme }) => theme.colors.lightGray};
    }

    &::before {
      /* expanded click area */
      content: "";
      position: absolute;
      top: ${rem(-5)};
      bottom: ${rem(-5)};
      left: ${rem(-10)};
      right: ${rem(-10)};
    }
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`
