import React from "react"
import styled from "styled-components"
import { em, rem } from "polished"

import ExpandableText from "../ExpandableText"
import embedParams from "../../config/embedParams"
import { __ } from "../../utils/translate"

const FIELDS = {
  directions: __(`Directions`),
  facts: __(`Facts`),
  regulations: __(`Regulations`),
}

const FIELD_KEYS = Object.keys(FIELDS)

const Texts = ({ data }) => {
  let anyContent = false

  FIELD_KEYS.some((field) => {
    if (data[field]) {
      anyContent = true
      return true
    }
    return false
  })

  if (!anyContent || embedParams.client == `stf`) return null

  return (
    <Container>
      {FIELD_KEYS.map(
        (field) =>
          data[field] && (
            <Group key={field}>
              <Title>{FIELDS[field]}</Title>

              <ExpandableText text={data[field]} />
            </Group>
          )
      )}
    </Container>
  )
}

export default Texts

const Container = styled.div`
  padding: 0 ${rem(20)};

  @media ${({ theme }) => theme.mq.xxsmallDown} {
    padding-left: ${rem(10)};
    padding-right: ${rem(10)};
  }
`

const Group = styled.section`
  margin: ${em(10)} 0;
  padding: ${em(10)} 0;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
`

const Title = styled.h3`
  ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

  margin-bottom: ${em(10)};
  font-size: ${em(15)};
  letter-spacing: ${em(-0.025)};
`
