import React, { useState, useMemo, useEffect } from "react"
import ReactDOM from "react-dom"
import { observer } from "mobx-react-lite"
import { usePopper } from "react-popper"
import styled, { css } from "styled-components"
import { em } from "polished"

import UnpublishedLabel from "./UnpublishedLabel"
import { useStore } from "../store"

const SiteTooltip = () => {
  const store = useStore()

  const point = useMemo(() => store.pointHover, [store.pointHover])

  return point ? <Tooltip point={point} /> : null
}

export default observer(SiteTooltip)

const Tooltip = ({ point }) => {
  const [containerEl, setContainerEl] = useState(null)
  const [mousePos, setMousePos] = useState([0, 0])

  const referenceEl = useMemo(
    () => ({
      getBoundingClientRect: () => {
        return {
          top: mousePos[1] || 0,
          left: mousePos[0] || 0,
          bottom: mousePos[0],
          right: mousePos[1],
          width: 0,
          height: 0,
        }
      },
    }),
    [mousePos]
  )

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceEl,
    containerEl,
    {
      placement: `top-start`,
      modifiers: [
        {
          name: `flip`,
          enabled: false,
        },
        {
          name: `offset`,
          options: {
            offset: [6, 6],
          },
        },
      ],
    }
  )

  const docMouseMove = (e) => {
    setMousePos([e.clientX, e.clientY])
  }

  useEffect(() => {
    document.addEventListener(`mousemove`, docMouseMove)

    return () => {
      document.removeEventListener(`mousemove`, docMouseMove)
    }
  }, [])

  return ReactDOM.createPortal(
    <Container
      ref={setContainerEl}
      style={popperStyles.popper}
      {...popperAttributes.popper}
    >
      {!point.properties.published && <UnpublishedLabel prepended={true} />}

      <Text $unpublished={!point.properties.published}>
        {point.properties.title}
      </Text>
    </Container>,
    document.querySelector(`body`)
  )
}

const Container = styled.div`
  z-index: ${({ theme }) => theme.zindex.siteTooltip};
  max-width: ${em(320)};
`

const Text = styled.div`
  ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

  padding: ${em(12)} ${em(22)};
  font-size: ${em(14)};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.gunmetal};
  border-radius: ${({ theme }) => theme.br.normal};
  box-shadow: ${({ theme }) => theme.bs.button};

  ${({ $unpublished }) =>
    $unpublished &&
    css`
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `}
`
