import React from "react"
import { observer } from "mobx-react-lite"
import styled, { css } from "styled-components"
import { rem } from "polished"

import { ReactComponent as SvgPrint } from "../assets/images/icons/print.svg"
import embedParams from "../config/embedParams"
import { __ } from "../utils/translate"
import { useStore } from "../store"

const PrintControl = () => {
  const store = useStore()

  const buttonClick = () => {
    store.setPrint(!store.print)
  }

  if (
    embedParams.printControl === false ||
    (embedParams.printControl == `fullscreen` && !store.fullscreen)
  )
    return null

  return (
    <Container
      $active={store.print}
      $stolavsleden={embedParams.client == `stolavsleden`}
    >
      <button type="button" title={__(`Print`)} onClick={buttonClick}>
        <SvgPrint aria-label={__(`Print`)} />
      </button>
    </Container>
  )
}

export default observer(PrintControl)

const Container = styled.div`
  button {
    width: ${rem(42)};
    height: ${rem(42)};
    padding: ${rem(12)};
    display: block;
    position: relative;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: ${({ theme }) => theme.bs.button};
    border-radius: ${({ theme }) => theme.br.normal};

    ${({ $active }) =>
      $active &&
      css`
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.seaGreen};
        box-shadow: ${({ theme }) => theme.bs.buttonPressed};
      `}

    &:hover {
      color: ${({ theme }) => theme.colors.cadet};

      ${({ $active }) =>
        $active &&
        css`
          color: ${({ theme }) => theme.colors.aliceBlue};
        `}
    }

    &::before {
      /* expanded click area */
      content: "";
      position: absolute;
      top: ${rem(-5)};
      bottom: ${rem(-5)};
      left: ${rem(-10)};
      right: ${rem(-10)};
    }

    ${({ $stolavsleden }) =>
      $stolavsleden &&
      css`
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.stolavsledenRed};

        &:hover {
          color: ${({ theme }) => theme.colors.white};
          background-color: ${({ theme }) => theme.colors.stolavsledenRedDark};
        }
      `}
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`
