const clientStfTripCategories = [
  `55`, // hostel
  `56`, // camping
  `62`, // firesite
  `64`, // entrance
  `61`, // shelter
  `65`, // toilet
  `68`, // resting-cabin
  // `33`, // hiking
]

export { clientStfTripCategories }
