import React from "react"
import { Layer } from "react-mapbox-gl"

import colors from "../theme/colors"

const lineOpacity = {
  "line-opacity": [
    `case`,
    [`boolean`, [`feature-state`, `invisible`], false],
    0,
    1,
  ],
  // "line-opacity": 1,
}

const layerConfig = {
  sourceId: `geojson-shapes`,
  type: `line`,
  filter: [
    `match`,
    [`geometry-type`],
    [`Polygon`, `MultiPolygon`],
    true,
    false,
  ],
  layout: {
    "line-join": `round`,
    "line-cap": `round`,
  },
}

const MapAreas = () => (
  <>
    {/* area */}

    <Layer
      {...layerConfig}
      paint={{
        "line-width": 8,
        "line-color": [
          `case`,
          [
            `any`,
            [`boolean`, [`feature-state`, `active`], false],
            [`boolean`, [`feature-state`, `hover`], false],
          ],
          `rgba(0, 0, 0, 0)`,
          colors.white,
        ],
        ...lineOpacity,
      }}
    />

    <Layer
      {...layerConfig}
      paint={{
        "line-width": 3,
        "line-dasharray": [1, 0.2],
        "line-color": [
          `case`,
          [
            `any`,
            [`boolean`, [`feature-state`, `active`], false],
            [`boolean`, [`feature-state`, `hover`], false],
          ],
          `rgba(0, 0, 0, 0)`,
          [
            `case`,
            [`!=`, [`get`, `color`], null],
            [`get`, `color`],
            colors.seaGreen,
          ],
        ],
        ...lineOpacity,
      }}
      id="areas"
    />

    {/* area: active */}

    <Layer
      {...layerConfig}
      paint={{
        "line-width": 14,
        "line-color": [
          `case`,
          [`boolean`, [`feature-state`, `active`], false],
          colors.orange,
          `rgba(0, 0, 0, 0)`,
        ],
        ...lineOpacity,
      }}
    />

    <Layer
      {...layerConfig}
      paint={{
        "line-width": 8,
        "line-color": [
          `case`,
          [`boolean`, [`feature-state`, `active`], false],
          colors.white,
          `rgba(0, 0, 0, 0)`,
        ],
        ...lineOpacity,
      }}
    />

    <Layer
      {...layerConfig}
      paint={{
        "line-width": 3,
        "line-dasharray": [1, 0.2],
        "line-color": [
          `case`,
          [`boolean`, [`feature-state`, `active`], false],
          [
            `case`,
            [`!=`, [`get`, `color`], null],
            [`get`, `color`],
            colors.seaGreen,
          ],
          `rgba(0, 0, 0, 0)`,
        ],
        ...lineOpacity,
      }}
    />

    {/* area: hover */}

    <Layer
      {...layerConfig}
      paint={{
        "line-width": 8,
        "line-color": [
          `case`,
          [`boolean`, [`feature-state`, `hover`], false],
          colors.black,
          `rgba(0, 0, 0, 0)`,
        ],
        ...lineOpacity,
      }}
    />

    <Layer
      {...layerConfig}
      paint={{
        "line-width": 3,
        "line-dasharray": [1, 0.2],
        "line-color": [
          `case`,
          [`boolean`, [`feature-state`, `hover`], false],
          [
            `case`,
            [`!=`, [`get`, `color`], null],
            [`get`, `color`],
            colors.seaGreen,
          ],
          `rgba(0, 0, 0, 0)`,
        ],
        ...lineOpacity,
      }}
    />
  </>
)

export default MapAreas
