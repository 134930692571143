import React from "react"
import { makeObservable, observable, action, computed } from "mobx"
import { nanoid } from "nanoid"
import { featureCollection as turfFeatureCollection } from "@turf/helpers"

import embedParams from "./config/embedParams"
import { elevationsExcludes } from "./config/elevations"
import { parseEmbedQuery, queryItemToTitle } from "./utils/query"
import { fetchApiOrganization } from "./utils/api" // TODO: move this outside the store
import mq from "./theme/mq"

const { query } = parseEmbedQuery(embedParams.query)

let menu = embedParams.menu
if (menu === true && window.matchMedia(mq.mobileDown).matches) menu = false // do not display menu initially on mobile
if (menu == `fullscreen`) menu = false

class Store {
  initiallyLoaded = false // map and initial site load completed
  map = null // {}
  mapStyle = embedParams.style // string
  mapTraveling = false
  mapFeatureHover = false
  search = ``
  query = query
  legend = embedParams.legend || false
  queryFailed = true
  points = []
  pointsVersion = ``
  pointsHide = false
  shapes = []
  categories = []
  guides = []
  organizations = []
  organizationsFetchPromises = {}
  counties = []
  municipalities = []
  users = []
  trips = []
  lists = []
  customSiteLinks = null // {}
  traffic = `` // form|trips|trips:loading|trip
  trafficStart = null // {}
  trafficFinish = null // {}
  trafficTrips = []
  trafficTripActiveId = 0
  trafficChoices = []
  trafficChoicesHide = false
  trafficMobileMap = false
  fullscreen = false
  pointHoverId = 0
  pointActiveId = 0
  pointActiveTrigger = ``
  pointPreselectedId = 0
  menu = menu // bool
  elevations = false
  elevationsViaSite = false
  elevationsMark = []
  measure = false
  measureValue = 0
  location = []
  displaySite = false
  print = false
  share = false
  keyboardAction = null // {}

  constructor() {
    makeObservable(this, {
      search: observable,
      query: observable,
      queryFailed: observable,
      points: observable,
      pointsVersion: observable,
      pointsHide: observable,
      shapes: observable,
      categories: observable,
      guides: observable,
      organizations: observable,
      municipalities: observable,
      counties: observable,
      users: observable,
      trips: observable,
      lists: observable,
      customSiteLinks: observable,
      traffic: observable,
      trafficStart: observable,
      trafficFinish: observable,
      trafficTrips: observable,
      trafficTripActiveId: observable,
      trafficChoices: observable,
      trafficChoicesHide: observable,
      trafficMobileMap: observable,
      initiallyLoaded: observable,
      map: observable,
      mapStyle: observable,
      mapFeatureHover: observable,
      fullscreen: observable,
      menu: observable,
      pointHoverId: observable,
      pointActiveId: observable,
      pointPreselectedId: observable,
      elevations: observable,
      elevationsMark: observable,
      measure: observable,
      measureValue: observable,
      location: observable,
      displaySite: observable,
      print: observable,
      share: observable,
      legend: observable,
      keyboardAction: observable,

      setSearch: action,
      setQuery: action,
      setQueryFailed: action,
      setFullscreen: action,
      setInitiallyLoaded: action,
      setMap: action,
      setMapFeatureHover: action,
      travelMap: action,
      setPoints: action,
      setPointsHide: action,
      clearPoints: action,
      setShapes: action,
      clearShapes: action,
      addShapes: action,
      removeShapes: action,
      setCategories: action,
      setGuides: action,
      setOrganizations: action,
      setCounties: action,
      setMunicipalities: action,
      setUsers: action,
      setTrips: action,
      setLists: action,
      setCustomSiteLinks: action,
      setTraffic: action,
      setTrafficStart: action,
      setTrafficFinish: action,
      setTrafficTrips: action,
      setTrafficTripActiveId: action,
      setTrafficChoices: action,
      setTrafficChoicesHide: action,
      setTrafficMobileMap: action,
      setMenu: action,
      setPointHoverId: action,
      setPointActiveId: action,
      setPointPreselectedId: action,
      setElevations: action,
      setElevationsMark: action,
      setMeasure: action,
      setMeasureValue: action,
      setLocation: action,
      setMapStyle: action,
      setDisplaySite: action,
      setPrint: action,
      setShare: action,
      setLegend: action,
      setKeyboardAction: action,

      pointsForMap: computed,
      shapesForMap: computed,
      pointActive: computed,
      pointHover: computed,
      pointPreselected: computed,
      shapeActive: computed,
      shapeHover: computed,
      shapePreselected: computed,
      anyTrailSurfaceInGeojson: computed,
      trafficTripActive: computed,
    })
  }

  setInitiallyLoaded = () => {
    this.initiallyLoaded = true
  }

  setMap = (map) => {
    this.map = map
  }

  setMapInteractive = (value) => {
    if (!this.map) return
    ;[
      `scrollZoom`,
      `boxZoom`,
      `dragRotate`,
      `dragPan`,
      `keyboard`,
      `doubleClickZoom`,
      `touchZoomRotate`,
    ].forEach((method) => this.map[method][value ? `enable` : `disable`]())
  }

  setMapStyle = (name) => {
    this.mapStyle = name
  }

  setMapFeatureHover = (value) => {
    this.mapFeatureHover = value
  }

  travelMap = ({
    instant = false,
    flyTo = null,
    fitBounds = null,
    fitBoundsParams = {},
  }) => {
    return new Promise((resolve, reject) => {
      if (!this.map || (!flyTo && !fitBounds)) return

      if (!instant) {
        this.mapTraveling = true
        this.map.once(`moveend`, () => {
          this.mapTraveling = false
          resolve()
        })
      }

      if (flyTo) {
        this.map.flyTo({
          ...flyTo,
          speed: instant ? 0 : 2,
        })
      } else {
        let padding = {}

        if (window.matchMedia(mq.mobileUp).matches) {
          // desktop

          padding = {
            top: 110,
            right: 60,
            bottom: 20,
            left: 20,
          }

          if (this.menu)
            padding = {
              ...padding,
              left: 240,
              right: 280,
            }
        } else {
          // mobile

          padding = {
            top: 30,
            right: 60,
            bottom: 20,
            left: 20,
          }

          if (this.fullscreen) {
            padding = {
              ...padding,
              top: 80,
              bottom: 80,
            }
          }
        }

        this.map.fitBounds(fitBounds, {
          ...fitBoundsParams,
          duration: instant ? 0 : 1000,
          padding,
        })
      }

      if (instant) resolve()
    })
  }

  setSearch = (search) => {
    this.search = search
  }

  setQuery = (query) => {
    this.query = query
  }

  addToQuery = (data) => {
    // adds or replace if exists
    const index = this.findIndexInQuery(data)
    if (index >= 0) {
      const query = [...this.query]
      query[index] = data
      this.setQuery(query)
    } else this.setQuery([...this.query, data])
  }

  removeFromQuery = (data) => {
    this.setQuery(
      [...this.query].filter(
        (i) =>
          !(
            i.type == data.type &&
            (data.value !== undefined ? data.value == i.value : true)
          )
      )
    )
  }

  findInQuery = (data) => {
    const index = this.findIndexInQuery(data)
    return index >= 0 ? this.query[index] : null
  }

  findIndexInQuery = (data) => {
    if ([`importance`].includes(data.type))
      return this.query.findIndex((i) => i.type == data.type)

    return this.query.findIndex(
      (i) => i.value == data.value && i.type == data.type
    )
  }

  setQueryFailed = (value) => {
    this.queryFailed = value
  }

  setPoints = (features) => {
    this.points.replace(features)
    this.pointsVersion = nanoid()
  }

  setPointsHide = (value) => {
    this.pointsHide = value
  }

  clearPoints = () => {
    this.points.clear()
  }

  findPoint = (id) => {
    return this.points.find((p) => p.id == id)
  }

  setShapes = (features) => {
    this.shapes.replace(features)
  }

  clearShapes = () => {
    this.shapes.clear()
  }

  addShapes = (features) => {
    features = Array.isArray(features) ? features : [features]
    this.shapes.push(...features)
  }

  removeShapes = (ids) => {
    this.shapes = this.shapes.filter((s) => !ids.includes(s.id))
  }

  findShapeByParent = (id) => {
    return this.shapes.find((s) => s.properties.parent == id)
  }

  setCategories = (data) => {
    this.categories = data
  }

  findCategory = (id) => {
    return this.categories.find((c) => c.id == id)
  }

  setGuides = (data) => {
    this.guides = data
  }

  findGuide = (id) => {
    return this.guides.find((g) => g.id == id)
  }

  setOrganizations = (oganizations) => {
    this.organizations.replace(oganizations)
  }

  addToOrganizations = (oganization) => {
    if (!this.findOrganization(oganization.id))
      this.setOrganizations([...this.organizations, oganization])
  }

  findOrganization = (id) => {
    return this.organizations.find((o) => o.id == id)
  }

  getOrganization = (id) => {
    return new Promise((resolve, reject) => {
      const organization = this.findOrganization(id)
      if (organization) return resolve(organization)

      const storedPromise = this.organizationsFetchPromises[id]
      if (storedPromise) return resolve(storedPromise)

      const fetchPromise = fetchApiOrganization({ id }).then(
        (fetchedOrganization) => {
          if (fetchedOrganization) this.addToOrganizations(fetchedOrganization)
          delete this.organizationsFetchPromises[id]
          return fetchedOrganization
        }
      )

      this.organizationsFetchPromises[id] = fetchPromise

      return resolve(fetchPromise)
    })
  }

  setCounties = (data) => {
    this.counties = data
  }

  setMunicipalities = (data) => {
    this.municipalities = data
  }

  findMunicipality = (id) => {
    return this.municipalities.find((m) => m.id == id)
  }

  setUsers = (users) => {
    this.users.replace(users)
  }

  addToUsers = (data) => {
    const items = (Array.isArray(data) ? data : [data]).filter(
      (i) => !this.findUser(i.id)
    )

    if (items.length) this.setUsers([...this.users, ...items])
  }

  findUser = (id) => {
    return this.users.find((u) => u.id == id)
  }

  setTrips = (trips) => {
    this.trips.replace(trips)
  }

  addToTrips = (data) => {
    const trips = (Array.isArray(data) ? data : [data]).filter(
      (i) => !this.findTrip(i.id)
    )

    if (trips.length) this.setTrips([...this.trips, ...trips])
  }

  findTrip = (id) => {
    return this.trips.find((t) => t.id == id)
  }

  setLists = (lists) => {
    this.lists.replace(lists)
  }

  addToLists = (data) => {
    const lists = (Array.isArray(data) ? data : [data]).filter(
      (i) => !this.findList(i.id)
    )

    if (lists.length) this.setLists([...this.lists, ...lists])
  }

  findList = (id) => {
    return this.lists.find((t) => t.id == id)
  }

  setCustomSiteLinks = (data) => {
    this.customSiteLinks = data
  }

  setTraffic = (data) => {
    this.traffic = data

    if (!data) {
      // this.setTrafficStart(null)
      this.setTrafficFinish(null)
      this.setTrafficTrips([])
      this.setTrafficTripActiveId(0)
      this.setTrafficChoices([])
      this.setTrafficMobileMap(false)
    }
  }

  setTrafficStart = (data) => {
    this.trafficStart = data
  }

  setTrafficFinish = (data) => {
    this.trafficFinish = data
  }

  setTrafficTrips = (data) => {
    this.trafficTrips = data
  }

  setTrafficTripActiveId = (data) => {
    this.trafficTripActiveId = data
  }

  setTrafficChoices = (data) => {
    this.trafficChoices = data
  }

  setTrafficChoicesHide = (data) => {
    this.trafficChoicesHide = data
  }

  setTrafficMobileMap = (data) => {
    this.trafficMobileMap = data
  }

  setFullscreen = (active) => {
    this.fullscreen = active
    return new Promise((resolve) => setTimeout(resolve, 400))
  }

  setMenu = (active) => {
    this.menu = active
  }

  setPointHoverId = (id) => {
    this.pointHoverId = id
  }

  setPointActiveId = (id, trigger = ``) => {
    this.pointActiveId = id
    this.pointActiveTrigger = trigger || ``
  }

  setPointPreselectedId = (id) => {
    this.pointPreselectedId = id
  }

  setElevations = (value, viaSite = false) => {
    if (!value && this.elevationsViaSite) {
      this.setMenu(true)
      this.setDisplaySite(true)
    }

    this.elevations = value
    this.elevationsViaSite = viaSite
  }

  setElevationsMark = (coordinates) => {
    this.elevationsMark = coordinates
  }

  setMeasure = (mode = null) => {
    if (!this.map) return
    mode = mode !== null ? mode : !this.measure
    this.measure = mode
    this.measureValue = 0

    if (!mode) {
      // @LATERDO: move into the component
      const mapMeasureSource = this.map.getSource(`geojson-measure`)
      if (mapMeasureSource) mapMeasureSource.setData(turfFeatureCollection([]))
    }
  }

  setMeasureValue = (value) => {
    this.measureValue = value
  }

  setLocation = (value) => {
    this.location = value
  }

  setDisplaySite = (active) => {
    this.displaySite = active
  }

  setPrint = (active = true) => {
    // if (!this.map) return
    this.print = active
  }

  setShare = (active = true) => {
    this.share = active
  }

  setLegend = (value) => {
    this.legend = value
  }

  setKeyboardAction = (data) => {
    this.keyboardAction = data
  }

  get pointsForMap() {
    return this.pointsHide ? [] : this.points.slice()
  }

  get shapesForMap() {
    return this.pointsHide ? [] : this.shapes.slice()
  }

  get pointActive() {
    return this.pointActiveId ? this.findPoint(this.pointActiveId) : null
  }

  get pointHover() {
    return this.pointHoverId ? this.findPoint(this.pointHoverId) : null
  }

  get pointPreselected() {
    return this.pointPreselectedId
      ? this.findPoint(this.pointPreselectedId)
      : null
  }

  get pointElevationsAvailable() {
    const point = this.pointActive || this.pointPreselected
    if (
      point &&
      point.properties.showElevations &&
      point.properties.type == `trail` &&
      !elevationsExcludes.includes(point.properties.icon)
    ) {
      const shape = this.shapeActive || this.shapePreselected
      // return shape ? shape.geometry.coordinates.length == 1 : false
      return !!shape
    }

    return false
  }

  get shapeActive() {
    return this.pointActiveId
      ? this.findShapeByParent(this.pointActiveId)
      : null
  }

  get shapeHover() {
    return this.pointHoverId ? this.findShapeByParent(this.pointHoverId) : null
  }

  get shapePreselected() {
    return this.pointPreselectedId
      ? this.findShapeByParent(this.pointPreselectedId)
      : null
  }

  get anyTrailSurfaceInGeojson() {
    return !!this.shapes.find((s) => s.properties.surface == `yes`)
  }

  get trafficTripActive() {
    return (
      this.trafficTripActiveId &&
      this.trafficTrips.find((t) => t.id == this.trafficTripActiveId)
    )
  }

  get strictInfo() {
    if (embedParams.strictShow && embedParams.strict) {
      if (embedParams.guide) {
        const guide = this.findGuide(embedParams.guide)
        if (guide) return { title: guide.name, type: `guide` }
      } else {
        const queryItem = this.query.find((q) =>
          [
            `guide`,
            `organization`,
            `municipality`,
            `county`,
            `trip`,
            `list`,
            `user`,
            `site_with_neighbours`,
            `site`,
          ].includes(q.type)
        )

        if (queryItem)
          return {
            title: queryItemToTitle(queryItem, this),
            type: queryItem.type,
          }
      }
    }

    return null
  }
}

const StoreContext = React.createContext()

const StoreProvider = ({ children, store }) => {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}

const useStore = () => {
  return React.useContext(StoreContext)
}

export { Store as default, StoreProvider, useStore }
