import React from "react"
import styled from "styled-components"

import { paramizeImgSrc } from "../../utils/img"

const Image = ({ data }) => {
  if (!data.image) return null

  const src = paramizeImgSrc({ src: data.image, width: 800, height: 600 })
  if (!src) return null

  return (
    <Container>
      <img src={src} alt={data.name} />
    </Container>
  )
}

export default Image

const Container = styled.figure`
  overflow: hidden;
  border-radius: ${({ theme }) => theme.br.normal};
  background-color: ${({ theme }) => theme.colors.lightGray};

  img {
    width: 100%;
    aspect-ratio: 3 / 2;
    object-fit: cover;
    display: block;
  }
`
