import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { em } from "polished"

import Styled from "../Styled"
import { ReactComponent as SvgChevronDown } from "../../assets/images/icons/chevron-down.svg"
import useDomId from "../../utils/useDomId"
import { __ } from "../../utils/translate"

const Facts = ({ data }) => {
  const [expanded, setExpanded] = useState(false)

  const textId = useDomId()
  const buttonId = useDomId()

  useEffect(() => {
    setExpanded(false)
  }, [data.id])

  const buttonClick = () => {
    setExpanded((v) => !v)
  }

  if (!data.facts) return null

  return (
    <Container>
      <h3>
        <Button
          onClick={buttonClick}
          aria-expanded={expanded}
          aria-controls={textId}
          id={buttonId}
          $expanded={expanded}
        >
          {__(`Facts`)}

          <SvgChevronDown aria-hidden="true" />
        </Button>
      </h3>

      {expanded && (
        <Text id={textId} aria-labelledby={buttonId}>
          <Styled dangerouslySetInnerHTML={{ __html: data.facts }} />
        </Text>
      )}
    </Container>
  )
}

export default Facts

const Container = styled.section`
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
`

const Button = styled.button.attrs({ type: `button` })`
  ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

  width: 100%;
  padding: ${em(8)} 0;
  display: flex;
  gap: ${em(20)};
  justify-content: space-between;
  align-items: baseline;
  letter-spacing: ${em(-0.25)};

  &:hover {
    color: ${({ theme }) => theme.colors.cadet};
  }

  svg {
    width: ${em(12)};
    height: ${em(12)};
    transition: transform 0.3s ${({ theme }) => theme.easings.default};

    ${({ $expanded }) =>
      $expanded &&
      css`
        transform: rotate(180deg);
      `}
  }
`

const Text = styled.div`
  margin-top: ${em(10)};
  padding-bottom: ${em(20)};
  animation: ${({ theme }) => theme.animations.slideInY(-10)} 0.3s
    ${({ theme }) => theme.easings.outBack};
`
