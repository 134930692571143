import React from "react"
import styled from "styled-components"
import { em, rem } from "polished"

import { __ } from "../../utils/translate"
import embedParams from "../../config/embedParams"

const Contact = ({ data }) => {
  const address = [
    data.postalAddress,
    data.postalCity,
    data.postalCode,
    data.postalCountry,
  ]
    .filter(Boolean)
    .join(`<br />`)

  if (
    embedParams.client == `stf` ||
    (!data.telephoneNumber &&
      !data.emailAddress &&
      !data.openingHours &&
      !address)
  )
    return null

  return (
    <Container>
      <Text>
        {data.telephoneNumber && (
          <>
            <h3>{__(`Phone`)}</h3>

            <p>
              <a href={`tel:${data.telephoneNumber}`}>{data.telephoneNumber}</a>
            </p>
          </>
        )}

        {data.emailAddress && (
          <>
            <h3>{__(`Email`)}</h3>

            <p>
              <a href={`mailto:${data.emailAddress}`}>{data.emailAddress}</a>
            </p>
          </>
        )}

        {address && (
          <>
            <h3>{__(`Address`)}</h3>

            <p dangerouslySetInnerHTML={{ __html: address }} />
          </>
        )}

        {data.openingHours && (
          <>
            <h3>{__(`Opening hours`)}</h3>

            <p>
              {data.openingHours.map((day, i) => (
                <React.Fragment key={i}>
                  {__(`day::${i + 1}`)}:{` `}
                  {day.open === true && __(`Open 24 hours`)}
                  {day.open === false && __(`Closed`)}
                  {day.open !== true && day.open !== false && (
                    <>
                      {/* replace: 0900 -> 09:00 */}
                      {day.open.replace(/^(.{2})/, `$1:`)}
                      {` `}–{` `}
                      {day.close.replace(/^(.{2})/, `$1:`)}
                    </>
                  )}
                  {data.openingHours.length - 1 != i && <br />}
                </React.Fragment>
              ))}
            </p>
          </>
        )}
      </Text>
    </Container>
  )
}

export default Contact

const Container = styled.div`
  padding: ${em(10)} ${rem(20)};
  border-top: 1px solid ${({ theme }) => theme.colors.lightGray};

  @media ${({ theme }) => theme.mq.xxsmallDown} {
    padding-left: ${rem(10)};
    padding-right: ${rem(10)};
  }
`

const Text = styled.div`
  font-size: ${em(13)};

  > * {
    + * {
      margin-top: ${em(8)};
    }

    + h3 {
      margin-top: ${em(26)};
    }
  }

  h3 {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

    font-size: ${em(15)};
    letter-spacing: ${em(-0.025)};
  }

  a {
    text-decoration-line: underline;

    &:hover {
      color: ${({ theme }) => theme.colors.cadet};
    }

    &:active {
      opacity: 0.8;
    }
  }
`
