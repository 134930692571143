import React from "react"
import { observer } from "mobx-react-lite"
import styled, { css } from "styled-components"
import { em, rem } from "polished"

const Styled = ({ children, innerRef, ...rest }) => {
  return (
    <Container ref={innerRef} {...rest}>
      {children}
    </Container>
  )
}

export default observer(Styled)

const tableBorder = css`1px solid ${({ theme }) => theme.colors.lightGray}`

const Container = styled.div`
  font-size: ${em(13)};

  > *:not(:last-child) {
    margin-bottom: ${rem(10)};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)}
  }

  p {
    /*  */
  }

  a,
  button {
    text-decoration-line: underline;

    &:hover {
      color: ${({ theme }) => theme.colors.cadet};
    }

    &:active {
      opacity: 0.8;
    }
  }

  mark {
    padding: 0 ${em(6)};
    box-decoration-break: clone;
    background-color: ${({ theme }) => theme.colors.cyberYellow};
  }

  u {
    text-decoration-line: underline;
  }

  hr {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
  }

  img {
    width: 100%;
    display: block;
  }

  iframe {
    width: 100%;
    height: auto;
    display: block;
    aspect-ratio: 16 / 9;
  }

  figure {
    figcaption {
      margin-top: ${em(10)};
      font-size: ${em(14)};
      line-height: 1.4;
      color: ${({ theme }) => theme.colors.cadet};
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    margin-left: ${em(20)};

    &:not(:last-child) {
      margin-bottom: ${em(6)};
    }
  }

  blockquote {
    padding-left: ${em(40)};
    position: relative;

    &::before {
      ${({ theme }) => theme.fonts.set(`primary`, `bold`)};

      content: "“";
      font-size: ${em(50)};
      position: absolute;
      top: ${em(4)};
      left: 0;
    }

    p {
      font-style: italic;
      word-break: break-word;
      line-height: 1.4;
    }

    > * + * {
      margin-top: ${em(20)};
    }

    // author

    > footer {
      font-size: ${em(14)};
      text-align: right;
      line-height: 1.4;
      color: ${({ theme }) => theme.colors.cadet};

      &::before {
        content: "— ";
      }
    }
  }

  table {
    width: 100%;
    word-break: break-word;
    border-collapse: collapse;

    thead {
      tr {
        border-bottom: ${tableBorder};
        border-width: 2px;
      }
    }

    tbody,
    tfoot {
      tr {
        &:not(:last-child) {
          border-bottom: ${tableBorder};
        }
      }
    }

    td,
    th {
      padding: ${em(10)};
      vertical-align: top;
      text-align: left;

      &:not(:last-child) {
        border-right: ${tableBorder};
      }
    }

    th {
      ${({ theme }) => theme.fonts.set(`primary`, `bold`)};
    }
  }

  .styled-table-container {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    table:not(.do-unstyle) {
      min-width: 300px;
      word-break: normal;
    }
  }
`
