import React from "react"
import { observer } from "mobx-react-lite"
import styled, { css } from "styled-components"
import { rem } from "polished"

import { ReactComponent as SvgEffort } from "../assets/images/icons/effort.svg"
import { __ } from "../utils/translate"
import { useStore } from "../store"

const ElevationsControl = () => {
  const store = useStore()

  if (!store.pointElevationsAvailable) return null

  const buttonClick = () => {
    store.setElevations(!store.elevations)
  }

  return (
    <Container $active={store.elevations}>
      <button type="button" title={__(`Elevations`)} onClick={buttonClick}>
        <SvgEffort aria-label={__(`Elevations`)} />
      </button>
    </Container>
  )
}

export default observer(ElevationsControl)

const Container = styled.div`
  animation: ${({ theme }) => theme.animations.slideInX(10)} 0.6s
    ${({ theme }) => theme.easings.outBack};

  button {
    width: ${rem(42)};
    height: ${rem(42)};
    padding: ${rem(12)};
    display: block;
    position: relative;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: ${({ theme }) => theme.bs.button};
    border-radius: ${({ theme }) => theme.br.normal};

    ${({ $active }) =>
      $active &&
      css`
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.seaGreen};
        box-shadow: ${({ theme }) => theme.bs.buttonPressed};
      `}

    &:hover {
      color: ${({ theme }) => theme.colors.cadet};

      ${({ $active }) =>
        $active &&
        css`
          color: ${({ theme }) => theme.colors.aliceBlue};
        `}
    }

    &::before {
      /* expanded click area */
      content: "";
      position: absolute;
      top: ${rem(-5)};
      bottom: ${rem(-5)};
      left: ${rem(-10)};
      right: ${rem(-10)};
    }
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`
