import humanizeString from "string-humanize"

import allTranslations from "../translations"
import embedParams from "../config/embedParams"

const translations = allTranslations[embedParams.language]

const __ = (text, replacements = null) => {
  let translation = translations[text]

  if (!translation && process.env.NODE_ENV !== `production`) {
    if (embedParams.language != `en` || text.includes(`::`)) {
      // eslint-disable-next-line
      console.warn(
        `Translation not found for "${text}" (${embedParams.language.toUpperCase()})`
      )
    }
  }

  if (!translation) {
    translation = text

    if (translation.includes(`::`))
      translation = humanizeString(translation.split(`::`).pop())
  }

  if (replacements)
    Object.entries(replacements).forEach(
      ([k, v]) => (translation = translation.replace(`%{${k}}`, v))
    )

  return translation
}

const _n = (text, textPlural, num, replacements = null) => {
  return __(num <= 1 ? text : textPlural, replacements)
}

export { __, _n }
