import React, { useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { em } from "polished"

import Image from "./Image"
import Header from "./Header"
import Description from "./Description"
import Facts from "./Facts"
import { useStore } from "../../store"
import embedParams from "../../config/embedParams"

const Miniguide = () => {
  const store = useStore()
  const [data, setData] = useState(null)

  useEffect(() => {
    setData(embedParams.strict == `trip` ? store.trips[0] : store.lists[0])
  }, [store.trips.slice(), store.lists.slice()])

  if (!data) {
    if (store.initiallyLoaded) return null

    return (
      <Loader>
        {Array.from({ length: 3 }, (_, i) => (
          <div key={i} />
        ))}
      </Loader>
    )
  }

  return (
    <Container>
      <Image data={data} />

      <Header data={data} />

      <Description data={data} />

      <Facts data={data} />
    </Container>
  )
}

export default observer(Miniguide)

const Container = styled.article`
  width: 100%;

  > *:not(:last-child) {
    margin-bottom: ${em(20)};
  }
`

const Loader = styled.div`
  width: 100%;

  div {
    width: 100%;
    height: ${em(90)};
    background-color: ${({ theme }) => theme.colors.lightGray};
    border-radius: ${({ theme }) => theme.br.small};
    animation: ${({ theme }) => theme.animations.fadeBlink} 1s infinite linear;

    &:nth-child(1) {
      height: ${em(200)};
    }

    &:nth-child(2) {
      width: 90%;
      height: ${em(20)};
    }

    &:nth-child(3) {
      width: 60%;
      height: ${em(20)};
    }

    &:not(:last-child) {
      margin-bottom: ${em(16)};
    }
  }
`
