import React from "react"
import styled from "styled-components"
import { em, rem, rgba } from "polished"

import Button from "../Button"
import { ReactComponent as SvgClose } from "../../assets/images/icons/close.svg"
import { __ } from "../../utils/translate"
import { useStore } from "../../store"

const Header = () => {
  const store = useStore()

  const closeClick = () => store.setTraffic(``)

  return (
    <Container>
      <h2>{__(`Public transport`)}</h2>

      <Button
        type="button"
        onClick={closeClick}
        title={__(`Close`)}
        color="gunmetal"
        size="xsmall"
        icon={<SvgClose />}
      />
    </Container>
  )
}

export default Header

const Container = styled.header`
  padding: ${em(12)} ${rem(20)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${em(10)};

  @media ${({ theme }) => theme.mq.mobileDown} {
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 ${rem(4)} ${rem(4)}
      ${({ theme }) => rgba(theme.colors.black, 0.25)};
  }

  @media ${({ theme }) => theme.mq.xxsmallDown} {
    padding: ${em(10)} ${rem(10)};
  }

  h2 {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

    font-size: ${em(15)};
    letter-spacing: ${em(-0.025)};
  }
`
