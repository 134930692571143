// TODO: fallback for Images lazy loading

import React, { useState, useEffect, useRef, Suspense, lazy } from "react"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import styled, { css } from "styled-components"
import { rem } from "polished"
import throttle from "raf-throttle"

import Header from "./Header"
import Basic from "./Basic"
import TrailStatus from "./TrailStatus"
import Toolbar from "./Toolbar"
import Current from "./Current"
import Audio from "./Audio"
import Description from "./Description"
import Categories from "./Categories"
import Elevations from "./Elevations"
import Texts from "./Texts"
import Contact from "./Contact"
import Links from "./Links"
import Footer from "./Footer"
import { useStore } from "../../store"
import embedParams from "../../config/embedParams"
import { fetchApiSiteInfo } from "../../utils/api"
import { trackEvent } from "../../utils/track"
import { postEmbedMessage } from "../../utils/embedMessage"

const Images = lazy(() => import(`./Images`))

const Site = () => {
  const store = useStore()
  const [data, setData] = useState(null)
  const [showTrailStatus, setShowTrailStatus] = useState(false)
  const [scrollableTop, setScrollableTop] = useState(false)
  const [scrollableBottom, setScrollableBottom] = useState(false)
  const dataCompleteRef = useRef(false)
  const scrollableRef = useRef(false)
  const siteViewedEmbedMessagePostedRef = useRef(false)

  useEffect(() => {
    if (!data || dataCompleteRef.current) return
    const id = data.id

    fetchApiSiteInfo({ id, language: embedParams.language }).then((info) => {
      if (id != store.pointActiveId) return

      dataCompleteRef.current = true

      setData({
        ...data,
        ...info,
        images: info.images.length ? info.images : data.images,
      })
    })

    if (!siteViewedEmbedMessagePostedRef.current) {
      siteViewedEmbedMessagePostedRef.current = true
      postEmbedMessage(`siteViewed`, {
        id,
        url: data.url,
        title: data.title,
      })
    }
  }, [data])

  useEffect(() => {
    dataCompleteRef.current = false

    if (!store.pointActive) {
      setData(null)
      return
    }

    const point = toJS(store.pointActive)
    const newData = {
      ...point.properties,
      id: point.id,
      geometry: point.geometry,
      images: point.properties.image
        ? [
            {
              url: point.properties.image,
              mediaType: point.properties.image.match(/\.(mp4|mov)$/i)
                ? `video`
                : `image`,
            },
          ]
        : [],
    }

    setData(newData)
    setShowTrailStatus(newData.organization ? false : true)

    const organizationPromise = newData.organization
      ? store.getOrganization(newData.organization)
      : Promise.reject(new Error())

    organizationPromise
      .then((organization) => {
        if (!organization) throw new Error()
        if (organization.trailStatusReportEnabled) setShowTrailStatus(true)

        trackEvent(`view_site`, {
          item_id: newData.id,
          item_name: newData.title,
          organization_id: organization.id,
          organization_name: organization.name,
          trigger: store.pointActiveTrigger,
        })
      })
      .catch(() => {
        trackEvent(`view_site`, {
          item_id: newData.id,
          item_name: newData.title,
          trigger: store.pointActiveTrigger,
        })
      })
  }, [store.pointActive])

  useEffect(() => {
    setScrollableEdges()
  }, [scrollableRef.current])

  const setScrollableEdges = () => {
    if (!scrollableRef.current) return
    setScrollableTop(scrollableRef.current.scrollTop > 0)

    setScrollableBottom(
      scrollableRef.current.scrollTop + scrollableRef.current.offsetHeight <
        scrollableRef.current.scrollHeight
    )
  }

  const scrollableScroll = throttle(setScrollableEdges)

  if (!data) return null

  return (
    <Container>
      <Scrollable
        ref={scrollableRef}
        onScroll={scrollableScroll}
        $separateTop={scrollableTop}
        $separateBottom={scrollableBottom}
      >
        <Suspense fallback={null}>
          {!!data.images.length && <Images data={data} />}
        </Suspense>

        <Header data={data} />

        <Basic data={data} />

        {showTrailStatus && <TrailStatus data={data} />}

        <Toolbar data={data} />

        <Current data={data} />

        <Audio data={data} />

        <Description data={data} />

        <Categories data={data} />

        <Elevations data={data} />

        <Texts data={data} />

        <Contact data={data} />

        <Links data={data} />
      </Scrollable>

      <Footer data={data} />
    </Container>
  )
}

export default observer(Site)

const Container = styled.article`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  animation:
    ${({ theme }) => theme.animations.fadeIn} 0.2s linear,
    ${({ theme }) => theme.animations.slideInY(20)} 0.2s
      ${({ theme }) => theme.easings.outBack};
`

const Scrollable = styled.div`
  flex-grow: 1;
  overflow: hidden;
  overflow-y: auto;
  /* overflow-anchor: none; */
  -webkit-overflow-scrolling: touch;
  border: solid transparent;
  border-width: 1px 0;

  ${({ $separateTop }) =>
    $separateTop &&
    css`
      border-top-color: ${({ theme }) => theme.colors.lightGray};
    `}

  ${({ $separateBottom }) =>
    $separateBottom &&
    css`
      border-bottom-color: ${({ theme }) => theme.colors.lightGray};
    `}

  &::-webkit-scrollbar {
    width: ${rem(5)};
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gunmetal};
    border-radius: ${({ theme }) => theme.br.small};
  }
`
