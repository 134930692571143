import React from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { em, rem } from "polished"
import turfBbox from "@turf/bbox"
import { featureCollection as turfFeatureCollection } from "@turf/helpers"

import Button from "../Button"
import { ReactComponent as SvgLocation } from "../../assets/images/icons/location.svg"
import { useStore } from "../../store"
import embedParams from "../../config/embedParams"
import { __ } from "../../utils/translate"
import mq from "../../theme/mq"

const Footer = ({ data }) => {
  const store = useStore()

  let url = data.url
  let title = ``
  const target =
    !embedParams.client || [`satellite`].includes(embedParams.client)
      ? `_top`
      : `_blank`

  if (store.customSiteLinks) url = store.customSiteLinks[data.id]
  else if (embedParams.client) {
    url = ``

    switch (embedParams.client) {
      case `roslagen`:
      case `visitvarmland`: {
        const link = (data.links || []).find((l) => l.type == `partner`)
        if (link) {
          url = link.url
          title = link.title
        }
        break
      }

      case `stf`: {
        const link = (data.links || []).find((l) =>
          l.url.includes(`svenskaturistforeningen.se`)
        )
        if (link) {
          url = link.url
          title = link.title
        }
        break
      }

      case `bergslagsleden`: {
        const link = (data.links || []).find((l) =>
          l.url.includes(`bergslagsleden.se`)
        )
        if (link) {
          url = link.url
          title = link.title
        }
        break
      }

      case `stolavsleden`: {
        const link = (data.links || []).find((l) =>
          l.url.includes(`stolavsleden.com`)
        )
        if (link) {
          url = link.url
          title = link.title
        }
        break
      }
    }
  }

  const showOnMapClick = () => {
    const travelMap = { instant: false }
    const shape = data.type != `place` && store.findShapeByParent(data.id)

    if (shape) travelMap.fitBounds = turfBbox(turfFeatureCollection([shape]))
    else {
      travelMap.flyTo = {
        center: data.geometry.coordinates,
        zoom: 14,
      }
    }
    store.travelMap(travelMap)

    if (window.matchMedia(mq.mobileDown).matches) {
      store.setMenu(false)
      store.setDisplaySite(false)
      store.setPointActiveId(data.id)
    }
  }

  if (url)
    url =
      url +
      (url.includes(`?`) ? `&` : `?`) +
      `utm_source=naturkartan&utm_medium=map` // should be synced with the main site

  return (
    <Container>
      <Button
        onClick={showOnMapClick}
        icon={<SvgLocation />}
        color="aliceBlue"
        fill="gunmetal"
        block={true}
        nowrap={true}
        title={__(`Show on map`)}
      >
        <TextFull>{__(`Show on map`)}</TextFull>

        <TextMinimized>{__(`Show`)}</TextMinimized>
      </Button>

      {url && (
        <Button
          href={url}
          target={target}
          title={__(`Exit map and go to site page`)}
          rel="noopener noreferrer"
          fill="white"
          outline="gunmetal"
          outlineStrong={true}
          block={true}
          nowrap={true}
        >
          {title || (
            <>
              <TextFull>{__(`More info`)}</TextFull>

              <TextMinimized>{__(`More`)}</TextMinimized>
            </>
          )}
        </Button>
      )}
    </Container>
  )
}

export default observer(Footer)

const Container = styled.footer`
  padding: ${em(12)} ${rem(20)};
  display: flex;
  gap: ${em(12)};
  container-type: inline-size;

  @media ${({ theme }) => theme.mq.xxsmallDown} {
    padding: ${em(14)} ${rem(10)};
  }
`

const TextFull = styled.span`
  @container (max-width: ${em(360)}) {
    display: none;
  }
`

const TextMinimized = styled.span`
  display: none;

  @container (max-width: ${em(360)}) {
    display: inline;
  }
`
