import React from "react"
import styled from "styled-components"
import { em, rem, hideVisually } from "polished"

import ExpandableText from "../ExpandableText"
import { __ } from "../../utils/translate"
import embedParams from "../../config/embedParams"

const Desciption = ({ data }) => {
  if (!data.description || embedParams.client == `stf`) return null

  return (
    <Container>
      <Title>{__(`Description`)}</Title>

      <ExpandableText text={data.description} />
    </Container>
  )
}

export default Desciption

const Container = styled.section`
  padding: ${em(10)} ${rem(20)};

  @media ${({ theme }) => theme.mq.xxsmallDown} {
    padding-left: ${rem(10)};
    padding-right: ${rem(10)};
  }
`

const Title = styled.h3`
  ${hideVisually()}
`
