import React from "react"
import { Layer } from "react-mapbox-gl"

import colors from "../theme/colors"

const lineOpacity = {
  "line-opacity": [
    `case`,
    [`boolean`, [`feature-state`, `invisible`], false],
    0,
    1,
  ],
  // "line-opacity": 1,
}

const layerConfig = {
  sourceId: `geojson-shapes`,
  type: `line`,
  layout: {
    "line-join": `round`,
    // "line-cap": `round`,
  },
  // filter: [
  //   `match`,
  //   [`geometry-type`],
  //   [`LineString`, `MultiLineString`],
  //   true,
  //   false,
  // ],
  filter: [`all`, [`==`, `$type`, `LineString`], [`!=`, `surface`, `yes`]],
}

const MapTrails = () => (
  <>
    {/* trail */}

    <Layer
      {...layerConfig}
      paint={{
        "line-width": 8,
        "line-color": [
          `case`,
          [
            `any`,
            [`boolean`, [`feature-state`, `active`], false],
            [`boolean`, [`feature-state`, `hover`], false],
          ],
          `rgba(0, 0, 0, 0)`,
          colors.white,
        ],
        ...lineOpacity,
      }}
    />

    <Layer
      {...layerConfig}
      paint={{
        "line-width": 5,
        "line-dasharray": [1, 0.2],
        "line-color": [
          `case`,
          [
            `any`,
            [`boolean`, [`feature-state`, `active`], false],
            [`boolean`, [`feature-state`, `hover`], false],
          ],
          `rgba(0, 0, 0, 0)`,
          [
            `case`,
            [`!=`, [`get`, `color`], null],
            [`get`, `color`],
            colors.russet,
          ],
        ],
        ...lineOpacity,
      }}
      id="trails"
    />

    {/* trail: active */}

    <Layer
      {...layerConfig}
      paint={{
        "line-width": 14,
        "line-color": [
          `case`,
          [`boolean`, [`feature-state`, `active`], false],
          colors.orange,
          `rgba(0, 0, 0, 0)`,
        ],
        ...lineOpacity,
      }}
    />

    <Layer
      {...layerConfig}
      paint={{
        "line-width": 8,
        "line-color": [
          `case`,
          [`boolean`, [`feature-state`, `active`], false],
          colors.white,
          `rgba(0, 0, 0, 0)`,
        ],
        ...lineOpacity,
      }}
    />

    <Layer
      {...layerConfig}
      paint={{
        "line-width": 5,
        "line-dasharray": [1, 0.2],
        "line-color": [
          `case`,
          [`boolean`, [`feature-state`, `active`], false],
          [
            `case`,
            [`!=`, [`get`, `color`], null],
            [`get`, `color`],
            colors.russet,
          ],
          `rgba(0, 0, 0, 0)`,
        ],
        ...lineOpacity,
      }}
    />

    {/* trail: hover */}

    <Layer
      {...layerConfig}
      paint={{
        "line-width": 8,
        "line-color": [
          `case`,
          [`boolean`, [`feature-state`, `hover`], false],
          colors.black,
          `rgba(0, 0, 0, 0)`,
        ],
        ...lineOpacity,
      }}
    />

    <Layer
      {...layerConfig}
      paint={{
        "line-width": 5,
        "line-dasharray": [1, 0.2],
        "line-color": [
          `case`,
          [`boolean`, [`feature-state`, `hover`], false],
          [
            `case`,
            [`!=`, [`get`, `color`], null],
            [`get`, `color`],
            colors.russet,
          ],
          `rgba(0, 0, 0, 0)`,
        ],
        ...lineOpacity,
      }}
    />

    {/* surface: background */}

    <Layer
      {...layerConfig}
      paint={{
        "line-width": 5,
        "line-color": colors.white,
        ...lineOpacity,
      }}
      filter={[`all`, [`==`, `$type`, `LineString`], [`==`, `surface`, `yes`]]}
    />

    {/* surface: dashed */}

    <Layer
      {...layerConfig}
      paint={{
        "line-width": 5,
        "line-dasharray": [1, 0.2],
        "line-color": [
          `case`,
          [`==`, [`get`, `type`], `gravel`],
          colors.surfaceGravel,
          [`==`, [`get`, `type`], `asphalt`],
          colors.surfaceAsphalt,
          `rgba(0, 0, 0, 0)`,
        ],
        ...lineOpacity,
      }}
      filter={[
        `all`,
        [`==`, `$type`, `LineString`],
        [`==`, `surface`, `yes`],
        [`in`, `type`, `gravel`, `asphalt`],
      ]}
    />

    {/* surface: solid */}

    <Layer
      {...layerConfig}
      paint={{
        "line-width": 5,
        "line-color": [
          `case`,
          [`==`, [`get`, `type`], `bike-gravel`],
          colors.surfaceGravel,
          [`==`, [`get`, `type`], `bike-asphalt`],
          colors.surfaceAsphalt,
          `rgba(0, 0, 0, 0)`,
        ],
        ...lineOpacity,
      }}
      filter={[
        `all`,
        [`==`, `$type`, `LineString`],
        [`==`, `surface`, `yes`],
        [`in`, `type`, `bike-gravel`, `bike-asphalt`],
      ]}
    />
  </>
)

export default MapTrails
