import React, { useState, useEffect, useRef } from "react"
import { observer } from "mobx-react-lite"
import styled, { css } from "styled-components"
import { em, rem } from "polished"

import Styles from "./Styles"
import { ReactComponent as SvgLayers } from "../assets/images/icons/layers.svg"
import embedParams from "../config/embedParams"
import { __ } from "../utils/translate"
import { useStore } from "../store"

const StyleControl = ({ onActivate }) => {
  const store = useStore()
  const [active, setActive] = useState(false)
  const containerRef = useRef()

  const toggleClick = () => {
    setActive((v) => !v)
  }

  const stylesCloseRequest = () => {
    setActive(false)
  }

  useEffect(() => {
    if (onActivate) onActivate(active)
  }, [active])

  if (
    embedParams.styleControl === false ||
    (embedParams.styleControl == `fullscreen` && !store.fullscreen)
  )
    return null

  return (
    <Container ref={containerRef}>
      <Toggle onClick={toggleClick} title={__(`Style`)} $active={active}>
        <SvgLayers aria-label={__(`Style`)} />
      </Toggle>

      {active && (
        <Styles
          referenceEl={containerRef.current}
          onCloseRequest={stylesCloseRequest}
        />
      )}
    </Container>
  )
}

export default observer(StyleControl)

const Container = styled.div``

const Toggle = styled.button.attrs({ type: `button` })`
  width: ${em(42)};
  height: ${em(42)};
  padding: ${em(12)};
  display: block;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.bs.button};
  border-radius: ${({ theme }) => theme.br.normal};

  ${({ $active }) =>
    $active &&
    css`
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.seaGreen};
      box-shadow: ${({ theme }) => theme.bs.buttonPressed};
    `}

  &:hover {
    color: ${({ theme }) => theme.colors.cadet};

    ${({ $active }) =>
      $active &&
      css`
        color: ${({ theme }) => theme.colors.aliceBlue};
      `}
  }

  &::before {
    /* expanded click area */
    content: "";
    position: absolute;
    top: ${rem(-5)};
    bottom: ${rem(-5)};
    left: ${rem(-10)};
    right: ${rem(-10)};
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`
