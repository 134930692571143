import heremapsConfig from "./heremaps"
import thunderforest from "./thunderforest"

const mapStyles = {
  lantmateriet: {
    tiles: [
      `https://maptiles.naturkartan-cdn.se/tiles/sweden/EPSG3857/{z}/{x}/{y}.png`,
    ],
    tileSize: 256,
    type: `raster`,
    minZoom: 6.5,
    maxZoom: 16,
    bounds: [
      [4.5273686052, 54.5617373725],
      [31.9033785336, 71.1062472602],
    ],
    zoomFallbackTileset: `outdoor`,
  },

  outdoor: {
    tiles: [
      `https://tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=${thunderforest.apiKey}`,
    ],
    tileSize: 256,
    type: `raster`,
  },

  cycling: {
    tiles: [
      `https://tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=${thunderforest.apiKey}`,
    ],
    tileSize: 256,
    type: `raster`,
  },

  hybrid: {
    tiles: [
      `https://2.aerial.maps.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/256/png8?apiKey=${heremapsConfig.apiKey}`,
    ],
    tileSize: 256,
    type: `raster`,
  },
}

const mapStyleNames = Object.keys(mapStyles)

const mapStyleNameDefault = mapStyleNames[0]

export { mapStyles as default, mapStyleNames, mapStyleNameDefault }
