import React from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { em } from "polished"

import Header from "./Header"
import Form from "./Form"
import Trips from "./Trips"
import Trip from "./Trip"
import { useStore } from "../../store"

const Traffic = () => {
  const store = useStore()

  return (
    <Container>
      <HeaderWrap>
        <Header />
      </HeaderWrap>

      {[`form`].includes(store.traffic) && <Form />}

      {[`trips`, `trips:loading`].includes(store.traffic) && <Trips />}

      {store.traffic == `trip` && store.trafficTripActive && <Trip />}
    </Container>
  )
}

export default observer(Traffic)

const Container = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.timberwolf};
  animation:
    ${({ theme }) => theme.animations.fadeIn} 0.2s linear,
    ${({ theme }) => theme.animations.slideInY(20)} 0.2s
      ${({ theme }) => theme.easings.outBack};
`

const HeaderWrap = styled.div`
  @media ${({ theme }) => theme.mq.mobileDown} {
    padding-bottom: ${em(10)};
  }
`
