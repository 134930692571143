import React from "react"
import { observer } from "mobx-react-lite"
import { Layer, Feature } from "react-mapbox-gl"

import { useStore } from "../store"
import colors from "../theme/colors"

const MapElevationsMark = () => {
  const store = useStore()

  if (!store.elevationsMark.length) return null

  return (
    <Layer
      type="circle"
      paint={{
        "circle-radius": 5,
        "circle-opacity": 1,
        "circle-color": colors.orange,
        "circle-stroke-width": 2,
        "circle-stroke-color": colors.black,
      }}
    >
      <Feature coordinates={store.elevationsMark} />
    </Layer>
  )
}

export default observer(MapElevationsMark)
