import Plausible from "plausible-tracker"

const DOMAIN = process.env.REACT_APP_PLAUSIBLE_DOMAIN
const DEBUG = process.env.REACT_APP_DEBUG_TRACKING

const plausible = DOMAIN
  ? // eslint-disable-next-line new-cap
    Plausible({
      DOMAIN,
      trackLocalhost: DEBUG,
    })
  : null

const consoleLog = (...params) =>
  DEBUG && console.log(`[Outdoormap Map Tracker]`, ...params) // eslint-disable-line no-console

const trackPageview = () => {
  return (
    plausible &&
    plausible.trackPageview({}, { callback: () => consoleLog(`Page view`) })
  )
}

const trackEvent = (name, props) => {
  return (
    plausible &&
    plausible.trackEvent(name, {
      props,
      callback: () => consoleLog(`Event`, name, props),
    })
  )
}

const trackOutboundLink = (url) => {
  return trackEvent(`Outbound Link: Click`, { url })
}

const enableAutoOutboundLinkTrack = () => {
  let timeout = null

  const docClick = (e) => {
    if (e.defaultPrevented) return

    const el = e.target.closest(`a`)
    if (el && [`blank`, `_blank`, `_top`, `_parent`].includes(el.target)) {
      e.preventDefault()
      trackOutboundLink(el.href)
      timeout = window.setTimeout(() => window.open(el.href, el.target), 150)
    }
  }

  document.addEventListener(`click`, docClick)

  return () => {
    window.clearTimeout(timeout)
    document.removeEventListener(`click`, docClick)
  }
}

export {
  trackPageview,
  trackEvent,
  trackOutboundLink,
  enableAutoOutboundLinkTrack,
}
