import React, { useEffect } from "react"
import ReactDOM from "react-dom"
import { observer } from "mobx-react-lite"
import { usePopper } from "react-popper"
import styled, { css } from "styled-components"
import { em, rem } from "polished"

import { ReactComponent as SvgCheck } from "../assets/images/icons/check.svg"
import { useStore } from "../store"
import useReferredState from "../utils/useReferredState"
import { __ } from "../utils/translate"
import { mapStyleNames } from "../config/mapStyles"

const A11ys = ({ referenceEl, onCloseRequest }) => {
  const store = useStore()
  const [containerEl, containerElRef, setContainerEl] = useReferredState(null)
  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceEl,
    containerEl,
    { placement: `left-start` }
  )

  const styleClick = (e) => {
    window.setTimeout(onCloseRequest, 200)
    const { style } = e.currentTarget.dataset
    store.setMapStyle(style)
  }

  const docClick = (e) => {
    if (
      containerElRef.current &&
      !containerElRef.current.contains(e.target) &&
      !referenceEl.contains(e.target)
    )
      onCloseRequest()
  }

  useEffect(() => {
    document.addEventListener(`click`, docClick, { passive: true })

    return () => {
      document.removeEventListener(`click`, docClick, { passive: true })
    }
  }, [])

  return ReactDOM.createPortal(
    <Container
      ref={setContainerEl}
      style={popperStyles.popper}
      {...popperAttributes.popper}
    >
      <List>
        {mapStyleNames.map((style) => (
          <li key={style}>
            <Button
              type="button"
              onClick={styleClick}
              $active={style == store.mapStyle}
              aria-selected={style == store.mapStyle}
              data-style={style}
            >
              {__(`map-style::${style}`)}

              <SvgCheck aria-hidden="true" />
            </Button>
          </li>
        ))}
      </List>
    </Container>,
    document.querySelector(`body`)
  )
}

export default observer(A11ys)

const Container = styled.div`
  z-index: 999999;
  padding-right: ${rem(10)};
`

const List = styled.ul`
  border: solid ${({ theme }) => theme.colors.aliceBlue};
  border-width: 1px 0 0 1px;
  padding: ${em(12)};
  position: absolute;
  top: 0;
  right: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.bs.button};
  border-radius: ${({ theme }) => theme.br.normal};
  animation: ${({ theme }) => theme.animations.slideInX(10)} 0.3s
    ${({ theme }) => theme.easings.outBack};

  > * + * {
    margin-top: ${em(4)};
  }
`

const Button = styled.button.attrs({ type: `button` })`
  ${({ theme }) => theme.fonts.set(`primary`, `medium`)}

  width: 100%;
  padding: ${em(6)} ${em(12)};
  display: flex;
  align-items: baseline;
  gap: ${em(10)};
  font-size: ${em(13)};
  white-space: nowrap;
  border-radius: ${({ theme }) => theme.br.small};

  ${({ $active }) =>
    $active &&
    css`
      background-color: ${({ theme }) => theme.colors.lightGray};
    `}

  &:hover {
    color: ${({ theme }) => theme.colors.cadet};
  }

  svg {
    width: ${em(14)};
    height: ${em(14)};
    margin-left: auto;
    flex-shrink: 0;
    opacity: ${({ $active }) => ($active ? 1 : 0)};
  }
`
