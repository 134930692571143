import React from "react"
import { observer } from "mobx-react-lite"
import { Source } from "react-mapbox-gl"

import { useStore } from "../store"

const MapSourceShapes = () => {
  const store = useStore()

  return (
    <Source
      id="geojson-shapes"
      geoJsonSource={{
        type: `geojson`,
        data: {
          type: `FeatureCollection`,
          features: store.pointsHide ? [] : store.shapes.slice(),
        },
      }}
    />
  )
}

export default observer(MapSourceShapes)
