import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import styled, { css, keyframes } from "styled-components"
import { rem } from "polished"

import { ReactComponent as SvgFullscreen } from "../assets/images/icons/fullscreen.svg"
import { ReactComponent as SvgMinimize } from "../assets/images/icons/minimize.svg"
import { useStore } from "../store"
import { __ } from "../utils/translate"
import embedParams from "../config/embedParams"
import mq from "../theme/mq"

const FullscreenControl = () => {
  const store = useStore()
  const [wasClicked, setWasClicked] = useState(false)

  const click = () => {
    store
      .setFullscreen(!store.fullscreen)
      .then(
        () =>
          embedParams.menu == `fullscreen` &&
          store.fullscreen &&
          window.matchMedia(mq.mobileUp).matches &&
          store.setMenu(true)
      )

    setWasClicked(true)
  }

  if (
    embedParams.fullscreenControl === false ||
    (embedParams.fullscreenControl == `fullscreen` && !store.fullscreen)
  )
    return null

  return (
    <Container $active={store.fullscreen} $wasClicked={wasClicked}>
      <button
        type="button"
        title={
          store.fullscreen
            ? __(`Exit fullscreen mode`)
            : __(`Enter fullscreen mode`)
        }
        onClick={click}
      >
        {store.fullscreen ? (
          <SvgMinimize aria-label={__(`Exit fullscreen mode`)} />
        ) : (
          <SvgFullscreen aria-label={__(`Enter fullscreen mode`)} />
        )}
      </button>
    </Container>
  )
}

export default observer(FullscreenControl)

const underlayAnim = keyframes`
  50% { transform: scale(1.1); opacity: 0.6; }
`

const Container = styled.div`
  button {
    width: ${rem(42)};
    height: ${rem(42)};
    padding: ${rem(11)};
    display: block;
    position: relative;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.gunmetal};
    box-shadow: ${({ theme }) => theme.bs.button};
    border-radius: ${({ theme }) => theme.br.normal};

    &:hover {
      color: ${({ theme }) => theme.colors.lightGray};
    }

    &:active {
      background-color: ${({ theme }) => theme.colors.cadet};
    }

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: ${rem(-3)};
      bottom: ${rem(-3)};
      left: ${rem(-3)};
      right: ${rem(-3)};
      border-radius: ${rem(10)};
      background-color: ${({ theme }) => theme.colors.seaGreen};
      animation: ${underlayAnim} 3s ${({ theme }) => theme.easings.default}
        infinite;

      ${({ $active, $wasClicked }) =>
        ($active || $wasClicked) &&
        css`
          display: none;
        `}
    }

    &::after {
      /* expanded click area */
      content: "";
      position: absolute;
      z-index: -2;
      top: ${rem(-5)};
      bottom: ${rem(-5)};
      left: ${rem(-10)};
      right: ${rem(-10)};
    }
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`
