import React, { useState, useMemo, useEffect } from "react"
import ReactDOM from "react-dom"
import { usePopper } from "react-popper"
import styled from "styled-components"
import { em } from "polished"

const Tooltip = ({ children, referenceEl }) => {
  const [containerEl, setContainerEl] = useState(null)
  const [mouse, setMouse] = useState([])

  const mouseReferenceEl = useMemo(
    () => ({
      getBoundingClientRect: () => {
        return {
          top: mouse[1] || 0,
          left: mouse[0] || 0,
          bottom: mouse[0],
          right: mouse[1],
          width: 0,
          height: 0,
        }
      },
    }),
    [mouse]
  )

  const popper = usePopper(referenceEl || mouseReferenceEl, containerEl, {
    placement: `top-start`,
    modifiers: [
      {
        name: `flip`,
        enabled: true,
      },
      {
        name: `offset`,
        options: {
          offset: [0, 6],
        },
      },
    ],
  })

  const docMouseMove = (e) => {
    setMouse([e.clientX, e.clientY])
  }

  useEffect(() => {
    if (!referenceEl) document.addEventListener(`mousemove`, docMouseMove)

    return () => {
      if (!referenceEl) document.removeEventListener(`mousemove`, docMouseMove)
    }
  }, [])

  if (!referenceEl && !mouse.length) return null

  return ReactDOM.createPortal(
    <Container
      ref={setContainerEl}
      style={popper.styles.popper}
      // {...popper.attributes.popper}
    >
      <Text>{children}</Text>
    </Container>,
    document.querySelector(`body`)
  )
}

export default Tooltip

const Container = styled.div`
  z-index: ${({ theme }) => theme.zindex.siteTooltip};
  max-width: ${em(320)};
`

const Text = styled.div`
  ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

  padding: ${em(8)} ${em(16)};
  font-size: ${em(14)};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.gunmetal};
  border-radius: ${({ theme }) => theme.br.normal};
  box-shadow: ${({ theme }) => theme.bs.button};
`
