import React from "react"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import { Source } from "react-mapbox-gl"

import { useStore } from "../store"

const MapSourceUnclustered = () => {
  const store = useStore()

  return (
    <Source
      id="geojson-points-unclustered"
      geoJsonSource={{
        type: `geojson`,
        data: {
          type: `FeatureCollection`,
          features: [
            store.pointActive && toJS(store.pointActive),
            store.pointHover && toJS(store.pointHover),
            store.pointPreselected && toJS(store.pointPreselected),
            // store.shapeActive && toJS(store.shapeActive),
            // store.shapeHover && toJS(store.shapeHover),
            // store.shapePreselected && toJS(store.shapePreselected),
          ].filter(Boolean),
        },
      }}
    />
  )
}

export default observer(MapSourceUnclustered)
