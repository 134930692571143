import { css } from "styled-components"
import { rem } from "polished"

const colorizeScrollbar = ({ size = rem(5), color } = {}) => css`
  &::-webkit-scrollbar {
    width: ${size};
    height: ${size};
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => color || theme.colors.lightGray};
    border-radius: ${({ theme }) => theme.br.xsmall};
  }
`

const hideScrollbar = () => css`
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent; /* Chrome/Safari/Webkit */
    display: none;
  }
`

export default { hideScrollbar, colorizeScrollbar }
