import { useEffect, useCallback } from "react"

import useAbortController from "./useAbortController"
import { fetchApiTrafficStop } from "./api"
import { parseEmbedMessage } from "./embedMessage"
import { parseEmbedQuery } from "./query"
import mq from "../theme/mq"

export default function useAppWindow(store) {
  const { signal } = useAbortController()

  const winMessage = useCallback((e) => {
    const { method, data } = parseEmbedMessage(e)

    switch (method) {
      case `initialData`: {
        const { siteLinks } = data
        if (siteLinks) store.setCustomSiteLinks(siteLinks)
        break
      }

      case `setQuery`: {
        const { query } = parseEmbedQuery(data)
        store.setQuery(query)
        break
      }

      case `setTraffic`: {
        store.setTraffic(`trips:loading`)

        const startPromise = !data.startId
          ? null
          : fetchApiTrafficStop({ id: data.startId }, { signal })

        const finishPromise = !data.finishId
          ? null
          : fetchApiTrafficStop({ id: data.finishId }, { signal })

        Promise.all([startPromise, finishPromise])
          .then(([start, finish]) => {
            store.setTrafficStart(start)
            store.setTrafficFinish(finish)

            if (!start || !finish) throw Error()
          })
          .catch(() => store.setTraffic(`form`))
        break
      }

      case `setFullscreen`: {
        store.setFullscreen(data)
        break
      }

      case `setFullscreenIfIsMobile`: {
        if (window.matchMedia(mq.mobileDown).matches) store.setFullscreen(data)
        break
      }

      case `setMapPointActive`: {
        store.setPointActiveId(
          data ? parseInt(data) : 0,
          data && `iframe_post_message`
        )
        break
      }

      case `setMapPointHovered`: {
        store.setPointHoverId(data ? parseInt(data) : 0)
        break
      }
    }
  }, [])

  const winKeyup = useCallback((e) => {
    window.setTimeout(() => {
      if (e.defaultPrevented) return

      if (e.key === `Escape`) {
        if (store.measure) {
          store.setMeasure(false)
        } else if (store.share) {
          store.setShare(false)
        } else if (store.print) {
          store.setPrint(false)
        } else if (store.traffic) {
          store.setTraffic(``)
        } else if (store.pointActiveId) {
          store.setPointActiveId(0)
        } else if (store.fullscreen) {
          store.setFullscreen(false)
        }
      }
    }, 50)
  }, [])

  useEffect(() => {
    window.addEventListener(`keyup`, winKeyup)
    window.addEventListener(`message`, winMessage)

    return () => {
      window.removeEventListener(`keyup`, winKeyup)
      window.removeEventListener(`message`, winMessage)
    }
  })

  return null
}
