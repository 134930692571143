import React, { useEffect } from "react"
import ReactDOM from "react-dom"
import { observer } from "mobx-react-lite"
import { usePopper } from "react-popper"
import styled, { css } from "styled-components"
import { em, rem } from "polished"

import { ReactComponent as SvgCheck } from "../assets/images/icons/check.svg"
import { ReactComponent as SvgWheelchairTested } from "../assets/images/icons/wheelchair-tested.svg"
import { useStore } from "../store"
import useReferredState from "../utils/useReferredState"
import { getCategoryIconSrc } from "../utils/icon"
import { __ } from "../utils/translate"
import { a11yCategories } from "../config/categories"

const wheelchairTestedId = `wheelchair-tested`

const A11ys = ({ referenceEl, onCloseRequest }) => {
  const store = useStore()
  const [containerEl, containerElRef, setContainerEl] = useReferredState(null)
  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceEl,
    containerEl,
    { placement: `bottom-start` }
  )

  const a11ys = [{ id: wheelchairTestedId }]

  a11yCategories.forEach((slug) => {
    const category = store.categories.find((c) => c.slug == slug)
    if (category) a11ys.push(category)
  })

  const a11yClick = (e) => {
    const { id } = e.currentTarget.dataset
    const data = { value: id, type: `a11y` }
    if (store.findInQuery(data)) store.removeFromQuery(data)
    else {
      store.addToQuery(data)
      store.removeFromQuery({ type: `importance` })
    }
  }

  const docClick = (e) => {
    if (
      containerElRef.current &&
      !containerElRef.current.contains(e.target) &&
      !referenceEl.contains(e.target)
    )
      onCloseRequest()
  }

  useEffect(() => {
    document.addEventListener(`click`, docClick, { passive: true })

    return () => {
      document.removeEventListener(`click`, docClick, { passive: true })
    }
  }, [])

  return ReactDOM.createPortal(
    <Container
      ref={setContainerEl}
      style={popperStyles.popper}
      {...popperAttributes.popper}
    >
      <List>
        {a11ys.map((category) => {
          const active = store.findInQuery({
            value: category.id,
            type: `a11y`,
          })

          return (
            <li key={category.id}>
              <Button
                onClick={a11yClick}
                $active={active}
                aria-selected={active}
                data-id={category.id}
              >
                {category.id == wheelchairTestedId ? (
                  <SvgWheelchairTested aria-hidden="true" />
                ) : (
                  <img
                    src={getCategoryIconSrc(category.id, `outline`)}
                    role="presentation"
                    alt=""
                  />
                )}

                <span>
                  {category.id == wheelchairTestedId
                    ? __(`Tested with wheelchair`)
                    : category.label}
                </span>

                <SvgCheck aria-hidden="true" />
              </Button>
            </li>
          )
        })}
      </List>
    </Container>,
    document.querySelector(`body`)
  )
}

export default observer(A11ys)

const Container = styled.div`
  z-index: 999999;
  padding-top: ${rem(10)};
`

const List = styled.ul`
  padding: ${em(12)};
  border: solid ${({ theme }) => theme.colors.aliceBlue};
  border-width: 1px 0 0 1px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.bs.button};
  border-radius: ${({ theme }) => theme.br.normal};
  animation: ${({ theme }) => theme.animations.slideInY(-10)} 0.3s
    ${({ theme }) => theme.easings.outBack};

  > * + * {
    margin-top: ${em(4)};
  }
`

const Button = styled.button.attrs({ type: `button` })`
  ${({ theme }) => theme.fonts.set(`primary`, `medium`)}

  width: 100%;
  padding: ${em(6)} ${em(12)};
  display: flex;
  align-items: baseline;
  gap: ${em(10)};
  font-size: ${em(13)};
  white-space: nowrap;
  border-radius: ${({ theme }) => theme.br.small};

  ${({ $active }) =>
    $active &&
    css`
      background-color: ${({ theme }) => theme.colors.lightGray};
    `}

  &:hover {
    color: ${({ theme }) => theme.colors.cadet};

    img {
      opacity: 0.6;
    }
  }

  img,
  svg:first-child {
    width: ${em(20)};
    flex-shrink: 0;
    position: relative;
    bottom: ${em(-4)};
    filter: grayscale(1) brightness(0);
  }

  svg:first-child {
    height: ${em(20)};
  }

  svg:last-child {
    width: ${em(14)};
    height: ${em(14)};
    margin-left: auto;
    flex-shrink: 0;
    opacity: ${({ $active }) => ($active ? 1 : 0)};
  }
`
