import React, { useEffect, useRef, useState } from "react"
import { observer } from "mobx-react-lite"
import styled, { css } from "styled-components"
import { em, rem, hideVisually } from "polished"
// import turfDistance from "@turf/distance"
// import { point as turfPoint } from "@turf/helpers"

import UnpublishedLabel from "../UnpublishedLabel"
import Tooltip from "../Tooltip"
import Button from "../Button"
import { ReactComponent as SvgStar } from "../../assets/images/icons/star.svg"
import { ReactComponent as SvgLength } from "../../assets/images/icons/length.svg"
import { ReactComponent as SvgClock } from "../../assets/images/icons/clock.svg"
import { useStore } from "../../store"
import { humanizeDurationTime, humanizeDifficulty } from "../../utils/humanize"
import { getCategoryIconSrc } from "../../utils/icon"
import { formatRatingNumber } from "../../utils/number"
import { __ } from "../../utils/translate"
import embedParams from "../../config/embedParams"

const Basic = ({ data }) => {
  const store = useStore()
  const closeRef = useRef(null)
  const categoriesTooltipRef = useRef(null)
  const [categoriesTooltip, setCategoriesTooltip] = useState(``)

  const categories = [
    store.categories.find((c) => c.id == data.mainCategory),
    ...data.categories.map((id) => {
      if (id == data.mainCategory) return null
      const category = store.categories.find((c) => c.id == id)
      return category && category.legacyType != `animal` ? category : null
    }),
  ].filter(Boolean)

  // const locationType = embedParams.guide ? `guide` : `municipality`
  let locationTitle = ``
  if (embedParams.guide) {
    if (data.guides.includes(embedParams.guide))
      locationTitle = (store.findGuide(embedParams.guide) || {}).name
  } else locationTitle = (store.findMunicipality(data.municipality) || {}).name

  // const userDistance = turfDistance(data, turfPoint(USER_LOCATION)) // @LATERDO
  const userDistance = -1
  const anyLocation = !!locationTitle || userDistance > -1
  const anyDetails = !!data.length || !!data.time || data.difficulty

  const showCategoriesTooltip = (e) => {
    categoriesTooltipRef.current = e.currentTarget
    setCategoriesTooltip(e.currentTarget.ariaLabel)
  }

  const hideCategoriesTooltip = () => setCategoriesTooltip(``)

  const trafficClick = () => store.setTraffic(`form`)

  useEffect(() => {
    if (closeRef.current) closeRef.current.focus()
  }, [])

  if (!categories.length && !anyLocation && !anyDetails) return null

  return (
    <Container>
      <Title>{__(`Details`)}</Title>

      {!data.published && (
        <Unpublished>
          <UnpublishedLabel />
        </Unpublished>
      )}

      {/* {anyLocation && (
          <Location>
            {locationTitle && (
              <li title={locationType}>
                <span>{locationType}:</span>

                {locationTitle}
              </li>
            )}

            {userDistance > -1 && (
              <li>{userDistance}</li>
            )}
          </Location>
        )} */}

      {!!categories.length && (
        <Categories>
          {categories.map((category, i) => (
            <li
              key={category.id}
              tabIndex={0}
              aria-label={category.label}
              onFocus={i ? showCategoriesTooltip : undefined}
              onBlur={i ? hideCategoriesTooltip : undefined}
              onMouseEnter={i ? showCategoriesTooltip : undefined}
              onMouseLeave={i ? hideCategoriesTooltip : undefined}
            >
              <img
                src={getCategoryIconSrc(category.id, `main`)}
                role="presentation"
                alt=""
              />

              {!i && <span>{category.label}</span>}
            </li>
          ))}

          {!!categoriesTooltip && (
            <Tooltip referenceEl={categoriesTooltipRef.current}>
              {categoriesTooltip}
            </Tooltip>
          )}
        </Categories>
      )}

      {data.rating && (
        <Rating title={__(`Rating`)}>
          <SvgStar aria-label={__(`Rating`)} />

          <span>{formatRatingNumber(data.rating)}</span>
        </Rating>
      )}

      {anyDetails && (
        <Details>
          {!!data.length && (
            <DetailItem>
              <b>{__(`Trail length`)}</b>

              <SvgLength aria-hidden="true" />

              <span>{data.length} km</span>
            </DetailItem>
          )}

          {!!data.time && (
            <DetailItem title={__(`Duration`)}>
              <b>{__(`Duration`)}</b>

              <SvgClock aria-hidden="true" />

              <span>{humanizeDurationTime(data.time)}</span>
            </DetailItem>
          )}

          {data.difficulty && (
            <DetailItem $difficulty={data.difficulty}>
              <b>{__(`Difficulty`)}</b>

              <span>{humanizeDifficulty(data.difficulty)}</span>
            </DetailItem>
          )}
        </Details>
      )}

      {embedParams.traffic && (
        <Button
          type="button"
          fill="gunmetal"
          color="white"
          size="small"
          block={true}
          onClick={trafficClick}
        >
          {__(`Get here by public transport`)}
        </Button>
      )}
    </Container>
  )
}

export default observer(Basic)

const Container = styled.section`
  padding: 0 ${rem(20)} ${em(10)};
  display: flex;
  flex-direction: column;
  gap: ${em(12)};
`

const Title = styled.h3`
  ${hideVisually()}
`

const Unpublished = styled.div`
  display: flex;
`

const Categories = styled.ul`
  ${({ theme }) => theme.fonts.set(`primary`, `medium`)}

  margin-top: ${em(10)};
  display: flex;
  flex-wrap: wrap;
  column-gap: ${em(10)};

  li {
    font-size: ${em(12)};
    display: flex;
    align-items: baseline;
    gap: ${em(8)};
  }

  img {
    width: ${em(26)};
    flex-shrink: 0;
  }

  span {
    position: relative;
    top: ${em(-8)};
  }
`

// const Location = styled.ul`
//   display: flex;
//   flex-wrap: wrap;
//   font-size: ${em(12)};

//   li {
//     &:not(:last-child)::after {
//       content: "•";
//       margin: 0 ${em(8)};
//     }
//   }

//   span {
//     ${hideVisually()}
//   }
// `

const Rating = styled.p`
  display: flex;
  align-items: center;
  gap: ${em(8)};

  svg {
    width: ${em(18)};
    height: ${em(18)};
    flex-shrink: 0;
  }

  span {
    margin-top: ${em(4)};
    font-size: ${em(12)};
  }
`

const Details = styled.ul`
  padding-top: ${em(12)};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: ${em(16)};
  row-gap: ${em(10)};
  border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
`

const DetailItem = styled.li`
  flex-grow: 1;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${em(8)};

  b {
    grid-column: 1 / span 2;
    font-size: ${em(11)};
    text-transform: uppercase;
  }

  svg {
    width: ${em(20)};
    height: ${em(20)};
    color: ${({ theme }) => theme.colors.darkSlateGray};
  }

  span {
    font-size: ${em(12)};

    ${({ $difficulty }) =>
      $difficulty &&
      css`
        display: flex;
        align-items: baseline;
        gap: ${em(10)};

        &::before {
          content: "";
          width: ${em(12)};
          height: ${em(12)};
          flex-shrink: 0;
          border-radius: 50%;
          background-color: ${({ theme, $difficulty }) =>
            theme.colors[
              $difficulty
                .replace(`green`, `seaGreen`)
                .replace(`blue`, `tealBlue`)
                .replace(`red`, `maximumRed`)
                .replace(`black`, `black`)
            ]};
          border: 1px solid
            ${({ theme, $difficulty }) =>
              $difficulty == `black` ? theme.colors.white : `transparent`};
        }
      `}
  }
`
