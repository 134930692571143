const transparentPlaceholderImg = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=`

const paramizeImgSrc = ({ src, width, height }) => {
  if (!width && !height) return src

  let fixedSrc = ``

  try {
    const url = new URL(src)

    if (width && height) {
      url.searchParams.set(`aspect_ratio`, `${width}:${height}`)
      url.searchParams.set(`width`, width)
    } else if (width) url.searchParams.set(`width`, width)
    else if (height) url.searchParams.set(`height`, height)

    fixedSrc = url.toString()
  } catch {
    //
  }

  return fixedSrc
}

export { transparentPlaceholderImg, paramizeImgSrc }
