import React from "react"
import { Marker } from "react-mapbox-gl"
import { observer } from "mobx-react-lite"
import styled, { keyframes } from "styled-components"
import { rem } from "polished"

import { ReactComponent as SvgTarget } from "../assets/images/icons/target.svg"
import { useStore } from "../store"

const MapLocation = () => {
  const store = useStore()

  if (!store.location.length) return null

  return (
    <Marker coordinates={store.location}>
      <Container>
        <SvgTarget />
      </Container>
    </Marker>
  )
}

export default observer(MapLocation)

const nudgeAnim = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.8); }
  100% { transform: scale(1); }
`

const Container = styled.div`
  width: ${rem(26)};
  height: ${rem(26)};
  padding: ${rem(5)};
  background-color: ${({ theme }) => theme.colors.maximumRed};
  border-radius: 50%;
  animation: ${nudgeAnim} 0.5s 3 ${({ theme }) => theme.easings.default};

  svg {
    width: 100%;
    height: 100%;
    display: block;
    color: ${({ theme }) => theme.colors.white};
  }
`
