import React from "react"
import { observer } from "mobx-react-lite"
import styled, { css } from "styled-components"
import { em, ellipsis } from "polished"

import { ReactComponent as SvgClose } from "../assets/images/icons/close.svg"
import { ReactComponent as SvgMinimize } from "../assets/images/icons/minimize.svg"
import { useStore } from "../store"
import embedParams from "../config/embedParams"
import { __ } from "../utils/translate"

const Strict = ({ aboveSearch = false }) => {
  const store = useStore()

  const fullscreenExitClick = () => {
    store.setFullscreen(false)
  }

  const exitClick = () => {
    let b = store.map.getBounds()
    b = [b._ne.lng, b._ne.lat, b._sw.lng, b._sw.lat]

    window.top.location = `${embedParams.naturkartanBase}/${
      embedParams.language
    }/explore?bounds=${b.join(`,`)}`
  }

  if (!store.strictInfo) return null

  return (
    <Container $aboveSearch={aboveSearch}>
      <Info>
        <Title>{store.strictInfo.title}</Title>

        <Type>{__(`strict-type::${store.strictInfo.type}`)}</Type>
      </Info>

      {embedParams.strictExit && store.fullscreen && (
        <Exit
          onClick={exitClick}
          title={__(`See all places, areas and trails in this area`)}
          $strict={true}
        >
          <SvgClose
            aria-label={__(`See all places, areas and trails in this area`)}
          />
        </Exit>
      )}

      <Exit
        onClick={fullscreenExitClick}
        title={__(`Exit fullscreen mode`)}
        $fullscreen={true}
      >
        <SvgMinimize aria-label={__(`Exit fullscreen mode`)} />
      </Exit>
    </Container>
  )
}

export default observer(Strict)

const Container = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.darkSlateGray};
  border-radius: ${({ theme }) => theme.br.normal};

  ${({ $aboveSearch }) =>
    $aboveSearch &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}

  @media ${({ theme }) => theme.mq.mobileDown} {
    background-color: ${({ theme }) => theme.colors.gunmetal};
  }
`

const Info = styled.div`
  overflow: hidden;
  align-self: center;
  flex-grow: 1;
  padding: ${em(8)} ${em(24)};
  padding-right: 0;
`

const Title = styled.h2`
  ${ellipsis(`100%`)}
  ${({ theme }) => theme.fonts.set(`primary`, `medium`)}

  display: block;
  font-size: ${em(12)};
`

const Type = styled.p`
  ${ellipsis(`100%`)}

  font-size: ${em(11)};
  display: block;
`

const Exit = styled.button.attrs({ type: `button` })`
  width: ${em(50)};
  flex-shrink: 0;

  @media ${({ theme }) => theme.mq.mobileUp} {
    ${({ $fullscreen }) =>
      $fullscreen &&
      css`
        display: none;
      `}
  }

  & + & {
    border-left: 1px solid ${({ theme }) => theme.colors.pewterBlue};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.lightGray};
  }

  &:active {
    opacity: 0.8;
  }

  svg {
    width: 100%;
    height: 100%;
    padding: 26%;
    display: block;

    ${({ $strict }) =>
      $strict &&
      css`
        padding: 32%;
      `}
  }
`
