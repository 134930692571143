import React from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { em, rem } from "polished"

import ElevationsComponent from "../Elevations/Index"
import { useStore } from "../../store"

const Elevations = () => {
  const store = useStore()

  if (!store.pointElevationsAvailable) return null

  return (
    <Container>
      <ElevationsComponent inSite={true} />
    </Container>
  )
}

export default observer(Elevations)

const Container = styled.div`
  height: ${em(180)};
  padding: ${em(10)} ${rem(20)};

  @media ${({ theme }) => theme.mq.xxsmallDown} {
    padding-left: ${rem(10)};
    padding-right: ${rem(10)};
  }
`
