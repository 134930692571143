import React, { useCallback } from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { em, rem, rgba, hideVisually } from "polished"

import Trippy from "./Trippy"
import TripLegs from "./TripLegs"
import Button from "../Button"
import { ReactComponent as SvgArrowLeft } from "../../assets/images/icons/arrow-left.svg"
import { ReactComponent as SvgChevronLeft } from "../../assets/images/icons/chevron-left.svg"
import { ReactComponent as SvgChevronRight } from "../../assets/images/icons/chevron-right.svg"
import { useStore } from "../../store"
import { __ } from "../../utils/translate"

const Trip = () => {
  const store = useStore()

  const index = store.trafficTrips.findIndex(
    (t) => t.id == store.trafficTripActiveId
  )
  const hasPrev = index > 0
  const hasNext = index < store.trafficTrips.length - 1

  const backClick = () => store.setTrafficTripActiveId(0)

  const prevClick = useCallback(() => {
    if (hasPrev) store.setTrafficTripActiveId(store.trafficTrips[index - 1].id)
  }, [store.trafficTripActiveId])

  const nextClick = useCallback(() => {
    if (hasNext) store.setTrafficTripActiveId(store.trafficTrips[index + 1].id)
  }, [store.trafficTripActiveId])

  const navItemClick = (e) =>
    store.setTrafficTripActiveId(e.currentTarget.dataset.id)

  return (
    <Container>
      <Button
        type="button"
        color="darkSlateGray"
        size="small"
        icon={<SvgArrowLeft />}
        onClick={backClick}
      >
        {__(`See other options`)}
      </Button>

      <Header>
        <Trippy trip={store.trafficTripActive} />

        {store.trafficTrips.length > 1 && (
          <Nav>
            <Button
              type="button"
              title={__(`Previous route`)}
              size="small"
              color="darkSlateGray"
              icon={<SvgChevronLeft />}
              onClick={hasPrev ? prevClick : undefined}
              disabled={!hasPrev}
            />

            <ul>
              {store.trafficTrips.map((trip, i) => (
                <li key={trip.id}>
                  <button
                    type="button"
                    title={`${__(`Route`)} ${i + 1}`}
                    data-id={trip.id}
                    aria-current={
                      trip.id == store.trafficTripActiveId ? `step` : undefined
                    }
                    onClick={navItemClick}
                  >
                    <span>
                      {__(`Route`)} {i + 1}
                    </span>
                  </button>
                </li>
              ))}
            </ul>

            <Button
              type="button"
              title={__(`Next route`)}
              size="small"
              color="darkSlateGray"
              icon={<SvgChevronRight />}
              onClick={hasNext ? nextClick : undefined}
              disabled={!hasNext}
            />
          </Nav>
        )}
      </Header>

      <Scroller>
        {/* <Toolbar></Toolbar> */}

        <TripLegs legs={store.trafficTripActive.legs} />
      </Scroller>
    </Container>
  )
}

export default observer(Trip)

const Container = styled.article`
  min-height: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const Header = styled.header`
  margin-top: ${em(4)};
  padding: ${em(10)} 0;
  position: relative;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.khakiWeb};
  box-shadow: 0 ${rem(2)} ${rem(2)}
    ${({ theme }) => rgba(theme.colors.black, 0.125)};
`

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${em(10)};

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1px;

    li {
      display: flex;
      align-items: center;
    }

    button {
      width: ${em(10)};
      height: ${em(10)};
      position: relative;
      color: ${({ theme }) => theme.colors.eagleGreen};

      &:hover {
        opacity: 0.6;
      }

      &[aria-current="step"] {
        font-size: ${em(26)};
        color: ${({ theme }) => theme.colors.seaGreen};
      }

      &::after {
        content: "";
        width: ${em(4)};
        height: ${em(4)};
        background-color: currentColor;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
      }
    }

    span {
      ${hideVisually()}
    }
  }
`

const Scroller = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-color: ${({ theme }) => theme.colors.white};

  &::-webkit-scrollbar {
    width: ${rem(5)};
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gunmetal};
    border-radius: ${({ theme }) => theme.br.small};
  }
`
