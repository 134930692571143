import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import styled, { css } from "styled-components"
import { em, rem, hideVisually, ellipsis } from "polished"
// import turfLength from "@turf/length"
// import turfDistance from "@turf/distance"
// import { point as turfPoint } from "@turf/helpers"

import UnpublishedLabel from "./UnpublishedLabel"
import SiteTrailStatus from "./SiteTrailStatus"
import ExpandableText from "./ExpandableText"
import { ReactComponent as SvgStar } from "../assets/images/icons/star.svg"
import { ReactComponent as SvgLength } from "../assets/images/icons/length.svg"
import { ReactComponent as SvgClock } from "../assets/images/icons/clock.svg"
import { useStore } from "../store"
import embedParams from "../config/embedParams"
import { humanizeDurationTime, humanizeDifficulty } from "../utils/humanize"
import { paramizeImgSrc } from "../utils/img"
import { getCategoryIconSrc } from "../utils/icon"
import { formatRatingNumber } from "../utils/number"
import { __ } from "../utils/translate"
import mq from "../theme/mq"

const SiteCard = ({
  data,
  pointActivationTrigger,
  highlightActive = false,
  showInMiniguideDescription = false,
}) => {
  const { properties } = data

  const store = useStore()
  const [showTrailStatus, setShowTrailStatus] = useState(false)

  const click = (e) => {
    e.preventDefault()

    if (window.matchMedia(mq.mobileDown).matches) {
      store.setFullscreen(true).then(() => {
        store.setPointActiveId(data.id, pointActivationTrigger)
        store.setDisplaySite(true)
      })
    } else {
      store.setPointActiveId(data.id, pointActivationTrigger)
      store.setDisplaySite(true)
    }
  }

  const mouseEnter = () => {
    store.setPointHoverId(data.id)
  }

  const mouseLeave = () => {
    if (store.pointHoverId == data.id) store.setPointHoverId(0)
  }

  // const trailLength = useMemo(() => {
  //   if (properties.type != `trail`) return 0

  //   const shape = store.findShapeByParent(data.id)

  //   return shape
  //     ? parseFloat(
  //         turfLength(toJS(shape), {
  //           units: `kilometers`,
  //         })
  //       ).toFixed(1)
  //     : 0
  // }, [store.shapes])

  let image = null
  let video = null
  if (properties.image) {
    if (properties.image.match(/\.(mp4|mov)$/i)) video = properties.image
    else
      image = paramizeImgSrc({ src: properties.image, width: 150, height: 200 })
  }

  const anyDetails =
    !!properties.length || !!properties.time || !!properties.difficulty

  // const locationType = embedParams.guide ? `guide` : `municipality`
  // let locationTitle = ``
  // if (embedParams.guide) {
  //   if (guides.includes(embedParams.guide))
  //     locationTitle = (store.findGuide(embedParams.guide) || {}).name
  // } else locationTitle = (store.findMunicipality(municipality) || {}).name

  // const userDistance = turfDistance(data, turfPoint(USER_LOCATION)) // @LATERDO
  // const userDistance = -1
  // const anyLocation = !!locationTitle || userDistance > -1
  // const anyLocation = userDistance > -1

  const categoryName =
    store.categories.find((c) => c.id == properties.mainCategory)?.label || ``

  let descriptionInMiniguide = ``
  if (
    [`trip`, `list`].includes(embedParams.strict) &&
    showInMiniguideDescription
  ) {
    const miniguide = (
      embedParams.strict == `trip` ? store.trips : store.lists
    )[0]
    if (miniguide) {
      const item = miniguide.items.find((i) => i.siteId == data.id)
      if (item) descriptionInMiniguide = item.description
    }
  }

  useEffect(() => {
    setShowTrailStatus(false)

    if (properties.trailStatusReportedAt) {
      if (!properties.organization) setShowTrailStatus(true)
      else {
        store
          .getOrganization(properties.organization)
          .then((o) => o?.trailStatusReportEnabled && setShowTrailStatus(true))
          .catch(() => null)
      }
    }
  }, [data.id])

  return (
    <Container>
      {!properties.published && <UnpublishedLabel prepended={true} />}

      <Box
        $active={store.pointActiveId == data.id && highlightActive}
        $hovered={store.pointHoverId == data.id && highlightActive}
        $unpublished={!properties.published}
        onClick={click}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
        data-menu-point-id={data.id}
      >
        <Image>
          {image && <img src={image} alt={properties.title} />}

          {video && <video src={video} preload="metadata" />}
        </Image>

        <Info>
          <Title>
            <button type="button">
              <span>{properties.title}</span>
            </button>
          </Title>

          <Details>
            {properties.mainCategory && (
              <Category title={__(`Category`)}>
                <img
                  src={getCategoryIconSrc(
                    properties.assetsCategoryIcon,
                    `main`
                  )}
                  role="presentation"
                  alt=""
                />

                <span>{categoryName}</span>
              </Category>
            )}

            {properties.rating && (
              <Detail title={__(`Rating`)}>
                <SvgStar aria-label={__(`Rating`)} />

                <span>{formatRatingNumber(properties.rating)}</span>
              </Detail>
            )}
          </Details>

          {/* {anyLocation && (
            <Location>
              {locationTitle && (
                <p title={__(`site.${locationType}`)}>
                  <i>{__(`site.${locationType}`)}:</i>

                  {locationTitle}
                </p>
            )}

              {userDistance > -1 && (
                {(t) => <p>{__(`site.distance_from_you`).replace(`{{distance}}`, userDistance)}</p>}
              )}
            </Location>
          )} */}

          {anyDetails && (
            <Details>
              {!!properties.length && (
                <Detail title={__(`Trail length`)}>
                  <SvgLength aria-label={__(`Trail length`)} />

                  <span>{properties.length} km</span>
                </Detail>
              )}

              {!!properties.time && (
                <Detail title={__(`Duration`)}>
                  <SvgClock aria-label={__(`Duration`)} />

                  <span>{humanizeDurationTime(properties.time)}</span>
                </Detail>
              )}

              {properties.difficulty && (
                <Difficulty
                  $difficulty={properties.difficulty}
                  title={__(`Difficulty`)}
                >
                  <i>{__(`Difficulty`)}</i>

                  <span>{humanizeDifficulty(properties.difficulty)}</span>
                </Difficulty>
              )}
            </Details>
          )}
        </Info>
      </Box>

      {showTrailStatus && (
        <TrailStatus>
          <SiteTrailStatus reportedAt={properties.trailStatusReportedAt} />
        </TrailStatus>
      )}

      {descriptionInMiniguide && (
        <DescriptionInMiniguide>
          <ExpandableText text={descriptionInMiniguide} />
        </DescriptionInMiniguide>
      )}
    </Container>
  )
}

export default observer(SiteCard)

const Container = styled.article``

const Box = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.gunmetal};
  border-radius: ${({ theme }) => theme.br.normal};
  box-shadow: 0 ${rem(2)} ${rem(4)} rgba(0, 0, 0, 0.5);
  cursor: pointer;

  ${({ $hovered }) =>
    $hovered &&
    css`
      background-color: ${({ theme }) => theme.colors.eagleGreen};
    `}

  ${({ $active }) =>
    $active &&
    css`
      outline: 3px solid ${({ theme }) => theme.colors.seaGreen};
    `}

  ${({ $unpublished }) =>
    $unpublished &&
    css`
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `}
`

const Image = styled.figure`
  width: ${em(90)};
  flex-shrink: 0;
  position: relative;
  background-color: ${(props) => props.theme.colors.cadet};

  @media ${({ theme }) => theme.mq.xxsmallDown} {
    display: none;
  }

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }
`

const Info = styled.div`
  padding: ${em(14)} ${em(16)};
  flex-grow: 1;
`

const Title = styled.h2`
  ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

  font-size: ${em(15)};

  span {
    ${ellipsis(`100%`, 2)}
  }
`

const Details = styled.div`
  margin-top: ${em(10)};
  display: flex;
  flex-wrap: wrap;
  column-gap: ${em(16)};
  row-gap: ${em(10)};
`

const Category = styled.p`
  display: flex;
  gap: ${em(8)};
  align-items: flex-start;

  img {
    width: ${em(18)};
    flex-shrink: 0;
  }

  span {
    font-size: ${em(12)};
  }
`

const Detail = styled.p`
  display: flex;
  align-items: center;
  gap: ${em(8)};

  svg {
    width: ${em(18)};
    height: ${em(18)};
    flex-shrink: 0;
  }

  span {
    font-size: ${em(12)};
  }
`

// const Location = styled.div`
//   margin-top: ${em(10)};
//   display: flex;
//   flex-wrap: wrap;
//   font-size: ${em(12)};

//   li {
//     &:not(:last-child)::after {
//       content: "•";
//       margin: 0 ${em(8)};
//     }
//   }

//   i {
//     ${hideVisually()}
//   }
// `

const Difficulty = styled.p`
  display: flex;
  align-items: center;
  gap: ${em(8)};

  &::before {
    content: "";
    width: ${em(8)};
    height: ${em(8)};
    border-radius: 50%;
    background-color: ${({ theme, $difficulty }) =>
      theme.colors[
        $difficulty
          .replace(`green`, `seaGreen`)
          .replace(`blue`, `tealBlue`)
          .replace(`red`, `maximumRed`)
          .replace(`black`, `black`)
      ]};
    border: 1px solid
      ${({ theme, $difficulty }) =>
        $difficulty == `black` ? theme.colors.white : `transparent`};
  }

  i {
    ${hideVisually()}
  }

  span {
    font-size: ${em(12)};
  }
`

const TrailStatus = styled.div`
  margin-top: ${em(-16)};
  position: relative;
  z-index: 2;

  > * {
    padding: ${em(30)} ${em(16)} ${em(10)};
  }
`

const DescriptionInMiniguide = styled.aside`
  margin-top: ${em(-16)};
  padding: ${em(20)};
  padding-top: ${em(36)};
  position: relative;
  z-index: 1;
  /* font-size: ${em(12)}; */
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: ${({ theme }) => theme.br.normal};
  box-shadow: ${rem(2)} ${rem(2)} ${rem(4)} rgba(0, 0, 0, 0.25);
`
