import React, { useMemo, useCallback } from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { em, rem, hideVisually, ellipsis } from "polished"
import { DateTime } from "luxon"

import Trippy from "./Trippy"
import Button from "../Button"
import { ReactComponent as SvgPencil } from "../../assets/images/icons/pencil.svg"
import { ReactComponent as SvgRefresh } from "../../assets/images/icons/refresh.svg"
import { useStore } from "../../store"
import { __ } from "../../utils/translate"

const Trips = () => {
  const store = useStore()
  const isLoading = store.traffic == `trips:loading`

  const data = useMemo(() => {
    const data = []
    let date = null

    store.trafficTrips.forEach((trip) => {
      const newDate = DateTime.fromISO(trip.startDate).toFormat(`yyyy-LL-dd`)

      if (date != newDate) {
        date = newDate
        data.push({ date })
      }

      data.push({ trip })
    })

    return data
  }, [store.trafficTrips])

  const startChoices = useMemo(() => {
    if (store.trafficTrips.length) return []
    return store.trafficChoices.filter((c) => c._choice == `start`)
  }, [store.trafficChoices])

  const refreshClick = () => store.setTraffic(`trips:loading`)

  const editClick = () => store.setTraffic(`form`)

  const choiceClick = useCallback(
    (e) => {
      const stop = startChoices.find((c) => c.id == e.currentTarget.dataset.id)
      if (stop) store.setTrafficStart(stop)
    },
    [startChoices]
  )

  const trippyClick = (e) =>
    store.setTrafficTripActiveId(e.currentTarget.dataset.id)

  return (
    <Container aria-busy={isLoading}>
      <Header>
        <h3>
          <b>{store.trafficStart?.name || (isLoading && <i>&nbsp;</i>)}</b>
          <span>—</span>
          <b>{store.trafficFinish?.name || (isLoading && <i>&nbsp;</i>)}</b>
        </h3>

        <ul aria-label={__(`Actions`)}>
          {!isLoading && (
            <li>
              <Button
                onClick={editClick}
                icon={<SvgPencil />}
                type="button"
                title={__(`Change stops`)}
                color="darkSlateGray"
                size="xsmall"
              />
            </li>
          )}

          <li>
            <Button
              onClick={refreshClick}
              icon={<SvgRefresh />}
              type="button"
              title={__(`Refresh`)}
              color="darkSlateGray"
              size="xsmall"
              loading={isLoading}
              disabled={isLoading}
            />
          </li>
        </ul>
      </Header>

      <Scroller>
        {isLoading && (
          <Loader aria-label={__(`Loading`)}>
            {Array.from({ length: 3 }).map((_, i) => (
              <div key={i} />
            ))}
          </Loader>
        )}

        {!isLoading && !store.trafficTrips.length && (
          <Empty>
            <p>
              {__(`There are no routes available between selected stops`)}.{` `}
              {!!startChoices.length &&
                __(`You can try selecting a different departure point`)}
            </p>

            {!!startChoices.length && (
              <ul>
                {startChoices.map((choice) => (
                  <li key={choice.id}>
                    <button
                      type="button"
                      onClick={choiceClick}
                      data-id={choice.id}
                    >
                      {choice.name}
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </Empty>
        )}

        {!isLoading &&
          data.map((group) =>
            group.trip ? (
              <TrippyWrap key={group.trip.id}>
                <Trippy trip={group.trip} onClick={trippyClick} />
              </TrippyWrap>
            ) : (
              <Date key={group.date} dateTime={group.date}>
                {group.date}
              </Date>
            )
          )}
      </Scroller>
    </Container>
  )
}

export default observer(Trips)

const Container = styled.section`
  min-height: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const Header = styled.header`
  width: 100%;
  padding: 0 ${rem(20)} ${em(14)} ${rem(20)};
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.khakiWeb};

  @media ${({ theme }) => theme.mq.xxsmallDown} {
    padding-left: ${rem(10)};
    padding-right: ${rem(10)};
  }

  h3 {
    min-width: 0;
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: ${em(6)};

    &::before {
      content: "";
      width: 1px;
      position: absolute;
      top: ${em(14)};
      bottom: ${em(16)};
      left: ${em(3)};
      background-color: ${({ theme }) => theme.colors.eagleGreen};
    }

    b {
      /* text */
      ${({ theme }) => theme.fonts.set(`primary`, `medium`)}
      ${ellipsis(`100%`)}

      padding-left: ${em(20)};
      position: relative;
      font-size: ${em(12)};

      &::before {
        content: "";
        width: ${em(9)};
        height: ${em(9)};
        position: absolute;
        top: ${em(7)};
        left: 0;
        border-radius: 50%;
      }

      &:first-child::before {
        background-color: ${({ theme }) => theme.colors.seaGreen};
      }

      &:last-child::before {
        background-color: ${({ theme }) => theme.colors.maximumPurple};
      }
    }

    span {
      /* dash */
      ${hideVisually()}
    }

    i {
      /* loader */
      width: 100%;
      max-width: ${em(130)};
      display: inline-block;
      border-radius: ${({ theme }) => theme.br.xsmall};
      background-color: ${({ theme }) => theme.colors.khakiWeb};
      animation: ${({ theme }) => theme.animations.fadeBlink} 1s infinite linear;
    }
  }

  ul {
    display: flex;
    align-items: center;
    gap: 1px;
  }
`

const Scroller = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: ${rem(5)};
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gunmetal};
    border-radius: ${({ theme }) => theme.br.small};
  }
`

const Loader = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${em(8)};

  div {
    height: ${em(66)};
    background-color: ${({ theme }) => theme.colors.khakiWeb};
    animation: ${({ theme }) => theme.animations.fadeBlink} 1s infinite linear;
  }
`

const Empty = styled.div`
  padding: ${em(20)} ${rem(20)};
  font-size: ${em(13)};
  color: ${({ theme }) => theme.colors.eagleGreen};

  @media ${({ theme }) => theme.mq.xxsmallDown} {
    padding-left: ${rem(10)};
    padding-right: ${rem(10)};
  }

  > * + * {
    margin-top: ${em(10)};
  }

  ul {
    list-style-type: disc;
    padding-left: ${em(16)};

    li:not(:last-child) {
      margin-top: ${em(6)};
    }
  }

  button {
    text-decoration-line: underline;

    &:hover {
      color: ${({ theme }) => theme.colors.seaGreen};
    }

    &:active {
      opacity: 0.6;
    }
  }
`

const Date = styled.time`
  padding: ${em(6)} ${rem(20)};
  display: block;
  font-size: ${em(12)};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.eagleGreen};

  @media ${({ theme }) => theme.mq.xxsmallDown} {
    padding: ${em(3)} ${rem(10)};
  }
`

const TrippyWrap = styled.div`
  & + & {
    border-top: 1px solid ${({ theme }) => theme.colors.khakiWeb};
  }
`
