import React from "react"
import styled from "styled-components"
import { em, rem } from "polished"

import { __ } from "../../utils/translate"
import { humanizeLinkType } from "../../utils/humanize"
import embedParams from "../../config/embedParams"

const Links = ({ data }) => {
  if (embedParams.client == `stf` || !data?.links?.length) return null

  const links = data.links.filter((l) => l.type != `partner`)

  if (!links) return null

  return (
    <Container>
      <Title>{__(`Links`)}</Title>

      <List>
        {links.map((link) => (
          <li key={link.id}>
            <a href={link.url} target="_blank" rel="noopener noreferrer">
              {link.title || humanizeLinkType(link.type)}
            </a>
          </li>
        ))}
      </List>
    </Container>
  )
}

export default Links

const Container = styled.section`
  margin: ${em(10)} ${rem(20)};
  padding: ${em(10)} 0;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGray};

  @media ${({ theme }) => theme.mq.xxsmallDown} {
    margin-left: ${rem(10)};
    margin-right: ${rem(10)};
  }
`

const Title = styled.h3`
  ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

  margin-bottom: ${em(10)};
  font-size: ${em(15)};
  letter-spacing: ${em(-0.025)};
`

const List = styled.ul`
  font-size: ${em(13)};
  padding-left: ${em(16)};
  list-style-type: disc;

  li {
    margin-top: ${em(10)};
  }

  a {
    text-decoration-line: underline;

    &:hover {
      color: ${({ theme }) => theme.colors.cadet};
    }

    &:active {
      opacity: 0.8;
    }
  }
`
