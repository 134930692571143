import en from "./en.yml"
import sv from "./sv.yml"
import nl from "./nl.yml"
import de from "./de.yml"
import pl from "./pl.yml"

const translations = {
  en,
  sv,
  nl,
  de,
  pl,
}

const languages = Object.keys(translations)
const defaultLanguage = languages.includes(`sv`) ? `sv` : languages[0]

export { translations as default, languages, defaultLanguage }
