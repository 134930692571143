import React from "react"
import styled, { css } from "styled-components"
import { em } from "polished"

const Button = ({
  children,
  title,
  icon,
  loading,
  size,
  block,
  nowrap,
  outline,
  outlineStrong,
  color,
  fill,
  boxShadow,
  innerRef,
  ...rest
}) => {
  rest.disabled = rest.disabled ? true : undefined
  const iconOnly = !children && !!icon

  return (
    <Container
      as={rest.href ? `a` : `button`}
      ref={innerRef}
      $iconOnly={iconOnly}
      $loading={loading}
      $size={size}
      $block={block}
      $nowrap={nowrap}
      $outline={outline}
      $outlineStrong={outlineStrong}
      $color={color}
      $fill={fill}
      $boxShadow={boxShadow}
      title={title}
      aria-label={!children ? title : undefined}
      {...rest}
    >
      {loading && (
        <SvgLoading
          width="40"
          height="40"
          viewBox="0 0 40 40"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd">
            <path d="M20,40A20,20,0,1,1,40,20">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 20 20"
                to="360 20 20"
                dur="1s"
                repeatCount="indefinite"
              />
            </path>
          </g>
        </SvgLoading>
      )}

      {icon && !loading && <Icon aria-hidden="true">{icon}</Icon>}

      {children && <Text>{children}</Text>}
    </Container>
  )
}

export default Button

const SvgLoading = styled.svg`
  width: ${em(20)};
  height: ${em(20)};
  position: relative;
  bottom: ${em(-3)};
  flex-shrink: 0;

  g {
    transform: scale(0.9);
    transform-origin: center center;
  }

  path {
    stroke: currentColor;
    stroke-width: 4;
  }
`

const Icon = styled.span`
  svg {
    width: ${em(24)};
    height: ${em(24)};
    display: block;
  }
`

const Text = styled.span``

const Container = styled.button`
  ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

  font-size: ${em(14)};
  padding: ${em(8)} ${em(22)};
  display: inline-flex;
  gap: ${em(14)};
  border: 1px solid transparent;
  border-radius: ${({ theme }) => theme.br.normal};

  &:hover:not([disabled]) {
    opacity: 0.8;
  }

  &:active:not([disabled]) {
    opacity: 0.5;
  }

  &[disabled] {
    &,
    &:hover {
      opacity: ${({ $loading }) => !$loading && 0.4};
      cursor: not-allowed;
    }
  }

  ${({ $iconOnly }) =>
    $iconOnly &&
    css`
      padding: ${em(12)};
      border-radius: 50%;
    `}

  ${({ $size }) =>
    $size == `small` &&
    css`
      font-size: ${em(12)};
    `}

  ${({ $size }) =>
    $size == `xsmall` &&
    css`
      font-size: ${em(10)};
    `}

  ${({ $block }) =>
    $block &&
    css`
      width: 100%;
      display: flex;
      justify-content: center;
    `}

  ${({ $nowrap }) =>
    $nowrap &&
    css`
      min-width: 0;

      ${Text} {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    `}

  ${({ $color }) =>
    $color &&
    css`
      color: ${({ theme }) => theme.colors[$color]};
    `}

  ${({ $fill }) =>
    $fill &&
    css`
      background-color: ${({ theme }) => theme.colors[$fill]};
    `}

  ${({ $outline }) =>
    $outline &&
    css`
      color: ${({ theme }) => theme.colors[$outline]};
      border-color: ${({ theme }) => theme.colors[$outline]};
    `}

  ${({ $outlineStrong }) =>
    $outlineStrong &&
    css`
      border-width: 2px;
    `}

  ${({ $boxShadow }) =>
    $boxShadow &&
    css`
      box-shadow: ${({ theme }) => theme.bs.button};
    `}
`
