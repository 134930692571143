import React, { memo } from "react"
import styled from "styled-components"
import { em, rem } from "polished"
import { DateTime, Duration } from "luxon"

import { ReactComponent as SvgHighSpeedTrain } from "../../assets/images/icons/high-speed-train.svg"
import { ReactComponent as SvgTrain } from "../../assets/images/icons/train.svg"
import { ReactComponent as SvgBus } from "../../assets/images/icons/bus.svg"
import { ReactComponent as SvgSubway } from "../../assets/images/icons/subway.svg"
import { ReactComponent as SvgTram } from "../../assets/images/icons/tram.svg"
import { ReactComponent as SvgFerry } from "../../assets/images/icons/ferry.svg"
import { ReactComponent as SvgCar } from "../../assets/images/icons/car.svg"
import { ReactComponent as SvgWalk } from "../../assets/images/icons/walk.svg"
import { ReactComponent as SvgBicycle } from "../../assets/images/icons/bicycle.svg"
import colors from "../../theme/colors"

const getLegIconAndColor = (leg) => {
  let Icon = null
  let color = ``

  switch (leg.categoryCode) {
    case `1`: {
      Icon = SvgHighSpeedTrain
      color = `fuchsiaRose`
      break
    }
    case `2`: {
      Icon = SvgTrain
      color = `fuchsiaRose`
      break
    }
    case `3`: {
      Icon = SvgBus
      color = `darkSlateGray`
      break
    }
    case `4`: {
      Icon = SvgTrain
      color = `fuchsiaRose`
      break
    }
    case `5`: {
      Icon = SvgSubway
      color = `indigoDye`
      break
    }
    case `6`: {
      Icon = SvgTram
      color = `indigoDye`
      break
    }
    case `7`: {
      Icon = SvgBus
      color = `darkSlateGray`
      break
    }
    case `8`: {
      Icon = SvgFerry
      color = `russet`
      break
    }
    case `9`: {
      Icon = SvgCar
      color = `gunmetal`
      break
    }
    default: {
      color = `seaGreen`

      switch (leg.type) {
        case `WALK`: {
          Icon = SvgWalk
          break
        }
        case `TRSF`: {
          Icon = SvgWalk
          break
        }
        case `BIKE`: {
          Icon = SvgBicycle
          break
        }
      }
    }
  }

  return {
    icon: Icon ? <Icon aria-hidden="true" /> : null,
    color: colors[color],
  }
}

const Trippy = memo(({ trip, onClick }) => {
  const startDate = DateTime.fromISO(trip.startDate)
  const finishDate = DateTime.fromISO(trip.finishDate)
  let totalDuration = 0

  const legs = trip.legs
    .map((leg) => {
      const duration = Duration.fromISO(leg.duration).as(`minutes`)
      totalDuration += duration
      return {
        ...leg,
        duration,
      }
    })
    .map((leg) => ({
      ...leg,
      durationShare: (leg.duration * 100) / totalDuration,
    }))

  return (
    <Container
      as={onClick ? `article` : `div`}
      role={onClick ? `button` : undefined}
      tabIndex={onClick ? 0 : undefined}
      onClick={onClick}
      data-id={trip.id}
    >
      <time dateTime={trip.startDate} title={startDate.toFormat(`yyyy-LL-dd`)}>
        {startDate.toFormat(`HH:mm`)}
      </time>

      <ul>
        {legs.map((leg) => {
          const { icon, color } = getLegIconAndColor(leg)

          return (
            <li
              key={leg.id}
              style={{ flexBasis: `${leg.durationShare}%`, "--color": color }}
              title={leg.name}
              aria-label={leg.name}
            >
              {icon}

              {leg.displayNumber && <b>{leg.displayNumber}</b>}
            </li>
          )
        })}
      </ul>

      <time
        dateTime={trip.finishDate}
        title={finishDate.toFormat(`yyyy-LL-dd`)}
      >
        {finishDate.toFormat(`HH:mm`)}
      </time>
    </Container>
  )
})

export { Trippy as default, getLegIconAndColor }

const Container = styled.article`
  width: 100%;
  padding: ${em(12)} ${rem(20)};
  display: flex;

  @media ${({ theme }) => theme.mq.xxsmallDown} {
    padding: ${em(10)} ${rem(10)};
  }

  &[role="button"] {
    cursor: pointer;
    outline-offset: ${em(-6)};

    &:hover {
      background-color: ${({ theme }) => theme.colors.khakiWeb};
    }
  }

  time {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

    font-size: ${em(11)};
  }

  ul {
    ${({ theme }) => theme.mixins.hideScrollbar()}

    height: ${em(42)};
    padding: 0 ${em(10)};
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    flex-grow: 1;
    display: flex;
    gap: 1px;
    mask: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) ${em(10)},
      rgba(0, 0, 0, 1) calc(100% - ${em(10)}),
      rgba(0, 0, 0, 0) 100%
    );

    li {
      /* min-width: 0; */
      padding: 0 ${em(2)};
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      position: relative;

      &::before {
        content: "";
        height: 1px;
        position: absolute;
        z-index: 1;
        top: ${em(21)};
        left: 0;
        right: 0;
        background-color: var(--color);
      }
    }

    svg {
      width: ${em(18)};
      height: ${em(18)};
      margin: 0 auto;
      color: var(--color);
    }

    b {
      margin-top: auto;
      padding: ${em(1)} ${em(6)};
      display: inline-block;
      font-size: ${em(8)};
      line-height: 1.5;
      white-space: nowrap;
      border-radius: ${({ theme }) => theme.br.xsmall};
      color: ${({ theme }) => theme.colors.white};
      background-color: var(--color);
    }
  }
`
