import React from "react"
import { Layer } from "react-mapbox-gl"

import colors from "../theme/colors"

const layerConfig = {
  sourceId: `geojson-points`,
  type: `symbol`,
  filter: [`!`, [`has`, `point_count`]],
}

const MapPoints = () => {
  return (
    <>
      {/* points */}

      <Layer
        {...layerConfig}
        id="points"
        layout={{
          "icon-allow-overlap": true,
          "icon-image": `{mapIcon}`,
          "icon-anchor": `bottom`,
          "icon-size": 0.55,
          "symbol-z-order": `source`,
        }}
        // paint={{
        //   "icon-opacity": [
        //     `case`,
        //     [
        //       `any`,
        //       [`boolean`, [`feature-state`, `active`], false],
        //       [`boolean`, [`feature-state`, `hover`], false],
        //     ],
        //     0,
        //     1,
        //   ],
        // }}
      />

      {/* <Layer
        {...layerConfig}
        layout={{
          "icon-allow-overlap": true,
          "icon-image": `{mapIconActive}`,
          "icon-anchor": `bottom`,
          "icon-size": 0.55,
        }}
        paint={{
          "icon-opacity": [
            `case`,
            [
              `any`,
              [`boolean`, [`feature-state`, `active`], false],
              [`boolean`, [`feature-state`, `hover`], false],
            ],
            1,
            0,
          ],
        }}
      /> */}

      {/* clusters */}

      <Layer
        {...layerConfig}
        id="points-cluster"
        type="circle"
        filter={[`has`, `point_count`]}
        paint={{
          "circle-color": [
            `step`,
            [`get`, `point_count`],
            colors.cyberYellow,
            100,
            colors.orange,
            500,
            colors.maximumRed,
          ],
          "circle-radius": [
            `step`,
            [`get`, `point_count`],
            20,
            100,
            30,
            500,
            40,
          ],
          "circle-stroke-width": 3,
          "circle-stroke-opacity": 0.6,
          "circle-stroke-color": [
            `case`,
            [`==`, [`feature-state`, `hover`], true],
            `#000`,
            `#fff`,
          ],
        }}
      />

      <Layer
        {...layerConfig}
        type="symbol"
        filter={[`has`, `point_count`]}
        layout={{
          "text-allow-overlap": true,
          "text-field": `{point_count_abbreviated}`,
          "text-font": [`Open Sans Bold`],
          "text-size": 12,
        }}
      />

      {/* points unclustered */}

      <Layer
        id="points-unclustered"
        sourceId="geojson-points-unclustered"
        type="symbol"
        layout={{
          "icon-allow-overlap": true,
          "icon-image": `{mapIconActive}`,
          "icon-anchor": `bottom`,
          "icon-size": 0.55,
        }}
        filter={[`match`, [`geometry-type`], [`Point`], true, false]}
      />
    </>
  )
}

export default MapPoints
