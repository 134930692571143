// TODO: implement external consent for `blockEmbed`

import { html, oneLine } from "common-tags"
import striptags from "striptags"

const jsonToHtml = (json) => {
  if (!json) return ``
  const blocks = json?.blocks || json
  if (!Array.isArray(blocks)) return ``

  const html = blocks
    .map((block) => {
      if (!block || !block.type || !block.data) return null

      switch (block.type) {
        case `header`:
          return blockHeader(block.data)

        case `paragraph`:
          return blockParagraph(block.data)

        case `quote`:
          return blockQuote(block.data)

        case `list`:
          return blockList(block.data)

        case `table`:
          return blockTable(block.data)

        case `image`:
          return blockImage(block.data)

        case `delimiter`:
          return `<hr />`

        case `embed`:
          return blockEmbed(block.data)

        case `warning`:
          return blockWarning(block.data)
      }

      return null
    })
    .filter(Boolean)
    .join(``)

  return oneLine(html)
}

const blockHeader = ({ text, level }) => {
  if (!text || !level) return null

  level = Math.max(2, level)

  return html`<h${level}>${fixText(text)}</h${level}>`
}

const blockParagraph = ({ text }) => {
  if (!text) return null

  return html`<p>${fixText(text)}</p>`
}

const blockQuote = ({ text, caption }) => {
  if (!text) return null

  return html`
    <blockquote>
      <p>${fixText(text)}</p>

      ${!!caption && `<footer>${fixText(caption)}</footer>`}
    </blockquote>
  `
}

const blockList = ({ items, style }) => {
  if (!items || !items.length) return null

  const tag = style == `ordered` ? `ol` : `ul`

  return html`
    <${tag}>
      ${items.map((item) => `<li>${fixText(item)}</li>`)}
    </${tag}>
  `
}

const blockTable = ({ content, withHeadings }) => {
  if (!content || !content.length) return null

  const thead = withHeadings ? [content[0]] : []
  const tbody = withHeadings ? content.slice(1) : content

  return html`
    <div class="styled-table-container">
      <table>
        ${!!thead.length &&
        html`
          <thead>
            ${thead.map(
              (row) => html`
                <tr>
                  ${row.map((col) => `<th>${fixText(col)}</th>`)}
                </tr>
              `
            )}
          </thead>
        `}
        ${!!tbody.length &&
        html`
          <tbody>
            ${tbody.map(
              (row) => html`
                <tr>
                  ${row.map((col) => `<td>${fixText(col)}</td>`)}
                </tr>
              `
            )}
          </tbody>
        `}
      </table>
    </div>
  `
}

const blockImage = ({ url, file, caption }) => {
  const realUrl = (file && file.url) || url
  if (!realUrl) return null

  const alt = caption ? striptags(caption).slice(0, 100) : ``

  return html`
    <figure>
      <img src="${realUrl}" alt=${alt} loading="lazy" />

      ${!!caption && `<figcaption>${fixText(caption)}</figcaption>`}
    </figure>
  `
}

const blockEmbed = ({ embed, caption }) => {
  if (!embed) return null

  return html`
    <figure>
      <iframe
        src="${embed}"
        title="Video"
        type="text/html"
        loading="lazy"
        allow="autoplay; fullscreen; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      ></iframe>

      ${!!caption && `<figcaption>${fixText(caption)}</figcaption>`}
    </figure>
  `
}

const blockWarning = ({ title, message }) => {
  return html`
    ${!!title &&
    `<h2 style="--color: var(--color-warning)">${fixText(title)}</h2>`}
    ${!!message &&
    `<p style="--color: var(--color-warning)">${fixText(message)}</p>`}
  `
}

const fixText = (text) => {
  return text
    .replaceAll(`&nbsp;`, ``)
    .replaceAll(`<b>`, `<strong>`)
    .replaceAll(`</b>`, `</strong>`)
    .replaceAll(`<i>`, `<em>`)
    .replaceAll(`</i>`, `</em>`)
}

export default jsonToHtml
