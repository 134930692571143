import React from "react"
import { observer } from "mobx-react-lite"
import styled, { keyframes } from "styled-components"
import { em } from "polished"

import { ReactComponent as SvgClose } from "../assets/images/icons/close.svg"
import { useStore } from "../store"
import { __ } from "../utils/translate"

const Measure = () => {
  const store = useStore()

  const closeClick = () => store.setMeasure(false)

  if (!store.measure) return null

  return (
    <Container>
      <Info>
        {store.measureValue ? (
          <>
            {__(`Total distance`)}:{` `}
            <strong>{store.measureValue} km</strong>
          </>
        ) : (
          __(`Click on map to measure a distance`)
        )}
      </Info>

      <Close onClick={closeClick} title={__(`Close`)}>
        <SvgClose aria-label={__(`Close`)} />
      </Close>
    </Container>
  )
}

export default observer(Measure)

const containerAnim = keyframes`
  0% { opacity: 0; transform: translateY(100%); }
  100% { opacity: 1; transform: translateY(0); }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.gunmetal};
  box-shadow: ${({ theme }) => theme.bs.button};
  border-radius: ${({ theme }) => theme.br.small};
  transform-origin: bottom;
  animation: ${containerAnim} 0.5s ${({ theme }) => theme.easings.outBack};

  @media only print {
    color: ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.white};
  }
`

const Info = styled.p`
  padding: ${em(16)} ${em(20)};
  padding-right: ${em(4)};
  font-size: ${em(13)};
  flex-grow: 1;

  @media ${({ theme }) => theme.mq.largeDown} {
    font-size: ${em(11)};
  }
`

const Close = styled.button.attrs({ type: `button` })`
  width: ${em(44)};
  flex-shrink: 0;

  @media only print {
    visibility: hidden;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.lightGray};
  }

  &:active {
    opacity: 0.6;
  }

  svg {
    width: 100%;
    height: 100%;
    padding: ${em(16)};
    display: block;
  }
`
