import { css } from "styled-components"

import embedParams from "../config/embedParams"

const families = {
  primary: `"Poppins", "Helvetica Neue", "Helvetica", "Arial", sans-serif`,
  // secondary: `"Georgia", serif`,
  code: `"Courier New", "Courier", "Lucida Sans Typewriter", "Lucida Typewriter", monospace`,
}

const weights = {
  primary: {
    normal: 400,
    medium: 500,
    bold: 600,
  },
  // secondary: {
  //   normal: 400,
  //   bold: 700,
  // },
  code: {
    normal: 400,
    bold: 700,
  },
}

if (embedParams.client == `stf`) {
  families.primary = `'Source Sans Pro', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif`
  weights.primary.medium = 600
}

const set = (family, weight) => {
  try {
    return css`
      font-family: ${families[family]};
      font-weight: ${weights[family][weight]};
    `
  } catch (e) {
    // eslint-disable-next-line no-console
    return console.warn(`Undefined font family/weight`, e.message, e.name)
  }
}

export default {
  set,
}
