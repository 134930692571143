import embedParams from "../config/embedParams"

const postEmbedMessage = (method, data = true) => {
  if (window.parent === window) return

  window.parent.postMessage(
    JSON.parse(
      JSON.stringify({
        owner: `naturkartan`,
        iframeId: embedParams.iframeId,
        method,
        data,
      })
    ),
    `*`
  )
}

const parseEmbedMessage = (e) => {
  let method = null
  let data = null

  if (e && e.data && e.data.owner && e.data.owner == `naturkartan`) {
    method = e.data.method
    data = e.data.data
  }

  return { method, data }
}

export { postEmbedMessage, parseEmbedMessage }
