import { useEffect, useCallback, useRef } from "react"

import useAbortController from "./useAbortController"
import { fetchApiTrafficStops, fetchApiTrafficTrips } from "./api"

export default function useAppTraffic(store) {
  const isFetchingRef = useRef(false)
  const abortController = useAbortController(true)
  const { signal } = abortController

  const fetchTrips = useCallback(() => {
    isFetchingRef.current = true

    store.setTraffic(`trips:loading`)
    store.setTrafficTrips([])
    store.setTrafficChoices([])

    const tripsPromise = fetchApiTrafficTrips(
      {
        from: store.trafficStart.sourceId,
        to: store.trafficFinish.sourceId,
      },
      { signal }
    )

    const choicesStartPromise = fetchApiTrafficStops(
      {
        lat: store.trafficStart.lat,
        lng: store.trafficStart.lng,
      },
      { signal }
    )

    const choicesFinishPromise = fetchApiTrafficStops(
      {
        lat: store.trafficFinish.lat,
        lng: store.trafficFinish.lng,
      },
      { signal }
    )

    Promise.all([tripsPromise, choicesStartPromise, choicesFinishPromise])
      .then(([trips, choicesStart, choicesFinish]) => {
        isFetchingRef.current = false

        const choices = [
          ...choicesStart.slice(0, 5).map((c) => ({ ...c, _choice: `start` })),
          ...choicesFinish
            .slice(0, 5)
            .map((c) => ({ ...c, _choice: `finish` })),
        ].filter(
          (c) => ![store.trafficStart.id, store.trafficFinish.id].includes(c.id)
        )

        store.setTrafficChoices(choices)
        store.setTrafficTrips(trips)
        store.setTraffic(`trips`)
      })
      .catch(() => store.setTraffic(`form`))
  }, [store.trafficStart, store.trafficFinish])

  useEffect(() => {
    if (
      store.traffic == `trips:loading` &&
      store.trafficStart &&
      store.trafficFinish
    )
      fetchTrips()

    if (
      store.traffic == `form` &&
      store.trafficStart &&
      store.trafficFinish &&
      store.trafficStart.id == store.trafficFinish.id
    )
      store.setTrafficStart(null)

    if (!store.traffic && isFetchingRef.current) {
      isFetchingRef.current = false
      abortController?.abort()
    }
  }, [store.traffic, store.trafficStart, store.trafficFinish])

  useEffect(() => {
    if ([`trips`, `trips:loading`, `trip`].includes(store.traffic))
      store.setTraffic(`trips:loading`)
  }, [store.trafficStart, store.trafficFinish])

  useEffect(() => {
    if (store.trafficTripActiveId) store.setTraffic(`trip`)
    else if (store.traffic == `trip`) store.setTraffic(`trips`)
  }, [store.trafficTripActiveId])

  return null
}
