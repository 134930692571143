// @TODO: fullscreen view on mobile down

import React, { useEffect } from "react"
import ReactDOM from "react-dom"
import FocusTrap from "focus-trap-react"
import styled from "styled-components"
import { em, rem, rgba } from "polished"

import { ReactComponent as SvgClose } from "../assets/images/icons/close.svg"

const Modal = ({
  children,
  title,
  icon = null,
  onClose,
  headerComponents,
  footerComponents,
}) => {
  const close = () => onClose && onClose()

  useEffect(() => {
    window.addEventListener(`keyup`, winKeyup)

    return () => {
      window.removeEventListener(`keyup`, winKeyup)
    }
  }, [])

  const winKeyup = (e) => {
    if (e.key === `Escape`) {
      e.preventDefault()
      close()
    }
  }

  return ReactDOM.createPortal(
    <FocusTrap>
      <Container>
        <Box>
          <Header>
            <Title>
              {icon}

              <span>{title}</span>
            </Title>

            {headerComponents && (
              <HeaderComponents>{headerComponents}</HeaderComponents>
            )}

            <Close title="" onClick={close}>
              <SvgClose aria-label="" />
            </Close>
          </Header>

          <Content>{children}</Content>

          {footerComponents && <Footer>{footerComponents}</Footer>}
        </Box>

        <Overlay />
      </Container>
    </FocusTrap>,
    document.querySelector(`body`)
  )
}

export default Modal

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 10vh ${em(20)};
  padding-top: max(10vh, ${rem(14)});
  padding-bottom: max(10vh, ${rem(14)});
  position: fixed;
  z-index: ${({ theme }) => theme.zindex.modal};
  top: 0;
  left: 0;

  @media ${({ theme }) => theme.mq.mobileDown} {
    padding-left: ${em(10)};
    padding-right: ${em(10)};
  }
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => rgba(theme.colors.cadet, 0.5)};
  animation: ${({ theme }) => theme.animations.fadeIn} 0.2s linear;
`

const Box = styled.div`
  width: 100%;
  max-width: ${em(600)};
  max-height: 100%;
  margin: 0 auto;
  padding: ${em(20)};
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.br.normal};
  animation: ${({ theme }) => theme.animations.slideInY(-10)} 0.3s
    ${({ theme }) => theme.easings.outBack};

  @media ${({ theme }) => theme.mq.mobileDown} {
    padding: ${em(12)};
  }
`

const Header = styled.header`
  padding: ${em(6)} 0 ${em(14)};
  display: flex;
  align-items: center;
  gap: ${em(20)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.slateGray};
`

const HeaderComponents = styled.div``

const Title = styled.h2`
  flex-grow: 1;
  display: flex;
  align-items: baseline;
  gap: ${em(14)};

  @media ${({ theme }) => theme.mq.mobileDown} {
    font-size: ${em(14)};
  }

  svg {
    width: ${em(20)};
    height: ${em(20)};
    flex-shrink: 0;
    color: ${({ theme }) => theme.colors.darkSlateGray};
    position: relative;
    bottom: ${em(-3)};
  }

  span {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

    font-size: ${em(18)};
  }
`

const Close = styled.button.attrs({ type: `button` })`
  width: ${em(32)};
  height: ${em(32)};
  padding: ${em(9)};
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.darkSlateGray};

  &:hover {
    color: ${({ theme }) => theme.colors.seaGreen};
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`

const Content = styled.div`
  padding: ${em(40)} ${em(20)};
  flex-grow: 1;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media ${({ theme }) => theme.mq.mobileDown} {
    padding: ${em(20)} ${em(12)};
  }

  &::-webkit-scrollbar {
    width: ${rem(5)};
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gunmetal};
    border-radius: ${({ theme }) => theme.br.small};
  }
`

const Footer = styled.footer`
  padding: ${em(20)} 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${({ theme }) => theme.colors.slateGray};
`
