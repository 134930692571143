import React from "react"
import { observer } from "mobx-react-lite"
import styled, { css } from "styled-components"
import { em } from "polished"

import { ReactComponent as SvgEyeOff } from "../assets/images/icons/eye-off.svg"
import { useStore } from "../store"
import { __ } from "../utils/translate"

const ToolbarTraffic = () => {
  const store = useStore()

  const pointsHideClick = () => store.setPointsHide(!store.pointsHide)

  const trafficChoicesHideClick = () =>
    store.setTrafficChoicesHide(!store.trafficChoicesHide)

  return (
    <>
      <Container>
        <li>
          <Toggle onClick={pointsHideClick} $active={store.pointsHide}>
            <SvgEyeOff aria-hidden="true" />

            <span>{__(`Hide sites`)}</span>
          </Toggle>
        </li>

        <li>
          <Toggle
            onClick={trafficChoicesHideClick}
            $active={store.trafficChoicesHide}
          >
            <SvgEyeOff aria-hidden="true" />

            <span>{__(`Hide alternative stops`)}</span>
          </Toggle>
        </li>
      </Container>
    </>
  )
}

export default observer(ToolbarTraffic)

const Container = styled.ul`
  display: inline-flex;
  align-items: flex-start;
  gap: ${em(10)};

  > li {
    position: relative;

    &:empty {
      display: none;
    }
  }
`

const Toggle = styled.button.attrs({ type: `button` })`
  ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

  height: ${em(44)};
  padding: ${em(6)} ${em(16)};
  position: relative;
  display: flex;
  align-items: center;
  gap: ${em(10)};
  font-size: ${em(15)};
  letter-spacing: ${em(-0.25)};
  white-space: nowrap;
  border: solid ${({ theme }) => theme.colors.aliceBlue};
  border-width: 1px 0 0 1px;
  border-radius: ${({ theme }) => theme.br.normal};
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.bs.button};

  ${({ $active }) =>
    $active &&
    css`
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.seaGreen};
      box-shadow: ${({ theme }) => theme.bs.buttonPressed};
    `}

  &:hover {
    ${({ $active }) =>
      $active
        ? css`
            color: ${({ theme }) => theme.colors.aliceBlue};
          `
        : css`
            background-color: ${({ theme }) => theme.colors.aliceBlue};
          `}
  }
`
