import { uniq } from "lodash"
// import turfBboxPolygon from "@turf/bbox-polygon"
// import turfBooleanIntersects from "@turf/boolean-intersects"

const getMapPointsInViewportIds = (store) => {
  return new Promise((resolve, reject) => {
    const renderedPointIds = store.map.getLayer(`points`)
      ? store.map
          .queryRenderedFeatures({
            layers: [`points`, `trails`, `areas`],
          })
          .map((f) =>
            String(f.layer.id == `points` ? f.id : f.properties.parent)
          )
      : []

    const renderedClusters = store.map.getLayer(`points-cluster`)
      ? store.map.queryRenderedFeatures({
          layers: [`points-cluster`],
        })
      : []

    if (renderedClusters.length) {
      const clusterSource = store.map.getSource(`geojson-points`)
      const clusteredPoints = []

      renderedClusters.forEach((cluster, i) =>
        // `getClusterLeaves` works asynchronously
        clusterSource.getClusterLeaves(
          cluster.properties.cluster_id,
          cluster.properties.point_count,
          0,
          (err, points) => {
            if (points) clusteredPoints.push(...points)
            if (i >= renderedClusters.length - 1)
              resolve(
                uniq([...clusteredPoints.map((p) => p.id), ...renderedPointIds])
              )
          }
        )
      )
    } else resolve(uniq(renderedPointIds))
  })
}

// const getMapPointsInViewportIds = (store) => {
//   return new Promise((resolve, reject) => {
//     if (!store.map) return resolve([])

//     const ids = []
//     const mapBounds = store.map.getBounds()
//     const mapBoundsPolygon = turfBboxPolygon([
//       mapBounds._ne.lng,
//       mapBounds._ne.lat,
//       mapBounds._sw.lng,
//       mapBounds._sw.lat,
//     ])

//     store.points.forEach(
//       (p) => turfBooleanIntersects(p, mapBoundsPolygon) && ids.push(p.id)
//     )

//     store.shapes.forEach(
//       (s) =>
//         turfBooleanIntersects(s, mapBoundsPolygon) &&
//         ids.push(s.properties.parent)
//     )

//     return resolve(uniq(ids))
//   })
// }

export { getMapPointsInViewportIds }
