import React from "react"
import styled, { css } from "styled-components"
import { em } from "polished"

import { __ } from "../utils/translate"

const UnpublishedLabel = ({ prepended = false }) => {
  return <Container $prepended={prepended}>{__(`Unpublished`)}</Container>
}

export default UnpublishedLabel

const Container = styled.div`
  padding: ${em(5)} ${em(12)};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.maximumRed};
  border-radius: ${({ theme }) => theme.br.xsmall};
  font-size: ${em(12)};

  ${({ $prepended }) =>
    $prepended &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
`
