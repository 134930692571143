import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { em, ellipsis } from "polished"

import Modal from "./Modal"
import { ReactComponent as SvgShare } from "../assets/images/icons/share.svg"
import { ReactComponent as SvgFacebook } from "../assets/images/icons/facebook.svg"
import { ReactComponent as SvgTwitter } from "../assets/images/icons/twitter.svg"
import { __ } from "../utils/translate"

const SOCIALS = [
  {
    title: `Facebook`,
    icon: <SvgFacebook aria-hidden="true" />,
    url: ({ url }) =>
      `https://www.facebook.com/sharer.php?u=${window.encodeURIComponent(url)}`,
  },
  {
    title: `Twitter`,
    icon: <SvgTwitter aria-hidden="true" />,
    url: ({ url, title }) =>
      `https://twitter.com/intent/tweet?text=${window.encodeURIComponent(
        title || ``
      )}&url=${window.encodeURIComponent(url)}`,
  },
]

const AuthModal = ({ title, url, onClose }) => {
  const [linkCopied, setLinkCopied] = useState(false)
  const linkCopiedTimeoutRef = useRef(null)

  const linkButtonClick = () => {
    try {
      window.navigator.clipboard.writeText(url)
      setLinkCopied(true)
      linkCopiedTimeoutRef.current = window.setTimeout(
        () => setLinkCopied(false),
        2000
      )
      return true
    } catch (e) {
      return false
    }
  }

  const close = () => onClose && onClose()

  useEffect(() => {
    window.addEventListener(`keyup`, winKeyup)

    return () => {
      window.removeEventListener(`keyup`, winKeyup)
      window.clearTimeout(linkCopiedTimeoutRef.current)
    }
  }, [])

  const winKeyup = (e) => {
    if (e.key === `Escape`) {
      e.preventDefault()
      close()
    }
  }

  return (
    <Modal
      title={__(`Share`)}
      icon={<SvgShare aria-hidden="true" />}
      onClose={onClose}
    >
      <Socials>
        {SOCIALS.map((social, i) => (
          <li key={i}>
            <a
              href={social.url({ url, title })}
              target="_blank"
              rel="noopener noreferrer"
            >
              {social.icon}

              <span>{social.title}</span>
            </a>
          </li>
        ))}
      </Socials>

      <Link>
        <LinkTitle>{__(`Or copy link`)}</LinkTitle>

        <LinkField>
          <input
            type="text"
            value={url}
            onFocus={(e) => e.target.select()}
            readOnly
          />

          <button type="button" onClick={linkButtonClick}>
            {linkCopied ? `${__(`Copied`)}!` : __(`Copy`)}
          </button>
        </LinkField>
      </Link>
    </Modal>
  )
}

export default AuthModal

const Socials = styled.ul`
  display: flex;
  justify-content: center;
  gap: ${em(30)};

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${em(12)};
    text-align: center;
    color: ${({ theme }) => theme.colors.darkSlateGray};

    &:hover {
      color: ${({ theme }) => theme.colors.seaGreen};
    }
  }

  svg {
    width: ${em(26)};
    height: ${em(26)};
  }

  span {
    font-size: ${em(13)};
    letter-spacing: ${em(-0.25)};
  }
`

const Link = styled.section`
  margin-top: ${em(46)};
`

const LinkTitle = styled.h3`
  ${({ theme }) => theme.fonts.set(`primary`, `medium`)}

  margin-bottom: ${em(10)};
  font-size: ${em(14)};
  letter-spacing: ${em(-0.25)};
  color: ${({ theme }) => theme.colors.cadet};
`

const LinkField = styled.div`
  padding: ${em(12)} ${em(20)};
  display: flex;
  gap: ${em(20)};
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors.pewterBlue};
  border-radius: ${({ theme }) => theme.br.normal};

  input {
    ${ellipsis(`100%`)}

    flex-grow: 1;
    font-size: ${em(14)};
    letter-spacing: ${em(-0.25)};
    outline: none !important;
  }

  button {
    text-decoration-line: underline;
    font-size: ${em(14)};
    letter-spacing: ${em(-0.25)};

    &:hover {
      color: ${({ theme }) => theme.colors.seaGreen};
    }
  }
`
