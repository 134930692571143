import mq from "./mq"
import fonts from "./fonts"
import colors from "./colors"
import zindex from "./zindex"
import easings from "./easings"
import animations from "./animations"
import bs from "./bs"
import br from "./br"
import mixins from "./mixins"

export default {
  mq,
  fonts,
  colors,
  zindex,
  easings,
  animations,
  bs,
  br,
  mixins,
}
