import React from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { hideVisually } from "polished"

import ExpandableText from "../ExpandableText"
import { __ } from "../../utils/translate"

const Desciption = ({ data }) => {
  if (!data.description) return null

  return (
    <Container>
      <Title>{__(`Description`)}</Title>

      <ExpandableText text={data.description} />
    </Container>
  )
}

export default observer(Desciption)

const Container = styled.section``

const Title = styled.h3`
  ${hideVisually()}
`
