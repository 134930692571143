// LATERDO: reuse the same elevations data when going fullscreen, and do not fetch API twice if multiple component instances are mounted

import React, { useState, useEffect, useRef, lazy, Suspense } from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { em, hideVisually } from "polished"
import { chunk } from "lodash"
import turfDistance from "@turf/distance"

import { ReactComponent as SvgClose } from "../../assets/images/icons/close.svg"
import { ReactComponent as SvgFullscreen } from "../../assets/images/icons/fullscreen.svg"
import { fetchApiSiteShapes, fetchApiSiteElevations } from "../../utils/api"
import { __ } from "../../utils/translate"
import { useStore } from "../../store"

const Chart = lazy(() => import(`./Chart`))

const Index = ({ inSite = false }) => {
  const store = useStore()
  const [status, setStatus] = useState(`loading`) // loading|success|error
  const [elevations, setElevations] = useState(null) // []
  const [gain, setGain] = useState(0)
  const pointIdRef = useRef(0)
  const pointId = store.pointActiveId || store.pointPreselectedId

  useEffect(() => {
    setStatus(`loading`)
    pointIdRef.current = pointId

    Promise.all([
      store.shapeActive ||
        store.shapePreselected ||
        fetchApiSiteShapes({ id: pointId }).then((f) => f?.[0]),
      fetchApiSiteElevations({ id: pointId }),
    ])
      .then(([shape, elevations]) => {
        if (pointIdRef.current != pointId) return
        if (!shape?.geometry?.coordinates?.length || !elevations?.length)
          throw Error()

        let coordinates = shape.geometry.coordinates.flat(Infinity)
        coordinates = chunk(coordinates, 2)
        if (coordinates.length != elevations.length) throw Error()

        // make sure coordinates start where the point is
        const point = store.pointActive || store.pointPreselected
        const pointSum =
          window.parseFloat(point.geometry.coordinates[0]) +
          window.parseFloat(point.geometry.coordinates[1])
        const firstSum =
          window.parseFloat(coordinates[0][0]) +
          window.parseFloat(coordinates[0][1])
        const lastSum =
          window.parseFloat(coordinates[coordinates.length - 1][0]) +
          window.parseFloat(coordinates[coordinates.length - 1][1])
        if (Math.abs(pointSum - firstSum) > Math.abs(pointSum - lastSum)) {
          coordinates = coordinates.reverse()
          elevations = elevations.reverse()
        }

        const results = []
        let gain = 0
        let distance = 0

        elevations.forEach((elevation, i) => {
          const coordinate = coordinates[i]
          if (i > 0)
            distance += turfDistance(results[i - 1].coordinate, coordinate, {
              units: `kilometers`,
            })

          results.push({
            elevation,
            coordinate,
            distance: distance.toFixed(1),
          })

          if (i < elevations.length - 1) {
            const gainDiff = elevations[i + 1] - elevation
            if (gainDiff > 0) gain += gainDiff
          }
        })

        setElevations(results)
        setGain(gain)
        setStatus(`success`)
      })
      .catch(() => {
        pointIdRef.current = 0
        setStatus(`error`)
      })
  }, [store.pointActiveId])

  const closeClick = () => {
    store.setElevations(false)
  }

  const fullscreenClick = () => {
    store.setDisplaySite(false)
    store.setMenu(false)
    store.setElevations(true, true)
  }

  return (
    <Container $inSite={inSite}>
      <Title>{__(`Elevations`)}</Title>

      {!!gain && (
        <Gain>
          {__(`Elevation gain`)}: {gain} m
        </Gain>
      )}

      <Suspense fallback={<Status>{__(`Loading`)}</Status>}>
        {status == `loading` && <Status>{__(`Loading`)}</Status>}

        {status == `error` && (
          <Status>
            {__(`Sorry, elevation data is not available for this trail.`)}
          </Status>
        )}

        {status == `success` && <Chart data={elevations} />}
      </Suspense>

      {inSite ? (
        <Close onClick={fullscreenClick} title={__(`Enter fullscreen mode`)}>
          <SvgFullscreen aria-label={__(`Enter fullscreen mode`)} />
        </Close>
      ) : (
        <Close onClick={closeClick} title={__(`Close`)}>
          <SvgClose aria-label={__(`Close`)} />
        </Close>
      )}
    </Container>
  )
}

export default observer(Index)

const Container = styled.section`
  width: 100%;
  height: 100%;
  padding: ${em(6)} ${({ $inSite }) => ($inSite ? em(10) : em(20))};
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.gunmetal};
  border-radius: ${({ theme }) => theme.br.normal};
  box-shadow: ${({ theme, $inSite }) => !$inSite && theme.bs.button};

  @media only print {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    filter: invert(1);
  }
`

const Title = styled.h2`
  ${hideVisually()}
`

const Gain = styled.p`
  position: relative;
  bottom: ${em(-8)};
  text-align: center;
  font-size: ${em(12)};
  color: ${({ theme }) => theme.colors.white};
`

const Status = styled.div`
  padding: ${em(14)} 0;
  text-align: center;
  font-size: ${em(12)};
  color: ${({ theme }) => theme.colors.white};
`

const Close = styled.button.attrs({ type: `button` })`
  width: ${em(34)};
  height: ${em(34)};
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  color: ${({ theme }) => theme.colors.white};

  @media only print {
    visibility: hidden;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.lightGray};
  }

  &:active {
    opacity: 0.6;
  }

  svg {
    width: 100%;
    height: 100%;
    padding: ${em(11)};
    display: block;
  }
`
