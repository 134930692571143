import React from "react"
import { observer } from "mobx-react-lite"
import { useMediaQuery } from "@react-hook/media-query"
import styled, { css } from "styled-components"
import { rem, rgba } from "polished"

import Strict from "./Strict"
import Search from "./Search"
import Toolbar from "./Toolbar"
import SearchResults from "./SearchResults"
import SitesList from "./SitesList"
import Site from "./Site/Index"
import Traffic from "./Traffic/Index"
import TrafficHeader from "./Traffic/Header"
import { ReactComponent as SvgChevronRight } from "../assets/images/icons/chevron-right.svg"
import { useStore } from "../store"
import mq from "../theme/mq"
import { __ } from "../utils/translate"
import embedParams from "../config/embedParams"

const Menu = () => {
  const store = useStore()
  const isMobile = useMediaQuery(mq.mobileDown)
  const isDesktop = !isMobile
  const isMobileFullscreen = isMobile && store.fullscreen
  const isMobileAndMenu = isMobile && store.menu
  const displaySearch = !(store.queryFailed && embedParams.strict)
  const displayTrafficHeader =
    isMobile && store.traffic && store.trafficMobileMap

  const toggleClick = () => store.setMenu(!store.menu)

  return (
    <Container>
      {isDesktop && (
        <Toggle onClick={toggleClick} $active={store.menu} title={__(`Menu`)}>
          <SvgChevronRight aria-label={__(`Menu`)} />
        </Toggle>
      )}

      {(store.menu || isMobileFullscreen) && (
        <SearchAndSites>
          {displayTrafficHeader ? (
            <TrafficHeader />
          ) : (
            <StrictAndSearch>
              <Strict aboveSearch={displaySearch} />

              {displaySearch && (
                <Search
                  horizontal={isMobile}
                  belowStrict={!!store.strictInfo}
                />
              )}
            </StrictAndSearch>
          )}

          {isMobileAndMenu && !store.search && (
            <ToolbarWrap>
              <Toolbar />
            </ToolbarWrap>
          )}

          {(isDesktop || isMobileAndMenu) && (
            <>{store.search ? <SearchResults /> : <SitesList />}</>
          )}
        </SearchAndSites>
      )}

      {isDesktop && store.menu && store.displaySite && store.pointActive && (
        <SiteWrap>
          <Site />
        </SiteWrap>
      )}

      {isDesktop && store.menu && store.traffic && embedParams.traffic && (
        <TrafficWrap>
          <Traffic />
        </TrafficWrap>
      )}
    </Container>
  )
}

export default observer(Menu)

const Container = styled.div`
  width: 100%;
  height: 100%;
`

const SearchAndSites = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
`

const StrictAndSearch = styled.div`
  padding: ${rem(24)} ${rem(20)};
  flex-shrink: 0;

  @media ${({ theme }) => theme.mq.mobileDown} {
    padding: ${rem(14)} ${rem(8)};
    background-color: ${({ theme }) => theme.colors.darkSlateGray};
  }
`

const ToolbarWrap = styled.div`
  ${({ theme }) => theme.mixins.hideScrollbar()}

  padding: ${rem(14)} ${rem(8)};
  flex-shrink: 0;
  position: relative;
  z-index: 1;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  &:empty {
    display: none;
  }
`

const SiteWrap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
`

const TrafficWrap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
`

const Toggle = styled.button.attrs({ type: `button` })`
  width: ${rem(36)};
  height: ${rem(38)};
  padding: ${rem(10)};
  z-index: 3;
  position: absolute;
  top: ${rem(100)};
  left: 100%;
  pointer-events: auto;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.gunmetal};
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  box-shadow: 0 ${rem(4)} ${rem(4)}
    ${({ theme }) => rgba(theme.colors.black, 0.25)};

  @media print {
    display: none;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.lightGray};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.cadet};
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
    transition: transform 0.3s ${({ theme }) => theme.easings.default};

    ${({ $active }) =>
      $active &&
      css`
        transform: rotate(180deg);
      `}
  }
`
