import { useRef, useEffect } from "react"

const useWatchGeoPosition = ({
  onWatch,
  onError,
  once = false,
  timeout = 10, // seconds
}) => {
  const ref = useRef(null)

  useEffect(() => {
    return () => {
      unwatch()
    }
  }, [])

  const watch = () => {
    unwatch()

    ref.current = window.navigator.geolocation.watchPosition(
      (pos) => {
        if (once) unwatch()
        onWatch(pos)
      },
      (err) => {
        unwatch()
        if (onError) onError(err)
      },
      { timeout: timeout * 1000, enableHighAccuracy: true }
    )
  }

  const unwatch = () => {
    if (ref.current) {
      window.navigator.geolocation.clearWatch(ref.current)
      ref.current = null
    }
  }

  return [watch, unwatch]
}

export default useWatchGeoPosition
