import React, { useRef, useEffect } from "react"
import { observer } from "mobx-react-lite"
import ContentEditable from "react-contenteditable"
import styled, { css } from "styled-components"
import { rem, em, rgba } from "polished"

import { ReactComponent as SvgSearch } from "../assets/images/icons/search.svg"
import { ReactComponent as SvgClose } from "../assets/images/icons/close.svg"
import { ReactComponent as SvgMinimize } from "../assets/images/icons/minimize.svg"
import { useStore } from "../store"
import embedParams from "../config/embedParams"
import useReferredState from "../utils/useReferredState"
import { queryItemToTitle } from "../utils/query"
import { __ } from "../utils/translate"

const INPUT_PLACEHOLDER = `___PLACEHOLDER___`

const sanitizeStr = (str) =>
  str
    .replace(/(<([^>]+)>)/gi, ` `)
    .replaceAll(`&nbsp;`, ``)
    .replace(/\s\s+/g, ` `)
    .trim()

const Search = ({ horizontal = true, belowStrict = false }) => {
  const store = useStore()
  const [inputValue, inputValueRef, setInputValue] =
    useReferredState(INPUT_PLACEHOLDER)
  const inputRef = useRef()
  const focusedRef = useRef(false)

  const queryTypes = embedParams.strict
    ? [`category`, `category_animal`, `text`]
    : [
        `category`,
        `category_animal`,
        `text`,
        `guide`,
        `organization`,
        `county`,
        `municipality`,
        `site`,
        `site_with_neighbours`,
        `user`,
        `trip`,
        `list`,
      ]

  const containerClick = () => {
    if (!store.menu) store.setMenu(true)
    inputRef.current.focus()
  }

  const inputChange = (e) => {
    store.setSearch(sanitizeStr(e.target.value))
  }

  const inputFocus = () => {
    focusedRef.current = true
    if (inputValueRef.current == INPUT_PLACEHOLDER) setInputValue(``)
  }

  const inputBlur = () => {
    focusedRef.current = false
    if (!store.search) setInputValue(INPUT_PLACEHOLDER)
  }

  const inputKeyDown = (e) => {
    const value = sanitizeStr(e.target.innerText)

    switch (e.key) {
      case `Enter`: {
        e.preventDefault()
        e.target.blur()
        break
      }

      case `Escape`: {
        if (inputValueRef.current) store.setSearch(``)
        else e.target.blur()
        break
      }

      case `Backspace`: {
        if (!value) {
          let removed = false
          const query = store.query
            .reverse()
            .filter((q) => {
              if (!removed && queryTypes.includes(q.type)) {
                removed = true
                return false
              }
              return true
            })
            .reverse()

          if (query.length != store.query.length) store.setQuery(query)
        }
        break
      }

      case `ArrowUp`:
      case `ArrowDown`: {
        e.preventDefault()
        break
      }
    }
  }

  const optionClick = (e) => {
    e.stopPropagation()
    store.removeFromQuery(e.currentTarget.dataset)
  }

  const fullscreenExitClick = () => {
    store.setFullscreen(false)
  }

  useEffect(() => {
    setInputValue(
      focusedRef.current ? store.search : store.search || INPUT_PLACEHOLDER
    )
  }, [store.search])

  useEffect(() => {
    return () => {
      store.setSearch(``)
    }
  }, [])

  return (
    <Container
      $horizontal={horizontal}
      $belowStrict={belowStrict}
      onClick={containerClick}
    >
      <Cta title={__(`Search`)}>
        <SvgSearch aria-label={__(`Search`)} />
      </Cta>

      <Options>
        <div>
          {store.query.map(
            (option) =>
              queryTypes.includes(option.type) && (
                <Option
                  key={`${option.type}${option.value}`}
                  onClick={optionClick}
                  data-type={option.type}
                  data-value={option.value}
                >
                  {queryItemToTitle(option, store)}

                  <SvgClose aria-hidden="true" />
                </Option>
              )
          )}

          <Input
            innerRef={inputRef}
            html={
              inputValue == INPUT_PLACEHOLDER ? `${__(`Search`)}…` : inputValue
            }
            onChange={inputChange}
            onFocus={inputFocus}
            onBlur={inputBlur}
            onKeyDown={inputKeyDown}
            spellCheck="false"
            autoComplete="off"
          />
        </div>
      </Options>

      {store.fullscreen && !store.strictInfo && (
        <Exit onClick={fullscreenExitClick} title={__(`Exit fullscreen mode`)}>
          <SvgMinimize aria-label={__(`Exit fullscreen mode`)} />
        </Exit>
      )}
    </Container>
  )
}

export default observer(Search)

const Cta = styled.button.attrs({ type: `button` })`
  width: ${em(40)};
  height: ${em(46)};
  padding: ${em(9)};
  flex-shrink: 0;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`

const Options = styled.div`
  ${({ theme }) => theme.mixins.hideScrollbar()}

  flex-grow: 1;
  max-height: ${em(114)};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  > div {
    padding: ${em(6)} ${em(4)};
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > * {
      margin: ${em(2)};
    }
  }
`

const Option = styled.button.attrs({ type: `button` })`
  ${({ theme }) => theme.fonts.set(`primary`, `medium`)}

  padding: ${em(6)} ${em(12)};
  display: flex;
  align-items: baseline;
  gap: ${em(10)};
  font-size: ${em(14)};
  letter-spacing: ${em(-0.25)};
  line-height: 1.3;
  word-break: break-all;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: ${({ theme }) => theme.br.small};

  &[data-type="category"],
  &[data-type="category_animal"] {
    background-color: ${({ theme }) => theme.colors.orange};
  }

  &[data-type="guide"] {
    background-color: ${({ theme }) => theme.colors.darkSlateGray};
  }

  &[data-type="text"] {
    background-color: ${({ theme }) => theme.colors.cadet};
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }

  svg {
    width: ${em(12)};
    height: ${em(12)};
    flex-shrink: 0;
    position: relative;
    bottom: ${em(-1)};
  }
`

const Input = styled(ContentEditable)`
  ${({ theme }) => theme.fonts.set(`primary`, `medium`)}

  min-width: ${em(80)};
  padding: ${em(4)} ${em(5)};
  font-size: ${em(16)};
  letter-spacing: ${em(-0.25)};
  line-height: 1.3;
  outline: none !important;

  /* &:focus {
    background-color: ${({ theme }) => theme.colors.aliceBlue};
    padding-left: ${em(8)};
    padding-right: ${em(8)};
  } */
`

const Exit = styled.button.attrs({ type: `button` })`
  width: ${em(54)};
  flex-shrink: 0;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.gunmetal};

  @media ${({ theme }) => theme.mq.mobileUp} {
    display: none;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.lightGray};
  }

  &:active {
    opacity: 0.8;
  }

  svg {
    width: ${em(22)};
    height: ${em(22)};
  }
`

const Container = styled.div`
  width: 100%;
  padding-left: ${em(4)};
  display: flex;
  align-items: start;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};
  border-top: 1px solid ${({ theme }) => theme.colors.aliceBlue};
  border-radius: ${({ theme }) => theme.br.normal};
  box-shadow: 0 ${rem(4)} ${rem(4)}
    ${({ theme }) => rgba(theme.colors.black, 0.25)};

  ${({ $belowStrict }) =>
    $belowStrict &&
    css`
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `}

  @media ${({ theme }) => theme.mq.mobileDown} {
    align-items: center;
    border-top: none;
  }

  ${({ $horizontal }) =>
    $horizontal &&
    css`
      @media ${({ theme }) => theme.mq.mobileDown} {
        min-height: ${em(48)};
      }

      ${Cta} {
        height: ${rem(40)};
      }

      ${Option} {
        white-space: nowrap;
      }

      ${Options} {
        overflow-y: hidden;
        overflow-x: auto;

        > div {
          padding-top: ${em(4)};
          padding-bottom: ${em(3)};
          flex-wrap: nowrap;
        }
      }
    `}
`
