import React from "react"
import { Marker } from "react-mapbox-gl"
// import { Layer, Source } from "react-mapbox-gl"
import styled from "styled-components"
import { em } from "polished"

import { ReactComponent as SvgFlag } from "../assets/images/icons/flag-solid.svg"
import embedParams from "../config/embedParams"

const MapCustomPin = () => {
  // const features = embedParams.customPin
  //   ? [
  //       {
  //         type: `Feature`,
  //         id: `29384732`,
  //         properties: {
  //           title: embedParams.customPin.title,
  //           icon: `${embedParams.customPin.icon || `none`}-active`,
  //         },
  //         geometry: {
  //           type: `Point`,
  //           coordinates: [embedParams.customPin.lng, embedParams.customPin.lat],
  //         },
  //       },
  //     ]
  //   : []

  if (!embedParams.customPin) return null

  return (
    <Marker
      coordinates={[embedParams.customPin.lng, embedParams.customPin.lat]}
      anchor="bottom-left"
      scale="2"
    >
      <Container
        tabIndex="0"
        title={embedParams.customPin.title}
        aria-label={embedParams.customPin.title}
      >
        <SvgFlag aria-hidden="true" />
      </Container>
    </Marker>

    // <>
    //   <Source
    //     id="geojson-custom-pin"
    //     geoJsonSource={{
    //       type: `geojson`,
    //       data: {
    //         type: `FeatureCollection`,
    //         features,
    //       },
    //     }}
    //   />

    //   <Layer
    //     id="custom-pin"
    //     sourceId="geojson-custom-pin"
    //     type="symbol"
    //     layout={{
    //       "icon-allow-overlap": true,
    //       "icon-image": `{icon}`,
    //       "icon-anchor": `bottom`,
    //       "icon-size": 0.55,
    //       "symbol-z-order": `source`,
    //     }}
    //   />
    // </>
  )
}

export default MapCustomPin

const Container = styled.span`
  svg {
    width: ${em(36)};
    height: ${em(36)};
    display: block;

    path {
      stroke: ${({ theme }) => theme.colors.white};
      stroke-width: ${em(1)};
      stroke-linejoin: round;
    }
  }
`
